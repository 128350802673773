//import react packeges
import React, { useEffect } from "react";

//import mantine packages
import { Container, Text } from "@mantine/core";

//import react router dom packages
import { Link, useNavigate } from "react-router-dom";

//import OrderPlaced css
import "./OrderPlaced.css";

//import checkicon
import check from "../../../assets/orderplaced/check.webp";

// Redux State
import { useDispatch } from "react-redux";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";

const OrderPlaced = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Redirecting to Home Page
  const orderID = sessionStorage.getItem("orderID");
  useEffect(() => {
    if (!orderID) {
      navigate("/");
    }
  }, [orderID]);

  useEffect(() => {
    sessionStorage.removeItem("cartProducts");
    dispatch(setCart(""));
  }, []);
  return (
    <div>
      <div className="orderplaced-div">
        <Container className="orderplaced-div-container" size={"82rem"}>
          <div className="orderplaced-div-container-main">
            <div className="orderplaced-div-container-main-icon">
              <img src={check} alt="check icon" width={100} />
            </div>
            <div className="orderplaced-div-container-main-details">
              <h1>Your order has been successfully completed!</h1>
              <p className="order-number-display">
                Order: #{sessionStorage.getItem("orderID")}
              </p>
            </div>
            <div className="orderplaced-div-container-main-goback">
              <Text component={Link} to="/">
                <button>Back to Home</button>
              </Text>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default OrderPlaced;
