import { createSlice } from "@reduxjs/toolkit";
import { orderState } from "../../InitialState/Orders/OrdersState";

export const orderSlice = createSlice({
  name: "Orders",
  initialState: orderState,
  reducers: {
    setOrders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setOrders } = orderSlice.actions;
