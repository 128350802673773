//import react packages
import React, { useEffect, useState } from "react";

//import mantine packages
import {
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Input,
  Modal,
  Radio,
  Select,
  Stepper,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

//import Checkout css file
import "./Checkout.css";

import { useDispatch, useSelector } from "react-redux";
import { setLoginModalOpen } from "../../../StateHandler/Slice/Login/LoginSlice";
import { useQuery, useQueryClient } from "react-query";
import { listAddressByUserID } from "../../../config/quries/Address/userAddress";
import CheckOutCart from "./CheckOutCart";
import { handleOrderPlaceControl } from "../../../controller/orders/OrdersControl";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { ArrowRight, CircleCheck, X } from "tabler-icons-react";
import anglebottom from "../../../assets/preheader/arrow-down.webp";
import {
  addressCountries,
  selectedListStates,
} from "../../../StateHandler/InitialState/Address/AddressState";

import {
  handleChangePasswordControl,
  handleEditAddressControl,
  hanldeCreateUserAddressControl,
} from "../../../controller/userAddress/userAddress";

const Checkout = () => {
  // Redux Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Cart Values
  const cartProducts = useSelector((state) => state?.cartData.value);

  // User Data
  const userData = useSelector((state) => state.userData.value);

  //   Total Sale Price
  const [totalSalePrice, setTotalSalePrice] = useState();

  // Open Login Modal
  const hanldeLoginModalOpen = (value) => {
    dispatch(setLoginModalOpen(value));
  };

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [userAddress, setUserAddress] = useState({
    id: "",
    address_type: "",
    first_name: "",
    last_name: "",
    email: "",
    number: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
  });

  const handleOpenModal = () => {
    setEditAddress(false);
    setAddressModalOpen(true);
    setUserAddress((previousData) => ({
      ...previousData,
      address_type: "",
      first_name: "",
      last_name: "",
      email: "",
      number: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
    }));
  };

  //   Getting Value of Products Price
  useEffect(() => {
    if (cartProducts) {
      const totalCartProductPrice = cartProducts.map((value) =>
        Number(value.sale_price)
      );
      const totalQuantityPerProduct = cartProducts.map((value) =>
        Number(value.quantity)
      );
      const resultArray = [];
      if (totalCartProductPrice.length === totalQuantityPerProduct.length) {
        for (let i = 0; i < totalQuantityPerProduct.length; i++) {
          resultArray.push(
            totalQuantityPerProduct[i] * totalCartProductPrice[i]
          );
        }
      }
      let sum = 0;
      for (let i = 0; i < resultArray.length; i++) {
        sum += resultArray[i];
        setTotalSalePrice(sum);
      }
    }
  }, [cartProducts]);

  // Stepper
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 4 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  // const user Addres
  const { data: userAddressDetails } = useQuery(
    ["userAddress", sessionStorage.getItem("MogoUserAccessToken101")],
    listAddressByUserID,
    {
      enabled: !!sessionStorage.getItem("MogoUserAccessToken101"),
    }
  );
  // Address Radio
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const handleRadioChange = (valueId) => {
    setSelectedAddress(valueId);
  };
  const radioDataAddress = () => {
    return filterUserAddress.map((value, index) => {
      return (
        <Card p={"xl"} mt={"xl"} mb={"xl"} shadow="sm" radius="md" withBorder>
          <Flex key={index} align={"center"}>
            <Radio
              onChange={() => handleRadioChange(value._id)}
              checked={selectedAddress === value._id}
              value={value._id}
              label={
                <div>
                  {value.first_name} {value.last_name},{value.address},
                  {value.city},{value.state},{value.country}-{value.zip_code},
                  {value.number}
                </div>
              }
            />
          </Flex>
        </Card>
      );
    });
  };

  // Payment Options
  const handlePaymentChange = (value) => {
    setSelectedPayment(value);
  };
  const paymentOptions = () => {
    return (
      <Flex className="padding-checkout" gap={"lg"} direction={"column"}>
        <Radio
          onChange={() => handlePaymentChange("UPI")}
          value={"UPI"}
          checked={selectedPayment === "UPI"}
          label={"UPI"}
        />
        <Radio
          onChange={() => handlePaymentChange("wallet")}
          checked={selectedPayment === "wallet"}
          value={"wallet"}
          label={"Mogo Wallet"}
        />
        <Radio
          onChange={() => handlePaymentChange("card")}
          checked={selectedPayment === "card"}
          value={"Card"}
          label={"Credit / Debit / ATM Card"}
        />
        <Radio
          onChange={() => handlePaymentChange("netbanking")}
          checked={selectedPayment === "netbanking"}
          value={"netBanking"}
          label={"Net Banking"}
        />
        <Radio
          onChange={() => handlePaymentChange("Emi")}
          checked={selectedPayment === "Emi"}
          value={"emi"}
          label={"EMI (Easy Installments)"}
        />
        <Radio
          onChange={() => handlePaymentChange("COD")}
          checked={selectedPayment === "COD"}
          value={"cod"}
          label={"Cash on Delivery"}
        />
      </Flex>
    );
  };

  // Hanlde Place Order
  const handlePlaceOrderSubmit = () => {
    const product_idFilter = cartProducts.map((value) => value._id);
    const data = {
      user_id: sessionStorage.getItem("MogoUserAccessToken101"),
      product_id: product_idFilter,
      address_id: selectedAddress,
      payment_id: selectedPayment,
      payment: selectedPayment,
      cart_products: cartProducts,
    };
    handleOrderPlaceControl(data, navigate, dispatch);
  };

  // Redirect If CArt is Empty
  useEffect(() => {
    if (!sessionStorage.getItem("cartProducts")) {
      navigate("/products");
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Cart is Empty Add Products to Continue Checkout",
      });
    }
  }, [sessionStorage.getItem("cartProducts")]);

  const [validateUserAddress, setValidateUserAddress] = useState({
    address_type: 0,
    first_name: 0,
    last_name: 0,
    email: 0,
    number: 0,
    address: 0,
    country: 0,
    state: 0,
    city: 0,
    zip_code: 0,
  });

  const queryClient = useQueryClient();

  const handleEditAddressSubmit = () => {
    handleEditAddressControl(
      userAddress,
      setUserAddress,
      validateUserAddress,
      setValidateUserAddress,
      setAddressModalOpen,
      queryClient
    );
  };

  // handle User Address
  const handleCreateUserAddress = () => {
    hanldeCreateUserAddressControl(
      userAddress,
      setUserAddress,
      validateUserAddress,
      setValidateUserAddress,
      setAddressModalOpen,
      queryClient
    );
  };

  // Filter User Address
  const filterUserAddress = userAddressDetails?.data?.data?.filter(
    (value) => value.visibility_status === true
  );

  return (
    <div>
      <div className="checkout-div">
        <Container className="checkout-div-container" size={"82rem"}>
          <div className="checkout-div-container-main">
            <div className="checkout-div-container-main-checkout">
              <div className="checkout-div-container-main-checkout-shipping">
                <div className="checkout-div-container-main-checkout-shipping-heading">
                  <h1>Checkout</h1>
                </div>
                <div className="checkout-div-container-main-checkout-shipping-content">
                  <Stepper
                    active={active}
                    onStepClick={setActive}
                    allowNextStepsSelect={false}
                  >
                    <Stepper.Step
                      label={
                        userData.first_name ? userData.first_name : "Login"
                      }
                      description={
                        userData.first_name ? "Logged In" : "or Create Account"
                      }
                    >
                      {userData.first_name ? (
                        <Card
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                          className="padding-checkout"
                        >
                          <p>
                            <Flex align={"center"} gap={"0.2rem"}>
                              <Title order={5}>Name :</Title>
                              {userData.first_name} {userData.last_name}
                            </Flex>
                          </p>
                          <p>
                            <Flex align={"center"} gap={"0.2rem"}>
                              <Title order={5}>Email : </Title>
                              {userData.email}
                            </Flex>
                          </p>
                        </Card>
                      ) : (
                        <div className="checkout-div-container-main-checkout-shipping-guest">
                          <p>
                            You are checking out as a guest. Have an account?
                            &nbsp;
                            <span onClick={hanldeLoginModalOpen}>Login</span>
                          </p>
                        </div>
                      )}
                    </Stepper.Step>
                    <Stepper.Step
                      label="Delivery Address"
                      description="Address"
                    >
                      {userAddressDetails?.data.data.length > 0 ? (
                        radioDataAddress()
                      ) : (
                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                          <Grid pt={"lg"} pl={"sm"}>
                            <Title order={5}>No Address Found</Title>
                          </Grid>
                          <Text
                            pl={"lg"}
                            style={{ cursor: "pointer" }}
                            onClick={handleOpenModal}
                          >
                            <Flex align={"center"} gap={"0.3rem"}>
                              Click here to Add Address{" "}
                              <ArrowRight strokeWidth={1} />
                            </Flex>
                          </Text>
                        </Card>
                      )}
                    </Stepper.Step>
                    <Stepper.Step
                      label="Order Summary"
                      description="Cart Details"
                    >
                      <CheckOutCart />
                    </Stepper.Step>
                    <Stepper.Step label="Payment Options" description="Payment">
                      {paymentOptions()}
                    </Stepper.Step>
                    <Stepper.Completed></Stepper.Completed>
                  </Stepper>
                  <Group justify="center" mt="xl">
                    <Button
                      disabled={active === 0}
                      variant="default"
                      onClick={prevStep}
                    >
                      Back
                    </Button>
                    {active === 4 ? (
                      <Button
                        onClick={handlePlaceOrderSubmit}
                        className="header-div-container-cart-wishlist-sell-now-sellnow-button"
                      >
                        Place Order
                      </Button>
                    ) : (
                      <Button
                        className="header-div-container-cart-wishlist-sell-now-sellnow-button"
                        disabled={
                          active === 0
                            ? userData.first_name
                              ? false
                              : true
                            : active === 1
                            ? selectedAddress
                              ? false
                              : true
                            : active === 2
                            ? cartProducts.length > 0
                              ? false
                              : true
                            : active === 3
                            ? selectedPayment
                              ? false
                              : true
                            : ""
                        }
                        onClick={nextStep}
                      >
                        Next step
                      </Button>
                    )}
                  </Group>
                </div>
              </div>
              <div className="checkout-div-container-main-checkout-summary">
                <div className="checkout-div-container-main-checkout-summary-heading">
                  <h1>
                    Order Summery ({cartProducts && cartProducts?.length})
                  </h1>
                </div>
                <div className="checkout-div-container-main-checkout-summary-content">
                  <div className="checkout-div-container-main-checkout-summary-content-order-summary-details-show-product-total">
                    <div className="cart-div-container-main-right-details">
                      <p>
                        Price ( {cartProducts && cartProducts?.length} Items )
                      </p>
                      <p>
                        <span>₹ </span> {totalSalePrice}
                      </p>
                    </div>
                    <div className="cart-div-container-main-right-details">
                      <p>GST</p>
                      <p>
                        <span>Included</span>
                      </p>
                    </div>
                    <div className="cart-div-container-main-right-details">
                      <p>Shipping</p>
                      <p>
                        {/* <span>₹ </span>80 */}
                        <p style={{ color: "rgb(104, 171, 65)" }}>FREE</p>
                      </p>
                    </div>
                    <div className="hrline"></div>
                    <div className="cart-div-container-main-right-details">
                      <p>Total</p>
                      <p>
                        <span>₹ </span>
                        {totalSalePrice}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal
        zIndex={1212111}
        size="xl"
        opened={addressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="sellerbalance-edit-modal"
      >
        <div className="sellerbalance-edit-modal-header">
          <h4>
            {userAddressDetails?.data?.data ? "Edit Address" : "Add Address"}
          </h4>
        </div>
        <div className="sellerbalance-edit-modal-body">
          <div className="sellerbalance-edit-modal-body-content">
            <div className="user-profile-form-input">
              <Input.Wrapper
                error={`${
                  validateUserAddress.address_type === 1
                    ? "Address Type is Compulsory"
                    : ""
                }`}
                label="Addres Title"
              >
                <Input
                  placeholder="Addres Title"
                  value={userAddress.address_type}
                  onChange={(e) =>
                    setUserAddress({
                      ...userAddress,
                      address_type: e.target.value,
                    })
                  }
                />
              </Input.Wrapper>
            </div>
            <div className="shipping-address-model-form-input">
              <div className="user-profile-form-input">
                <Input.Wrapper
                  error={`${
                    validateUserAddress.first_name === 1
                      ? "First Name is Compulsory"
                      : ""
                  }`}
                  label="First Name"
                >
                  <Input
                    placeholder="First Name"
                    value={userAddress.first_name}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        first_name: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="user-profile-form-input">
                <Input.Wrapper
                  error={`${
                    validateUserAddress.last_name === 1
                      ? "Last Name is Compulsory"
                      : ""
                  }`}
                  label="Last Name"
                >
                  <Input
                    placeholder="Last Name"
                    value={userAddress.last_name}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        last_name: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="user-profile-form-input">
                <Input.Wrapper
                  label="Email Address"
                  error={`${
                    validateUserAddress.email === 1
                      ? "Email is Compulsory"
                      : validateUserAddress.email === 2
                      ? "Please Enter Valid email Address"
                      : ""
                  }`}
                >
                  <Input
                    placeholder="Your email"
                    value={userAddress.email}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        email: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="user-profile-form-input">
                <Input.Wrapper
                  label="Phone Number"
                  error={`${
                    validateUserAddress.number === 1
                      ? "Mobile Number is Compulsory"
                      : validateUserAddress.number === 2
                      ? "Please Enter Valid Mobile Number"
                      : ""
                  }`}
                >
                  <Input
                    placeholder="Phone Number"
                    value={userAddress.number}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        number: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
            </div>
            <div className="user-profile-form-input">
              <Input.Wrapper
                label="Address"
                error={`${
                  validateUserAddress.address === 1
                    ? "Address is Compulsory"
                    : ""
                }`}
              >
                <Input
                  placeholder="Address"
                  value={userAddress.address}
                  onChange={(e) =>
                    setUserAddress({
                      ...userAddress,
                      address: e.target.value,
                    })
                  }
                />
              </Input.Wrapper>
            </div>
            <div className="shipping-address-model-form-input">
              <div className="user-profile-form-input">
                <Select
                  error={`${
                    validateUserAddress.country === 1
                      ? "Country is Compulsory"
                      : ""
                  }`}
                  label="Country"
                  placeholder="Country"
                  searchable
                  rightSection={<img src={anglebottom} width={11} />}
                  data={addressCountries?.map((data) => ({
                    value: data,
                    label: data,
                  }))}
                  value={userAddress.country}
                  onChange={(e) =>
                    setUserAddress({ ...userAddress, country: e })
                  }
                />
              </div>
              <div className="user-profile-form-input">
                <Select
                  error={`${
                    validateUserAddress.state === 1 ? "State is Compulsory" : ""
                  }`}
                  label="State"
                  placeholder="State"
                  searchable
                  rightSection={<img src={anglebottom} width={11} />}
                  data={selectedListStates[
                    userAddress.country ? userAddress.country : "India"
                  ]?.map((data) => ({
                    value: data,
                    label: data,
                  }))}
                  value={userAddress.state}
                  onChange={(e) => setUserAddress({ ...userAddress, state: e })}
                />
              </div>
              <div className="user-profile-form-input">
                <Input.Wrapper
                  label="City"
                  error={`${
                    validateUserAddress.city === 1 ? "City is Compulsory" : ""
                  }`}
                >
                  <Input
                    placeholder="City"
                    value={userAddress.city}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        city: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="user-profile-form-input">
                <Input.Wrapper
                  error={`${
                    validateUserAddress.zip_code === 1
                      ? "Zip Code is Compulsory"
                      : ""
                  }`}
                  label="Zip Code"
                >
                  <Input
                    placeholder="Zip Code"
                    value={userAddress.zip_code}
                    onChange={(e) =>
                      setUserAddress({
                        ...userAddress,
                        zip_code: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
            </div>
            <div className="sellerbalance-edit-modal-body-content-button ht-20">
              <button
                onClick={
                  editAddress
                    ? handleEditAddressSubmit
                    : handleCreateUserAddress
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Checkout;
