import { showNotification } from "@mantine/notifications";
import {
  updateUserAPI,
  userLoginAPI,
  userRegisterAPI,
} from "../../../config/quries/users/usersQuery";
import { ThemeIcon } from "@mantine/core";
import { CircleCheck, X } from "tabler-icons-react";
import { setUserData } from "../../../StateHandler/Slice/UserSlice/UserSliceData";
import axios from "axios";
import config from "../../../config/server/Servers";
import { setLoginModalOpen } from "../../../StateHandler/Slice/Login/LoginSlice";

export const handleRegisterControl = async (
  userRegisterValue,
  setUserRegisterValue,
  validateUserRegisterValue,
  setValidateUserRegisterValue,
  setRegisterModalOpen
) => {
  const { first_name, last_name, email, password, conform_password, number } =
    userRegisterValue;
  const payload = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
    number: number,
  };
  if (first_name.trim()) {
    if (last_name.trim()) {
      if (email.trim()) {
        if (email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          if (number.trim()) {
            if (number.match(/^[0-9]{10}$/)) {
              if (password.trim()) {
                if (conform_password.trim()) {
                  if (password.trim() === conform_password.trim()) {
                    await userRegisterAPI(payload)
                      .then((result) => {
                        if (
                          result.data.data.result ===
                          "Mobile Number and Email ID Already Exists"
                        ) {
                          setValidateUserRegisterValue({
                            ...validateUserRegisterValue,
                            email: 3,
                          });
                          setValidateUserRegisterValue({
                            ...validateUserRegisterValue,
                            number: 3,
                          });
                          showNotification({
                            icon: (
                              <ThemeIcon
                                variant="light"
                                radius="xl"
                                size="xl"
                                color="red"
                              >
                                <X color="red" />
                              </ThemeIcon>
                            ),
                            message: "Email and Mobile number already Exists",
                          });
                        } else {
                          setUserData(result.data?.data);
                          sessionStorage.setItem(
                            "MogoUserAccessToken101",
                            result.data?.data?.insertedId
                          );
                          sessionStorage.setItem(
                            "MogoUserAccessToken102",
                            result.data?.data?.accessToken
                          );
                          showNotification({
                            icon: (
                              <ThemeIcon
                                variant="light"
                                radius="xl"
                                size="xl"
                                color="green"
                              >
                                <CircleCheck color="green" />
                              </ThemeIcon>
                            ),
                            message: "Register Successfully",
                          });
                          setRegisterModalOpen(false);
                          setUserRegisterValue({
                            ...userRegisterValue,
                            first_name: "",
                            last_name: "",
                            email: "",
                            password: "",
                            conform_password: "",
                          });
                          const body = {
                            email: email,
                            subject: "Greatings from Mogo Ecommerce Site",
                            template: `Welcome to Mogo Largest Ecommerce Site in India
                <p> Your Registration has been Successfully Completed <p>
                `,
                            header: ` <h1> Hello ${first_name} ${last_name} <h1/>
              `,
                            footerData: `
              <p> Keep Shopping. demo.themogo.com <p/>
              <p> for Support Contact -  support@mogo.com </p>
             `,
                          };
                          axios.post(
                            `${config.baseUrlApi}/registeremail`,
                            body
                          );
                        }
                      })
                      .catch(() => {
                        showNotification({
                          icon: (
                            <ThemeIcon
                              variant="light"
                              radius="xl"
                              size="xl"
                              color="red"
                            >
                              <X color="red" />
                            </ThemeIcon>
                          ),
                          message: "Error Registering User",
                        });
                      });
                  } else {
                    setValidateUserRegisterValue({
                      ...validateUserRegisterValue,
                      conform_password: 2,
                    });
                  }
                } else {
                  setValidateUserRegisterValue({
                    ...validateUserRegisterValue,
                    conform_password: 1,
                  });
                }
              } else {
                setValidateUserRegisterValue({
                  ...validateUserRegisterValue,
                  password: 1,
                });
              }
            } else {
              setValidateUserRegisterValue({
                ...validateUserRegisterValue,
                number: 2,
              });
            }
          } else {
            setValidateUserRegisterValue({
              ...validateUserRegisterValue,
              number: 1,
            });
          }
        } else {
          setValidateUserRegisterValue({
            ...validateUserRegisterValue,
            email: 2,
          });
        }
      } else {
        setValidateUserRegisterValue({
          ...validateUserRegisterValue,
          email: 1,
        });
      }
    } else {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        last_name: 1,
      });
    }
  } else {
    setValidateUserRegisterValue({
      ...validateUserRegisterValue,
      first_name: 1,
    });
  }
};

export const handleLoginControl = async (
  userLogin,
  setuserLogin,
  userLoginValidation,
  setUserLoginValidation,
  dispatch,
  queryClient
) => {
  const { email, password } = userLogin;
  const payload = {
    email: email,
    password: password,
  };
  if (email.trim()) {
    if (email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      if (password.trim()) {
        await userLoginAPI(payload)
          .then((result) => {
            const first_name = result.data.data.first_name;
            const last_name = result.data.data.last_name;
            sessionStorage.setItem(
              "MogoUserAccessToken101",
              result.data?.data?._id
            );
            sessionStorage.setItem(
              "MogoUserAccessToken102",
              result.data?.data?.accessToken
            );
            sessionStorage.removeItem("wishList");
            sessionStorage.removeItem("cartProducts");
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <CircleCheck color="green" />
                </ThemeIcon>
              ),
              message: "Login Successfully",
            });
            setuserLogin({
              ...userLogin,
              email: "",
              password: "",
            });
            dispatch(setLoginModalOpen(false));
            queryClient.invalidateQueries("userData");
            window.location.reload();
            const body = {
              email: email,
              subject: "New Device Logged in ",
              template: `Welcome to Mogo Largest Ecommerce Site in India
              `,
              header: ` <h1> Hello ${first_name} ${last_name} <h1/>
            `,
              footerData: `
            <p> Keep Shopping. demo.themogo.com <p/>
            <p> for Support Contact -  support@mogo.com </p>
            `,
            };
            axios.post(`${config.baseUrlApi}/loginemail`, body);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Incorrect Password",
              });
              setUserLoginValidation({ ...userLoginValidation, password: 2 });
            } else if (err.response.status == 500) {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "User Not Found",
              });
              setUserLoginValidation({ ...userLoginValidation, email: 3 });
            }
          });
      } else {
        setUserLoginValidation({ ...userLoginValidation, password: 1 });
      }
    } else {
      setUserLoginValidation({ ...userLoginValidation, email: 2 });
    }
  } else {
    setUserLoginValidation({ ...userLoginValidation, email: 1 });
  }
};

export const handleUpdateUserControl = async (
  updateUser,
  setUpdateUser,
  validateUser,
  setValidateUser,
  queryClient,
  userData
) => {
  const { first_name, last_name, number, profile_image } = updateUser;
  const formData = new FormData();
  const body = {};
  if (profile_image === undefined) {
    body.first_name = first_name;
    body.last_name = last_name;
    body.number = number;
  } else if (profile_image) {
    formData.append("first_name", first_name.trim());
    formData.append("last_name", last_name.trim());
    formData.append("mobile_number", number.trim());
    formData.append("file", profile_image);
  }
  if (first_name) {
    if (last_name) {
      if (number) {
        if (profile_image.name !== undefined) {
          await axios
            .put(
              `${config.baseUrlApi}/users/updateuser/${sessionStorage.getItem(
                "MogoUserAccessToken101"
              )}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${sessionStorage.getItem(
                    "MogoUserAccessToken102"
                  )}`,
                },
              }
            )
            .then(() => {
              showNotification({
                icon: (
                  <ThemeIcon
                    variant="light"
                    radius="xl"
                    size="xl"
                    color="green"
                  >
                    <CircleCheck color="green" />
                  </ThemeIcon>
                ),
                message: "Profile Updated Successfully",
              });
              setUpdateUser({
                ...updateUser,
                email: "",
                first_name: "",
                last_name: "",
                number: "",
              });
              queryClient.invalidateQueries("userData");
            })
            .catch(() => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Error Updating Profile",
              });
            });
        } else {
          await axios
            .put(
              `${
                config.baseUrlApi
              }/users/updateuserwithoutimage/${sessionStorage.getItem(
                "MogoUserAccessToken101"
              )}`,
              body,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem(
                    "MogoUserAccessToken102"
                  )}`,
                },
              }
            )
            .then(() => {
              showNotification({
                icon: (
                  <ThemeIcon
                    variant="light"
                    radius="xl"
                    size="xl"
                    color="green"
                  >
                    <CircleCheck color="green" />
                  </ThemeIcon>
                ),
                message: "Profile Updated Successfully",
              });
              queryClient.invalidateQueries("userData");
            })
            .catch(() => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Error Updating Profile",
              });
            });
        }
      } else {
        setValidateUser({ ...validateUser, number: 1 });
      }
    } else {
      setValidateUser({ ...validateUser, last_name: 1 });
    }
  } else {
    setValidateUser({ ...validateUser, first_name: 1 });
  }
};
