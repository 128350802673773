import { Button, Center, Flex, Grid, Title } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

const Comming_Soon = () => {
  return (
    <div>
      <Flex h={"100vh"} align={"center"} justify={"center"}>
        <Center>
          <Flex gap={4} direction="column">
            <Title>Comming Soon...</Title>
            <Button component={Link} to={"/"} style={{ width: "fitContent" }}>
              Get back to Home
            </Button>
          </Flex>
        </Center>
      </Flex>
    </div>
  );
};

export default Comming_Soon;
