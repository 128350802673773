//import react packages
import React, { useEffect, useState } from "react";

//import mantine packages
import {
  Button,
  Container,
  Flex,
  Input,
  Radio,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

//import Cart css
import "./Cart.css";

//import icons
import close from "../../../assets/cart/close.webp";
import arrowleft from "../../../assets/cart/arrowleft.webp";

//import images
import amex from "../../../assets/footer/copyright/amex.webp";
import discover from "../../../assets/footer/copyright/discover.webp";
import maestro from "../../../assets/footer/copyright/maestro.webp";
import mastercard from "../../../assets/footer/copyright/mastercard.webp";
import visa from "../../../assets/footer/copyright/visa.webp";

//import react router dom packages
import { Link, useNavigate } from "react-router-dom";

// Config
import config from "../../../config/server/Servers";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, InfoCircle } from "tabler-icons-react";

const Cart = () => {
  // Navigator
  const navigate = useNavigate();
  // Quantity Value
  const cartProduct = useSelector((state) => state.cartData.value);
  const [totalSalePrice, setTotalSalePrice] = useState();
  const [refetchCartProduct, setrefetchCartProduct] = useState(false);
  const dispatch = useDispatch();
  const handleRemoveFromCart = (e) => {
    const filterCartData = JSON.parse(
      sessionStorage.getItem("cartProducts")
    ).filter((value) => value._id !== e);
    sessionStorage.setItem("cartProducts", JSON.stringify(filterCartData));
    dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="green">
          <CircleCheck color="green" />
        </ThemeIcon>
      ),
      message: "Product Removed from Cart",
    });
  };

  const handleAddCartQuantity = (productId, quantity, index) => {
    const filterCart = cartProduct.filter((value) => value._id === productId);
    const removeThatProduct = cartProduct.filter(
      (value) => value._id !== productId
    );
    const addQuantity = { ...filterCart[0], quantity: quantity };
    const newCartData = [...removeThatProduct];
    newCartData.splice(index, 0, addQuantity);
    sessionStorage.setItem("cartProducts", JSON.stringify(newCartData));
    setrefetchCartProduct(!refetchCartProduct);
  };
  useEffect(() => {
    dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
  }, [refetchCartProduct, !refetchCartProduct]);

  useEffect(() => {
    if (cartProduct) {
      const totalCartProductPrice = cartProduct?.map((value) =>
        Number(value.sale_price)
      );
      const totalQuantityPerProduct = cartProduct?.map((value) =>
        Number(value.quantity)
      );
      const resultArray = [];
      if (totalCartProductPrice.length === totalQuantityPerProduct.length) {
        for (let i = 0; i < totalQuantityPerProduct.length; i++) {
          resultArray.push(
            totalQuantityPerProduct[i] * totalCartProductPrice[i]
          );
        }
      }
      let sum = 0;
      for (let i = 0; i < resultArray.length; i++) {
        sum += resultArray[i];
        setTotalSalePrice(sum);
      }
    }
  }, [cartProduct, !refetchCartProduct]);

  // Navigat to Product
  const hanldeNavigateToProduct = (value) => {
    const category = value?.CategoryDetails[0].name.replaceAll(" ", "_");
    const subCategory = value?.subCategoryDetails[0].name.replaceAll(" ", "_");
    navigate(`/product/${category}/${subCategory}/${value._id}`);
  };

  return (
    <div>
      <div className="cart-div">
        {cartProduct && cartProduct.length >= 1 ? (
          <Container className="cart-div-container" size={"82rem"}>
            <div className="cart-div-container-main">
              <div className="cart-div-container-main-left">
                <div className="cart-div-container-main-left-main">
                  <div className="cart-div-container-main-heading">
                    <h1>My Cart ({cartProduct.length})</h1>
                  </div>
                  {Array.isArray(cartProduct)
                    ? cartProduct?.map((value, index) => {
                        const quantity = value.quantity ? value.quantity : 1;
                        return (
                          <div
                            key={index}
                            className="cart-div-container-main-cart-products"
                          >
                            <div className="cart-div-container-main-cart-products-image">
                              <div className="cart-div-container-main-cart-products-image-img">
                                <img
                                  src={`${config.baseUrlApi}/assets/productImages/${value.product_image}`}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="cart-div-container-main-cart-products-content">
                              <div className="cart-div-container-main-cart-products-content-heading">
                                <p>{value.name}</p>
                              </div>
                              <div className="cart-div-container-main-cart-products-content-vendor">
                                <p>
                                  By <span>Mogo</span>
                                </p>
                              </div>
                              <div className="cart-div-container-main-cart-products-content-price mt-15">
                                <div className="cart-div-container-main-cart-products-content-price-heading">
                                  <p>Unit Price:</p>
                                </div>
                                <div className="cart-div-container-main-cart-products-content-price-price">
                                  <p>
                                    <span></span>
                                    {value.sale_price ? value.sale_price : 199}
                                  </p>
                                </div>
                              </div>
                              <div className="cart-div-container-main-cart-products-content-price">
                                <div className="cart-div-container-main-cart-products-content-price-heading">
                                  <p>Total:</p>
                                </div>
                                <div className="cart-div-container-main-cart-products-content-price-price">
                                  <p>
                                    <span>₹</span>
                                    {value.sale_price
                                      ? value.sale_price * quantity
                                      : 199 * quantity}
                                  </p>
                                </div>
                              </div>
                              <div className="cart-div-container-main-cart-products-content-button">
                                <button
                                  onClick={() =>
                                    handleRemoveFromCart(value._id)
                                  }
                                >
                                  <img
                                    src={close}
                                    width={10}
                                    alt="close icon"
                                  />
                                  Remove
                                </button>
                                <button
                                  onClick={() => hanldeNavigateToProduct(value)}
                                >
                                  <InfoCircle
                                    className="info-icon-cart"
                                    size={15}
                                    strokeWidth={1}
                                  />
                                  View
                                </button>
                              </div>
                            </div>
                            <div className="cart-div-container-main-cart-products-count">
                              <div className="product-div-container-main-product-display-content-counter-cart-wishlist-counter">
                                <button
                                  disabled={quantity === 1}
                                  onClick={() =>
                                    handleAddCartQuantity(
                                      value._id,
                                      quantity - 1,
                                      index
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  value={value.quantity ? value.quantity : 1}
                                  disabled
                                  className="product-div-container-main-product-display-content-counter-cart-wishlist-counter-input"
                                />
                                <button
                                  onClick={() =>
                                    handleAddCartQuantity(
                                      value._id,
                                      quantity + 1,
                                      index
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <div className="cart-div-container-main-left-keep-shoping mt-15">
                  <Text component={Link} to={"/"}>
                    <button>
                      <img src={arrowleft} width={14} alt="arrow left icon" />
                      Keep Shopping
                    </button>
                  </Text>
                </div>
              </div>
              <div className="cart-div-container-main-right">
                <div className="cart-div-container-main-right-details">
                  <p>Price ({cartProduct.length} items)</p>
                  <p>
                    <span>₹ </span>
                    {totalSalePrice}
                  </p>
                </div>
                <div className="cart-div-container-main-right-details">
                  <p>Shipping Charges</p>
                  <p>
                    <p style={{ color: "rgb(104, 171, 65)" }}>FREE</p>
                  </p>
                </div>
                <div className="hrline"></div>
                <div className="cart-div-container-main-right-details">
                  <p>Total</p>
                  <p>
                    <span>₹ </span>
                    {totalSalePrice}
                  </p>
                </div>
                <div className="cart-div-container-main-right-checkout-button mb-15">
                  <Text component={Link} to="/checkout">
                    <button>Continue to Checkout</button>
                  </Text>
                </div>
                <div className="cart-div-container-main-right-checkout-accepted-payments">
                  <img src={visa} width={42} height={26} alt="" />
                  <img src={mastercard} width={42} height={26} alt="" />
                  <img src={maestro} width={42} height={26} alt="" />
                  <img src={discover} width={42} height={26} alt="" />
                  <img src={amex} width={42} height={26} alt="" />
                </div>
                <div className="hrline"></div>
                <div className="cart-div-container-main-right-discount-coupen-form">
                  <label htmlFor="Discount Coupen">Discount Coupen</label>
                  <div className="cart-div-container-main-right-discount-coupen-form-form">
                    <div className="cart-div-container-main-right-discount-coupen-form-input">
                      <Input placeholder="Coupon Code" />
                    </div>
                    <div className="cart-div-container-main-right-discount-coupen-form-button">
                      <button>Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Container className="cart-div-container cart-empty" size={"82rem"}>
            <Flex justify={"center"} align={"center"} w={"100%"} h={"100%"}>
              <Title>Cart is Empty</Title>
            </Flex>
          </Container>
        )}
      </div>
    </div>
  );
};

export default Cart;
