//import react packages
import React, { useMemo, useState } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import { Card, Text, Group, Input, Pagination, Select } from "@mantine/core";

//import mantine models
import { modals } from "@mantine/modals";

//import Earnings css
import "./Earnings.css";

//import icons
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import anglebottom from "../../../assets/admin/table/anglebottom.webp";
import trash from "../../../assets/admin/table/dropdown/trash.png";
import ReactDatePicker from "react-datepicker";

const Earnings = () => {
  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Delete your profile",
      children: (
        <Text size="sm">
          Are you sure you want to delete your profile? This action is
          destructive and you will have to contact support to restore your data.
        </Text>
      ),
      labels: { confirm: "Delete account", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
    });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const handleDateChange = (update) => {
    if (Array.isArray(update) && update.length === 2) {
      const [newStartDate, newEndDate] = update;

      if (newStartDate && newEndDate) {
        const formattedStartDate = newStartDate.toLocaleDateString("en-US");
        const formattedEndDate = newEndDate.toLocaleDateString("en-US");
        const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setFormattedDateRange(formattedDateRange);
      } else {
        setFormattedDateRange("");
      }
    } else {
      setSelectedDate(update);
    }
  };

  const COLUMN = [
    {
      Header: "S.No",
      accessor: "id",
    },
    {
      Header: "Order Id",
      accessor: "Order",
    },
    {
      Header: "User",
      accessor: "User",
    },
    {
      Header: "Product Name",
      accessor: "Product_Name",
    },
    {
      Header: "Amount",
      accessor: "Amount",
    },
    {
      Header: "GST",
      accessor: "TAX",
    },
    {
      Header: "Shipping Cost",
      accessor: "Shipping_Cost",
    },
    {
      Header: "Discount",
      accessor: "Discount_Coupon",
    },
    {
      Header: "Total",
      accessor: "Total",
    },
    {
      Header: "Earnings",
      accessor: "Earned_Amount",
    },
    {
      Header: "Date",
      accessor: "Date",
    },
  ];

  const tableData = [
    {
      id: 1,
      Order: 1,
      User: "Pebrook",
      Product_Name: "Bath Towal",
      Amount: "₹520.91",
      Total: "₹520.91",
      TAX: "₹8.26",
      Discount_Coupon: "₹3.96",
      Shipping_Cost: "₹4.51",
      Earned_Amount: "₹1.11",
      Date: "9/23/2022",
    },
    {
      id: 2,
      Order: 2,
      User: "Vincenty",
      Product_Name: "Bath Towal",
      Amount: "₹815.63",
      Total: "₹815.63",
      TAX: "₹1.42",
      Discount_Coupon: "₹1.43",
      Shipping_Cost: "₹6.52",
      Earned_Amount: "₹3.73",
      Date: "11/3/2022",
    },
    {
      id: 3,
      Order: 3,
      User: "Allayne",
      Product_Name: "Bath Towal",
      Amount: "₹954.20",
      Total: "₹954.20",
      TAX: "₹6.66",
      Discount_Coupon: "₹3.23",
      Shipping_Cost: "₹6.10",
      Earned_Amount: "₹1.45",
      Date: "5/29/2023",
    },
    {
      id: 4,
      Order: 4,
      User: "Esra",
      Product_Name: "Bath Towal",
      Amount: "₹339.43",
      Total: "₹339.43",
      TAX: "₹7.69",
      Discount_Coupon: "₹0.98",
      Shipping_Cost: "₹2.43",
      Earned_Amount: "₹1.30",
      Date: "8/23/2023",
    },
    {
      id: 5,
      Order: 5,
      User: "Bill",
      Product_Name: "Bath Towal",
      Amount: "₹253.42",
      Total: "₹253.42",
      TAX: "₹1.45",
      Discount_Coupon: "₹3.75",
      Shipping_Cost: "₹1.47",
      Earned_Amount: "₹0.39",
      Date: "7/23/2023",
    },
    {
      id: 6,
      Order: 6,
      User: "Burgess",
      Product_Name: "Bath Towal",
      Amount: "₹19.84",
      Total: "₹19.84",
      TAX: "₹7.17",
      Discount_Coupon: "₹1.88",
      Shipping_Cost: "₹8.77",
      Earned_Amount: "₹1.35",
      Date: "11/22/2022",
    },
    {
      id: 7,
      Order: 7,
      User: "Oates",
      Product_Name: "Bath Towal",
      Amount: "₹156.40",
      Total: "₹156.40",
      TAX: "₹0.85",
      Discount_Coupon: "₹4.43",
      Shipping_Cost: "₹4.55",
      Earned_Amount: "₹2.73",
      Date: "2/25/2023",
    },
    {
      id: 8,
      Order: 8,
      User: "Roderigo",
      Product_Name: "Bath Towal",
      Amount: "₹99.58",
      Total: "₹99.58",
      TAX: "₹3.52",
      Discount_Coupon: "₹2.17",
      Shipping_Cost: "₹3.85",
      Earned_Amount: "₹0.42",
      Date: "6/2/2023",
    },
    {
      id: 9,
      Order: 9,
      User: "Kerwin",
      Product_Name: "Bath Towal",
      Amount: "₹87.82",
      Total: "₹87.82",
      TAX: "₹3.64",
      Discount_Coupon: "₹2.47",
      Shipping_Cost: "₹7.84",
      Earned_Amount: "₹1.94",
      Date: "2/18/2023",
    },
    {
      id: 10,
      Order: 10,
      User: "Justen",
      Product_Name: "Bath Towal",
      Amount: "₹168.44",
      Total: "₹168.44",
      TAX: "₹9.62",
      Discount_Coupon: "₹1.88",
      Shipping_Cost: "₹1.22",
      Earned_Amount: "₹0.56",
      Date: "8/15/2023",
    },
    {
      id: 11,
      Order: 11,
      User: "Man",
      Product_Name: "Bath Towal",
      Amount: "₹676.44",
      Total: "₹676.44",
      TAX: "₹3.39",
      Discount_Coupon: "₹4.75",
      Shipping_Cost: "₹2.83",
      Earned_Amount: "₹4.72",
      Date: "9/30/2022",
    },
    {
      id: 12,
      Order: 12,
      User: "Saul",
      Product_Name: "Bath Towal",
      Amount: "₹78.49",
      Total: "₹78.49",
      TAX: "₹5.54",
      Discount_Coupon: "₹2.26",
      Shipping_Cost: "₹1.88",
      Earned_Amount: "₹0.43",
      Date: "3/17/2023",
    },
    {
      id: 13,
      Order: 13,
      User: "Chick",
      Product_Name: "Bath Towal",
      Amount: "₹146.51",
      Total: "₹146.51",
      TAX: "₹6.96",
      Discount_Coupon: "₹1.30",
      Shipping_Cost: "₹0.09",
      Earned_Amount: "₹1.06",
      Date: "11/1/2022",
    },
    {
      id: 14,
      Order: 14,
      User: "Erny",
      Product_Name: "Bath Towal",
      Amount: "₹578.60",
      Total: "₹578.60",
      TAX: "₹4.57",
      Discount_Coupon: "₹2.18",
      Shipping_Cost: "₹6.13",
      Earned_Amount: "₹0.24",
      Date: "1/2/2023",
    },
    {
      id: 15,
      Order: 15,
      User: "Roma",
      Product_Name: "Bath Towal",
      Amount: "₹632.40",
      Total: "₹632.40",
      TAX: "₹2.57",
      Discount_Coupon: "₹4.12",
      Shipping_Cost: "₹0.34",
      Earned_Amount: "₹2.87",
      Date: "4/21/2023",
    },
    {
      id: 16,
      Order: 16,
      User: "Agosto",
      Product_Name: "Bath Towal",
      Amount: "₹789.85",
      Total: "₹789.85",
      TAX: "₹1.25",
      Discount_Coupon: "₹2.00",
      Shipping_Cost: "₹7.05",
      Earned_Amount: "₹3.17",
      Date: "3/4/2023",
    },
    {
      id: 17,
      Order: 17,
      User: "Kahaleel",
      Product_Name: "Bath Towal",
      Amount: "₹635.41",
      Total: "₹635.41",
      TAX: "₹8.42",
      Discount_Coupon: "₹4.54",
      Shipping_Cost: "₹0.67",
      Earned_Amount: "₹0.10",
      Date: "5/17/2023",
    },
    {
      id: 18,
      Order: 18,
      User: "Theobald",
      Product_Name: "Bath Towal",
      Amount: "₹336.77",
      Total: "₹336.77",
      TAX: "₹2.28",
      Discount_Coupon: "₹2.43",
      Shipping_Cost: "₹1.76",
      Earned_Amount: "₹0.93",
      Date: "6/9/2023",
    },
    {
      id: 19,
      Order: 19,
      User: "Kincaid",
      Product_Name: "Bath Towal",
      Amount: "₹702.39",
      Total: "₹702.39",
      TAX: "₹4.78",
      Discount_Coupon: "₹2.87",
      Shipping_Cost: "₹1.47",
      Earned_Amount: "₹4.78",
      Date: "6/9/2023",
    },
    {
      id: 20,
      Order: 20,
      User: "Sutherlan",
      Product_Name: "Bath Towal",
      Amount: "₹377.59",
      Total: "₹377.59",
      TAX: "₹4.81",
      Discount_Coupon: "₹1.60",
      Shipping_Cost: "₹7.09",
      Earned_Amount: "₹4.77",
      Date: "7/27/2023",
    },
    {
      id: 21,
      Order: 21,
      User: "Barron",
      Product_Name: "Bath Towal",
      Amount: "₹724.59",
      Total: "₹724.59",
      TAX: "₹7.96",
      Discount_Coupon: "₹1.13",
      Shipping_Cost: "₹5.69",
      Earned_Amount: "₹3.45",
      Date: "8/20/2023",
    },
    {
      id: 22,
      Order: 22,
      User: "Brennan",
      Product_Name: "Bath Towal",
      Amount: "₹47.36",
      Total: "₹47.36",
      TAX: "₹7.18",
      Discount_Coupon: "₹1.79",
      Shipping_Cost: "₹4.90",
      Earned_Amount: "₹0.77",
      Date: "12/22/2022",
    },
    {
      id: 23,
      Order: 23,
      User: "Hayden",
      Product_Name: "Bath Towal",
      Amount: "₹610.24",
      Total: "₹610.24",
      TAX: "₹3.13",
      Discount_Coupon: "₹1.94",
      Shipping_Cost: "₹3.32",
      Earned_Amount: "₹3.53",
      Date: "11/16/2022",
    },
    {
      id: 24,
      Order: 24,
      User: "Mathian",
      Product_Name: "Bath Towal",
      Amount: "₹495.35",
      Total: "₹495.35",
      TAX: "₹9.44",
      Discount_Coupon: "₹2.68",
      Shipping_Cost: "₹4.34",
      Earned_Amount: "₹3.58",
      Date: "12/4/2022",
    },
    {
      id: 25,
      Order: 25,
      User: "Zechariah",
      Product_Name: "Bath Towal",
      Amount: "₹871.22",
      Total: "₹871.22",
      TAX: "₹3.65",
      Discount_Coupon: "₹3.66",
      Shipping_Cost: "₹0.13",
      Earned_Amount: "₹3.63",
      Date: "9/4/2023",
    },
    {
      id: 26,
      Order: 26,
      User: "Adair",
      Product_Name: "Bath Towal",
      Amount: "₹698.27",
      Total: "₹698.27",
      TAX: "₹1.71",
      Discount_Coupon: "₹2.51",
      Shipping_Cost: "₹6.21",
      Earned_Amount: "₹0.42",
      Date: "7/13/2023",
    },
    {
      id: 27,
      Order: 27,
      User: "Mic",
      Product_Name: "Bath Towal",
      Amount: "₹73.00",
      Total: "₹73.00",
      TAX: "₹3.53",
      Discount_Coupon: "₹2.70",
      Shipping_Cost: "₹1.20",
      Earned_Amount: "₹2.92",
      Date: "3/31/2023",
    },
    {
      id: 28,
      Order: 28,
      User: "Laughton",
      Product_Name: "Bath Towal",
      Amount: "₹940.98",
      Total: "₹940.98",
      TAX: "₹5.85",
      Discount_Coupon: "₹0.93",
      Shipping_Cost: "₹6.65",
      Earned_Amount: "₹3.60",
      Date: "8/10/2023",
    },
    {
      id: 29,
      Order: 29,
      User: "Gregorio",
      Product_Name: "Bath Towal",
      Amount: "₹998.12",
      Total: "₹998.12",
      TAX: "₹9.66",
      Discount_Coupon: "₹2.99",
      Shipping_Cost: "₹4.55",
      Earned_Amount: "₹1.11",
      Date: "11/29/2022",
    },
    {
      id: 30,
      Order: 30,
      User: "Lawrence",
      Product_Name: "Bath Towal",
      Amount: "₹982.32",
      Total: "₹982.32",
      TAX: "₹8.53",
      Discount_Coupon: "₹2.23",
      Shipping_Cost: "₹4.93",
      Earned_Amount: "₹1.57",
      Date: "5/11/2023",
    },
    {
      id: 31,
      Order: 31,
      User: "Haven",
      Product_Name: "Bath Towal",
      Amount: "₹540.35",
      Total: "₹540.35",
      TAX: "₹4.39",
      Discount_Coupon: "₹4.97",
      Shipping_Cost: "₹8.66",
      Earned_Amount: "₹2.44",
      Date: "12/30/2022",
    },
    {
      id: 32,
      Order: 32,
      User: "Staffard",
      Product_Name: "Bath Towal",
      Amount: "₹159.61",
      Total: "₹159.61",
      TAX: "₹1.50",
      Discount_Coupon: "₹1.08",
      Shipping_Cost: "₹2.39",
      Earned_Amount: "₹1.65",
      Date: "12/29/2022",
    },
    {
      id: 33,
      Order: 33,
      User: "Edmund",
      Product_Name: "Bath Towal",
      Amount: "₹616.98",
      Total: "₹616.98",
      TAX: "₹0.75",
      Discount_Coupon: "₹1.30",
      Shipping_Cost: "₹0.68",
      Earned_Amount: "₹2.34",
      Date: "5/30/2023",
    },
    {
      id: 34,
      Order: 34,
      User: "Vaughan",
      Product_Name: "Bath Towal",
      Amount: "₹323.04",
      Total: "₹323.04",
      TAX: "₹9.67",
      Discount_Coupon: "₹4.46",
      Shipping_Cost: "₹5.49",
      Earned_Amount: "₹2.11",
      Date: "1/17/2023",
    },
    {
      id: 35,
      Order: 35,
      User: "Ilario",
      Product_Name: "Bath Towal",
      Amount: "₹718.80",
      Total: "₹718.80",
      TAX: "₹2.34",
      Discount_Coupon: "₹3.70",
      Shipping_Cost: "₹9.70",
      Earned_Amount: "₹2.46",
      Date: "3/19/2023",
    },
    {
      id: 36,
      Order: 36,
      User: "Smith",
      Product_Name: "Bath Towal",
      Amount: "₹725.39",
      Total: "₹725.39",
      TAX: "₹9.17",
      Discount_Coupon: "₹4.84",
      Shipping_Cost: "₹3.28",
      Earned_Amount: "₹1.68",
      Date: "1/16/2023",
    },
    {
      id: 37,
      Order: 37,
      User: "Ilario",
      Product_Name: "Bath Towal",
      Amount: "₹571.34",
      Total: "₹571.34",
      TAX: "₹7.61",
      Discount_Coupon: "₹3.42",
      Shipping_Cost: "₹3.86",
      Earned_Amount: "₹1.29",
      Date: "2/6/2023",
    },
    {
      id: 38,
      Order: 38,
      User: "Dill",
      Product_Name: "Bath Towal",
      Amount: "₹549.02",
      Total: "₹549.02",
      TAX: "₹9.97",
      Discount_Coupon: "₹1.52",
      Shipping_Cost: "₹8.62",
      Earned_Amount: "₹2.67",
      Date: "2/22/2023",
    },
    {
      id: 39,
      Order: 39,
      User: "Findlay",
      Product_Name: "Bath Towal",
      Amount: "₹521.77",
      Total: "₹521.77",
      TAX: "₹4.62",
      Discount_Coupon: "₹1.71",
      Shipping_Cost: "₹3.74",
      Earned_Amount: "₹1.85",
      Date: "9/22/2023",
    },
    {
      id: 40,
      Order: 40,
      User: "Preston",
      Product_Name: "Bath Towal",
      Amount: "₹905.76",
      Total: "₹905.76",
      TAX: "₹6.70",
      Discount_Coupon: "₹0.36",
      Shipping_Cost: "₹6.30",
      Earned_Amount: "₹4.80",
      Date: "8/1/2023",
    },
  ];

  // React Table
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => tableData, []),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Status Filter
  const [selectedStatus] = useState("All");
  const [selectedPaymentStatus] = useState("All");

  return (
    <div className='min-height-90vh'>
      <div className="earnings-div">
        <Card className="earnings-count">
          <div className="earnings-count-left">
            <h2>₹200.99</h2>
            <p>Balance</p>
          </div>
          <div className="earnings-count-right">
            <i className="fa fa-money" />
          </div>
        </Card>
        <Card >
          <div className="earnings-div-heading pb-15">
            <h2>Earnings</h2>
          </div>
          <div className="earnings-div-table">
            <div>
              <div className="earnings-filter-container">
                <div className="earnings-filter-container-div1">
                  <Select
                    label="Show"
                    onChange={(e) => setPageSize(Number(e))}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"10"}
                    data={[
                      { value: "10", label: "10", key: "10" },
                      { value: "20", label: "20", key: "20" },
                      { value: "30", label: "30", key: "30" },
                      { value: "40", label: "40", key: "40" },
                      { value: pageCount * 10, label: "All", key: "All" },
                    ]}
                  />
                </div>
                <div className="earnings-filter-container-div4">
                  <Input.Wrapper label="Search" maw={320} mx="auto">
                    <Input
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Filter"
                    />
                  </Input.Wrapper>
                </div>
                <div className="soldproducts-filter-container-div5">
                  <Input.Wrapper label="Date" maw={320} mx="auto">
                    <ReactDatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                        handleDateChange(update);
                      }}
                      isClearable={true}
                      maxDate={currentDate}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="table-style">
                <table {...getTableProps()} className="custum-table-style">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((header) => (
                          <th {...header.getHeaderProps()}>
                            {header.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page
                        .filter(
                          (row) =>
                            selectedStatus === "All" ||
                            row.values.Status === selectedStatus
                        )
                        .filter(
                          (row) =>
                            selectedPaymentStatus === "All" ||
                            row.values.Payment_Status === selectedPaymentStatus
                        )
                        .map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          Nothing Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="react-table-pagination">
                <Pagination.Root
                  total={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  value={pageIndex + 1}
                  onChange={(e) => gotoPage(e - 1)}
                >
                  <Group spacing={5} position="center">
                    <Pagination.First
                      disabled={!canPreviousPage}
                      onClick={() => gotoPage(0)}
                    />
                    <Pagination.Previous
                      disabled={!canPreviousPage}
                      onClick={() => {
                        previousPage();
                        gotoPage(pageIndex - 1);
                      }}
                    />
                    <Pagination.Items value={2} />
                    <Pagination.Next
                      disabled={!canNextPage}
                      onClick={() => {
                        nextPage();
                        gotoPage(pageIndex + 1);
                      }}
                    />
                    <Pagination.Last
                      disabled={!canNextPage}
                      onClick={() => gotoPage(pageCount - 1)}
                    />
                  </Group>
                </Pagination.Root>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Earnings;
