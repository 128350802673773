//import react packages
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

//import mantine components
import { Container, Rating, Center, ThemeIcon } from "@mantine/core";

//import OfferSlider css
import "./OfferSlider.css";

import { Carousel } from "@mantine/carousel";

import Autoplay from "embla-carousel-autoplay";

//import react-router-dom packages
import { Link } from "react-router-dom";

//import images
import cart from "../../../assets/header/cart.webp";
import { useDispatch, useSelector } from "react-redux";
import { listAllProduct } from "../../../config/quries/Products/ProductQuries";
import { useQuery } from "react-query";
import config from "../../../config/server/Servers";
import ReactHtmlParser from "react-html-parser";
import { categoryById } from "../../../config/quries/Category/CategoryQueries";
import { subCategorById } from "../../../config/quries/SubCategory/SubCategoryQuries";
import { handleAddtoCartControl } from "../../../controller/cart/cartController";
import { useMediaQuery } from "@mantine/hooks";
import Cookies from "js-cookie";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";
import { setWishList } from "../../../StateHandler/Slice/wishList/WishListSlice";
import { showNotification } from "@mantine/notifications";
import {
  CircleCheck,
  Heart,
  InfoCircle,
  ShoppingCart,
} from "tabler-icons-react";

const OfferSlider = ({ header, header2 }) => {
  const navigate = useNavigate();
  const [wishProductIds, setWishListProductsIds] = useState();
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const header1000MediaQuery = useMediaQuery("(max-width:1000px)");
  const slidesToShow = header1000MediaQuery ? 3 : 5;
  const calculatedSlideSize = `${100 / slidesToShow}%`;
  const wishListData = useSelector((state) => state.wishListData.value);

  // State Handler
  // const productList = useSelector((state) => state.productList.value);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();

  // const productList =
  useQuery("productList", listAllProduct, {
    refetchOnWindowFocus: true,
    onSuccess: (res) => {
      const filteredList = res?.data?.data?.result.filter(
        (value) => value.special === true
      );
      setProductList(filteredList);
    },
  });

  const [shuffledProducts, setShuffledProducts] = useState([]);
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  useEffect(() => {
    const shuffled = shuffleArray(productList);
    setShuffledProducts(shuffled);
  }, [productList]);

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  // handle Add to cart

  // Category
  useQuery(["categoryByid", categoryId], categoryById, {
    refetchOnWindowFocus: false,
    enabled: !!categoryId,
    onSuccess: (res) => {
      const filter = res.data?.data?.result?.name.replace(" ", "_");
      setCategoryName(filter);
    },
  });

  useQuery(["subcategoryByid", subCategoryId], subCategorById, {
    refetchOnWindowFocus: false,
    enabled: !!subCategoryId,
    onSuccess: (res) => {
      const filter = res.data?.data?.result?.name.replace(" ", "_");
      setSubCategoryName(filter);
    },
  });

  const hanldeNavigatePage = (product) => {
    setCategoryId(product.product_category);
    setSubCategoryId(product.product_subcategory);
    if (categoryId && subCategoryId) {
      navigate(`/product/${categoryName}/${subCategoryName}/${product._id}`);
    }
  };

  //Add to Cart
  const cartSessionValue = JSON.parse(sessionStorage.getItem("cartProducts"));
  const handleAddCartClick = (e) => {
    if (cartSessionValue) {
      const filterCart = cartSessionValue.filter(
        (value) => value._id === e._id
      );
      if (filterCart && filterCart.length > 0) {
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="blue">
        //       <InfoCircle color="blue" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Already Exists in Cart",
        // });
      } else {
        const cartProdutwithQuantity = { ...e, quantity: 1 };
        const newCart = [...cartSessionValue, cartProdutwithQuantity];
        sessionStorage.setItem("cartProducts", JSON.stringify(newCart));
        dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
        //       <CircleCheck color="green" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Added to Cart",
        // });
      }
    } else {
      const cartProdutwithQuantity = { ...e, quantity: 1 };
      sessionStorage.setItem(
        "cartProducts",
        JSON.stringify([cartProdutwithQuantity])
      );
      dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
      // showNotification({
      //   icon: (
      //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
      //       <CircleCheck color="green" />
      //     </ThemeIcon>
      //   ),
      //   message: "Product Added to Cart",
      // });
    }
  };

  useEffect(() => {
    dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
  }, []);

  // Add to Wish List
  const wishListSessionValue = JSON.parse(sessionStorage.getItem("wishList"));
  const handleAddWishListClick = (e) => {
    if (wishListSessionValue) {
      const filterWishlist = wishListSessionValue.filter(
        (value) => value._id === e._id
      );
      if (filterWishlist && filterWishlist.length > 0) {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="blue">
              <InfoCircle color="blue" />
            </ThemeIcon>
          ),
          message: "Product Already Exists in Wishlist",
        });
      } else {
        const newWishList = [...wishListSessionValue, e];
        sessionStorage.setItem("wishList", JSON.stringify(newWishList));
        dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
        //       <CircleCheck color="green" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Added to Wishlist",
        // });
      }
    } else {
      sessionStorage.setItem("wishList", JSON.stringify([e]));
      dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
      // showNotification({
      //   icon: (
      //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
      //       <CircleCheck color="green" />
      //     </ThemeIcon>
      //   ),
      //   message: "Product Added to Wishlist",
      // });
    }
  };

  useEffect(() => {
    dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
  }, []);

  useEffect(() => {
    if (wishListSessionValue && wishListSessionValue.length > 0) {
      setWishListProductsIds(wishListSessionValue.map((value) => value._id));
    }
  }, [wishListData]);
  return (
    <div>
      {productList.length > 0 && productList.length >= 5 ? (
        <div className="offerslider-div">
          <Container size={"82rem"} className="offerslider-div-container">
            <Center>
              <h1 className="offerslider-div-container-heading">
                {header}
                &nbsp;
                <span className="second-header-color">{header2}</span>
              </h1>
            </Center>
            <Carousel
              align="start"
              controlsOffset="md"
              loop
              slideSize={calculatedSlideSize}
              slideGap="xs"
              controlSize={36}
              className="offerslider-div-container-slider"
              plugins={[autoplay.current]}
              onMouseEnter={autoplay.current.stop}
              onMouseLeave={autoplay.current.reset}
              dragFree
            >
              {Array.isArray(shuffledProducts)
                ? shuffledProducts.map((product, index) => {
                    return (
                      <Carousel.Slide
                        key={index}
                        className="offerslider-div-container-slider-individual"
                      >
                        <div className="offerslider-div-container-slider-image">
                          <div
                            className="offerslider-div-container-slider-image-router"
                            onClick={() => {
                              hanldeNavigatePage(product);
                            }}
                            onMouseOver={() => {
                              setCategoryId(product.product_category);
                              setSubCategoryId(product.product_subcategory);
                            }}
                          >
                            <img
                              src={`${config.baseUrlApi}/assets/productImages/${product.product_image}`}
                              alt=""
                            />
                            <img
                              className="second-image"
                              src={`${config.baseUrlApi}/assets/productImages/${product.product_gallery_image[1]}`}
                              alt=""
                            />
                          </div>
                          <div className="offerslider-div-container-slider-individual-wishlist-cart">
                            <div
                              onClick={() => {
                                handleAddWishListClick(product);
                              }}
                              id="offerslider-div-container-slider-individual-wishlist-cart-wishlist"
                            >
                              <Heart strokeWidth={1} />
                            </div>
                            <div
                              onClick={() => {
                                handleAddCartClick(product);
                              }}
                              id="offerslider-div-container-slider-individual-wishlist-cart-cart"
                            >
                              <ShoppingCart strokeWidth={1} />
                            </div>
                          </div>
                          <div className="offerslider-div-container-slider-individual-discount">
                            <p>34%</p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            hanldeNavigatePage(product);
                          }}
                          className="offerslider-div-container-slider-content"
                        >
                          <div className="offerslider-div-container-slider-content-heading">
                            <p style={{ padding: "1rem 0" }}>{product.name}</p>
                          </div>
                          <div className="offerslider-div-container-slider-content-seller">
                            <p>
                              Mogo
                              {/* {ReactHtmlParser(product.short_description)} */}
                            </p>
                          </div>
                          <div className="offerslider-div-container-slider-content-seller-category">
                            <p>{product?.CategoryDetails[0]?.name}</p>
                          </div>
                          <div className="homeproductlist-div-container-content-product-content-rating-like">
                            <div className="homeproductlist-div-container-content-product-content-rating-like-rating">
                              <Rating value={5} readOnly />
                            </div>
                            <div className="homeproductlist-div-container-content-product-content-rating-like-like">
                              <div className="homeproductlist-div-container-content-product-content-rating-like-like-image">
                                <Heart strokeWidth={1} />
                              </div>
                              {/* {offerSlider.like} */}
                            </div>
                          </div>
                          <div className="offerslider-div-container-slider-content-price">
                            <p className="offerslider-div-container-slider-content-price-old">
                              ₹
                              {product.actual_price
                                ? product.actual_price
                                : 299}
                            </p>
                            <p className="offerslider-div-container-slider-content-price-new">
                              ₹{product.sale_price ? product.sale_price : 199}
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                    );
                  })
                : ""}
            </Carousel>
          </Container>
        </div>
      ) : productList.length <= 5 ? (
        <div className="offerslider-div">
          <Container size={"82rem"} className="offerslider-div-container">
            <Center>
              <h1 className="offerslider-div-container-heading">
                {header}
                &nbsp;
                <span className="second-header-color">{header2}</span>
              </h1>
            </Center>
            <div className="offerslider-div-container-slider1">
              {Array.isArray(shuffledProducts)
                ? shuffledProducts.map((product, index) => {
                    return (
                      <div
                        key={index}
                        className="offerslider-div-container-slider-individual1"
                      >
                        <div className="offerslider-div-container-slider-image">
                          <div
                            className="offerslider-div-container-slider-image-router"
                            onClick={() => {
                              hanldeNavigatePage(product);
                            }}
                            onMouseOver={() => {
                              setCategoryId(product.product_category);
                              setSubCategoryId(product.product_subcategory);
                            }}
                          >
                            <img
                              src={`${config.baseUrlApi}/assets/productImages/${product.product_image}`}
                              alt=""
                            />
                            <img
                              className="second-image"
                              src={`${config.baseUrlApi}/assets/productImages/${product.product_gallery_image[1]}`}
                              alt=""
                            />
                          </div>
                          {/* <img src={offerSlider.secondImage} alt="" /> */}
                          <div className="offerslider-div-container-slider-individual1-wishlist-cart">
                            <div
                              onClick={() => handleAddWishListClick(product)}
                              id="offerslider-div-container-slider-individual1-wishlist-cart-wishlist"
                            >
                              <Heart strokeWidth={1} />
                            </div>
                            <div
                              onClick={() => {
                                handleAddCartClick(product);
                              }}
                              id="offerslider-div-container-slider-individual1-wishlist-cart-cart"
                            >
                              <ShoppingCart strokeWidth={1} />
                            </div>
                          </div>
                          <div className="offerslider-div-container-slider-individual1-discount">
                            <p>34%</p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            hanldeNavigatePage(product);
                          }}
                          className="offerslider-div-container-slider-content"
                        >
                          <div className="offerslider-div-container-slider-content-heading">
                            <p style={{ padding: "1rem 0" }}>{product.name}</p>
                          </div>
                          <div className="offerslider-div-container-slider-content-seller">
                            <p>
                              Mogo
                              {/* {ReactHtmlParser(product.short_description)} */}
                            </p>
                          </div>
                          <div className="offerslider-div-container-slider-content-seller-category">
                            <p>{product?.CategoryDetails[0]?.name}</p>
                          </div>
                          <div className="homeproductlist-div-container-content-product-content-rating-like">
                            <div className="homeproductlist-div-container-content-product-content-rating-like-rating">
                              <Rating value={5} readOnly />
                            </div>
                            <div className="homeproductlist-div-container-content-product-content-rating-like-like">
                              <div className="homeproductlist-div-container-content-product-content-rating-like-like-image">
                                <Heart strokeWidth={1} />
                              </div>
                              {/* {offerSlider.like} */}
                            </div>
                          </div>
                          <div className="offerslider-div-container-slider-content-price">
                            <p className="offerslider-div-container-slider-content-price-old">
                              ₹
                              {product.actual_price
                                ? product.actual_price
                                : 299}
                            </p>
                            <p className="offerslider-div-container-slider-content-price-new">
                              ₹{product.sale_price ? product.sale_price : 199}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </Container>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OfferSlider;
