import React from "react";
import { Container } from "@mantine/core";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import "./ReturnPolicy.css";
const ReturnPolicy = () => {
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "Return Policy", href: "/return_policy" },
  ];

  return (
    <div>
      <div className="terms-and-conditions-div">
        <Container className="terms-and-conditions-div-container" size={"82rem"}>
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="terms-and-conditions-div-container-heading">
            <h1>Order Return Policy</h1>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>Returns Policy</h1>
            <p>Returns is a scheme provided by respective Mogos directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective Mogos to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below</p>
            <p>The return policy is divided into three parts; Do read all sections carefully to understand the conditions and cases under which returns will be accepted.</p>
            <p>Part 1 – Category, Return Window and Actions possible</p>
            <p>Part 2 - Returns Pick-Up and Processing In case of returns where you would like item(s) to be picked up from a different address, the address can only be changed if pick-up service is available at the new address During pick-up, your product will be checked for the following conditions:</p>
            <p>The field executive will refuse to accept the return if any of the above conditions are not met.</p>
            <p>For any products for which a refund is to be given, the refund will be processed once the returned product has been received by the Mogo.</p>
            <p>Part 3 - General Rules for a successful Return</p>
            <ul>
              <li>In certain cases where the Mogo is unable to process a replacement for any reason whatsoever, a refund will be given.</li>
              <li>In cases where a product accessory is found missing/damaged/defective, the Mogo may either process a replacement of the particular accessory or issue an eGV for an amount equivalent to the price of the accessory, at the Mogo’s discretion.</li>
              <li>During open box deliveries, while accepting your order, if you received a different or a damaged product, you will be given a refund (on the spot refunds for cash-on-delivery orders). Once you have accepted an open box delivery, no return request will be processed, except for manufacturing defects. In such cases, these category-specific replacement/return general conditions will be applicable. Click here to know more about Open Box Delivery</li>
              <li>For products where installation is provided by TheMogo's service partners, do not open the product packaging by yourself. TheMogo authorised personnel shall help in unboxing and installation of the product.</li>
              <li>For Furniture, any product-related issues will be checked by authorised service personnel (free of cost) and attempted to be resolved by replacing the faulty/ defective part of the product. Full replacement will be provided only in cases where the service personnel opines that replacing the faulty/defective part will not resolve the issue.'</li>
            </ul>
            <p>TheMogo holds the right to restrict the number of returns created per order unit, post the evaluation of the product/order defect is undertaken by TheMogo’s authorized representative.</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ReturnPolicy;
