//import react packages
import React, { useMemo, useState } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import {
  Card,
  Menu,
  Text,
  Group,
  Input,
  Pagination,
  Select,
} from "@mantine/core";

//import mantine models
import { modals } from "@mantine/modals";

//import react router dom packages
import { Link } from "react-router-dom";

//import Orders css
import "./Orders.css";

//import icons
import anglebottom from "../../../../assets/admin/table/anglebottom.webp";
import info from "../../../../assets/admin/table/dropdown/info.png";
import trash from "../../../../assets/admin/table/dropdown/trash.png";
import check from "../../../../assets/admin/table/dropdown/check.png";
import block from "../../../../assets/admin/table/dropdown/block.png";

//import icons
import arrowdown from "../../../../assets/preheader/arrow-down.webp";
import ReactDatePicker from "react-datepicker";
import { useQuery } from "react-query";
import { allOrders } from "../../../../config/quries/Orders/OrdersQuery";
import moment from "moment";

const Orders = () => {
  // Orders Data
  const [orderData, setOrderData] = useState([]);

  // Daterange Filter
  const dateRangeFilter = (rows, id, filterValue) => {
    const [startDate, endDate] = filterValue || [];
    if (!startDate || !endDate) {
      return rows;
    }

    return rows.filter((row) => {
      const date = new Date(row.values[id]);
      return date >= startDate && date <= endDate;
    });
  };

  // Modal
  const openDeleteModal = () =>
    modals.openConfirmModal({
      zIndex: "12345678",
      centered: true,
      title: "Delete your profile",
      children: (
        <Text size="sm">
          Are you sure you want to delete your profile? This action is
          destructive and you will have to contact support to restore your data.
        </Text>
      ),
      labels: { confirm: "Delete account", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
    });

  // All Orders
  useQuery("allOrders", allOrders, {
    onSuccess: (res) => {
      setOrderData(res?.data?.data?.result);
    },
  });

  // Props Column
  const COLUMN = [
    {
      Header: "S.No",
      accessor: "_id",
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: "Order #Id",
      Cell: ({ row }) => {
        return "#" + row.original._id;
      },
    },
    {
      Header: "Amount",
      Cell: ({ row }) => {
        const orderQuantity = row.original.cart_products.map((value) =>
          Number(value.quantity)
        );
        const orderProductPrices = row.original.cart_products.map((value) =>
          Number(value.sale_price)
        );
        let returnValue = [];
        if (orderQuantity.length == orderProductPrices.length) {
          for (let i = 0; i < orderProductPrices.length; i++) {
            returnValue.push(Number(orderProductPrices[i] * orderQuantity[i]));
          }
        }
        let totalAmount = 0;
        for (let i = 0; i < returnValue.length; i++) {
          totalAmount += returnValue[i];
        }
        return totalAmount;
      },
    },
    {
      Header: "Status",
      accessor: "order_status",
    },
    {
      Header: "Payment Method",
      accessor: "payment",
    },
    {
      Header: "Updated",
      accessor: "Updated",
      Cell: ({ row }) => {
        const date = row.original.modified;
        const formattedDate = moment(new Date(date)).format("DD-MM-YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Date",
      accessor: "created",
      Cell: ({ row }) => {
        const date = row.original.created;
        const formattedDate = moment(new Date(date)).format("DD-MM-YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Options",
      Cell: ({ row }) => (
        <div>
          <Menu classNames="table-selectbox">
            <Menu.Target>
              <button className="table-select-dropdown">
                <img src={anglebottom} alt="anglebottom" width={15} />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              <Text
                component={Link}
                to={`/individualorder/${row.original._id}`}
              >
                <Menu.Item>
                  <img src={info} alt="info icon" width={15} />
                  &nbsp;&nbsp;View Details
                </Menu.Item>
              </Text>
              <Menu.Item>
                <img src={check} alt="check icon" width={15} />
                &nbsp;&nbsp;Payment Received
              </Menu.Item>
              <Menu.Item>
                <img src={block} alt="block icon" width={15} />
                &nbsp;&nbsp;Cancel Order
              </Menu.Item>
              <Menu.Item onClick={openDeleteModal}>
                <img src={trash} alt="trash icon" width={15} />
                &nbsp;&nbsp; Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      ),
    },
  ];

  // React Table
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => orderData.slice().reverse(), [orderData]),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Status Filter
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("All");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const handleDateChange = (update) => {
    if (Array.isArray(update) && update.length === 2) {
      const [newStartDate, newEndDate] = update;

      if (newStartDate && newEndDate) {
        const formattedStartDate = newStartDate.toLocaleDateString("en-US");
        const formattedEndDate = newEndDate.toLocaleDateString("en-US");
        const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setFormattedDateRange(formattedDateRange);
        setGlobalFilter(formattedDateRange);
      } else {
        setFormattedDateRange("");
      }
    } else {
      setSelectedDate(update);
    }
  };

  return (
    <div>
      <div className="orders-div">
        <Card className="orders-div">
          <div className="orders-div-heading pb-15">
            <h2>Orders</h2>
          </div>
          <div className="orders-div-table">
            <div>
              <div className="orders-filter-container">
                <div className="orders-filter-container-div1">
                  <Select
                    label="Show"
                    onChange={(e) => setPageSize(Number(e))}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"10"}
                    data={[
                      { value: "10", label: "10", key: "10" },
                      { value: "20", label: "20", key: "20" },
                      { value: "30", label: "30", key: "30" },
                      { value: "40", label: "40", key: "40" },
                      { value: pageCount * 10, label: "All", key: "All" },
                    ]}
                  />
                </div>
                <div className="orders-filter-container-div2">
                  <Select
                    label="Status"
                    onChange={(e) => setSelectedStatus(e)}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"All"}
                    data={[
                      { value: "All", label: "All" },
                      { value: "Pending", label: "Pending" },
                      { value: "Completed", label: "Completed" },
                      { value: "Failed", label: "Failed" },
                    ]}
                  />
                </div>
                <div className="orders-filter-container-div3">
                  <Select
                    label="Payment Status"
                    onChange={(e) => setSelectedPaymentStatus(e)}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"All"}
                    data={[
                      { value: "All", label: "All" },
                      { value: "Pending", label: "Pending" },
                      { value: "Completed", label: "Completed" },
                      { value: "Failed", label: "Failed" },
                    ]}
                  />
                </div>
                <div className="orders-filter-container-div4">
                  <Input.Wrapper label="Search" maw={320} mx="auto">
                    <Input
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Filter"
                    />
                  </Input.Wrapper>
                </div>
                <div className="orders-filter-container-div5">
                  <Input.Wrapper label="Date" maw={320} mx="auto">
                    <ReactDatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                        handleDateChange(update);
                      }}
                      isClearable={true}
                      maxDate={currentDate}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="table-style">
                <table {...getTableProps()} className="custum-table-style">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((header) => (
                          <th {...header.getHeaderProps()}>
                            {header.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page
                        .filter(
                          (row) =>
                            selectedStatus === "All" ||
                            row.values.Status === selectedStatus
                        )
                        .filter(
                          (row) =>
                            selectedPaymentStatus === "All" ||
                            row.values.Payment_Status === selectedPaymentStatus
                        )
                        .map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          Nothing Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="react-table-pagination">
                <Pagination.Root
                  total={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  value={pageIndex + 1}
                  onChange={(e) => gotoPage(e - 1)}
                >
                  <Group spacing={5} position="center">
                    <Pagination.First
                      disabled={!canPreviousPage}
                      onClick={() => gotoPage(0)}
                    />
                    <Pagination.Previous
                      disabled={!canPreviousPage}
                      onClick={() => {
                        previousPage();
                        gotoPage(pageIndex - 1);
                      }}
                    />
                    <Pagination.Items value={2} />
                    <Pagination.Next
                      disabled={!canNextPage}
                      onClick={() => {
                        nextPage();
                        gotoPage(pageIndex + 1);
                      }}
                    />
                    <Pagination.Last
                      disabled={!canNextPage}
                      onClick={() => gotoPage(pageCount - 1)}
                    />
                  </Group>
                </Pagination.Root>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Orders;
