//import react
import React, { useEffect, useMemo, useState } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import {
  Card,
  Menu,
  Text,
  Group,
  Input,
  Pagination,
  Select,
  Modal,
  Tabs,
  PasswordInput,
  Button,
  ThemeIcon,
  Flex,
} from "@mantine/core";

//import mantine models
import { modals } from "@mantine/modals";

//import react router dom packages
import { Link } from "react-router-dom";

//import icons
import anglebottom from "../../../assets/admin/table/anglebottom.webp";
import trash from "../../../assets/admin/table/dropdown/trash.png";
import edit from "../../../assets/admin/table/dropdown/edit.png";
import block from "../../../assets/admin/table/dropdown/block.png";
import plus from "../../../assets/admin/table/dropdown/plus.png";
import arrowdown from "../../../assets/preheader/arrow-down.webp";

import "./Users.css";
import { handleAddAdminControl } from "../../../controller/loginAuth/adminAuth/AdminControl";
import { useQuery, useQueryClient } from "react-query";
import { listAllAdmin } from "../../../config/quries/AdminLogin/Quries";
import { getAllUsers } from "../../../config/quries/users/usersQuery";
import { getAllVendor } from "../../../config/quries/vendorLogin/vendorQuries";
import axios from "axios";
import config from "../../../config/server/Servers";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";
import moment from "moment";

const Users = () => {
  const queryClient = useQueryClient();
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [addAdmin, setAddAdmin] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    conformPassword: "",
  });

  const [validateData, setValidateData] = useState({
    first_name: 0,
    last_name: 0,
    email: 0,
    password: 0,
    conformPassword: 0,
  });

  const [userData, setUserData] = useState({
    admin: "",
    user: [],
    vendor: "",
  });

  // Status Filter
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [hideAddBtn, setHideAddBtn] = useState(0);

  const openDeleteModal = (id, value) =>
    modals.openConfirmModal({
      centered: true,
      title: "Delete your profile",
      zIndex: 123456789,
      children: (
        <Text size="sm">
          Delete option only Hide and Change the Status . but Not Remove the
          User From Database
        </Text>
      ),
      labels: { confirm: "Delete account", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        axios
          .put(
            `${config.baseUrlApi}/admin/updateuserbyadmin/${id}`,
            {
              status: "Deleted",
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "MogoAdminAccessToken102"
                )}`,
              },
            }
          )
          .then((res) => {
            queryClient.invalidateQueries("AllAdminList");
            queryClient.invalidateQueries("AllUsers");
            queryClient.invalidateQueries("AllVendors");
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <CircleCheck color="green" />
                </ThemeIcon>
              ),
              message: "User Deleted Successfully",
            });
          })
          .catch((err) => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                  <X color="red" />
                </ThemeIcon>
              ),
              message: "Unable to Delete  User",
            });
          });
      },
    });

  // Hanlde ban User
  const handleBanUser = (id, value) => {
    axios
      .put(
        `${config.baseUrlApi}/admin/updateuserbyadmin/${id}`,
        {
          status: value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem(
              "MogoAdminAccessToken102"
            )}`,
          },
        }
      )
      .then((res) => {
        queryClient.invalidateQueries("AllAdminList");
        queryClient.invalidateQueries("AllUsers");
        queryClient.invalidateQueries("AllVendors");
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "User Banned Successfully",
        });
      })
      .catch((err) => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Unable to Ban User",
        });
      });
  };

  // Props Column
  const COLUMN = [
    {
      Header: "S.No",
      accessor: "_id",
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: "User",
      accessor: "first_name",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.first_name} {row.original.last_name}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Last Seen",
      accessor: "last_seen",
      Cell: ({ row }) => {
        const date = row.original.modified;
        const formattedDate = moment(new Date(date)).format("DD-MM-YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: ({ row }) => {
        const date = row.original.created;
        const formattedDate = moment(new Date(date)).format("DD-MM-YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Options",
      Cell: ({ row }) => (
        <div>
          <Menu classNames="table-selectbox">
            <Menu.Target>
              <button className="table-select-dropdown">
                <img src={anglebottom} alt="anglebottom" width={15} />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              {/* <Text>
                <Menu.Item>
                  <img src={edit} alt="edit icon" width={15} />
                  &nbsp;&nbsp;Edit
                </Menu.Item>
              </Text> */}
              <Text>
                {row.original.status === "Active" ? (
                  <Menu.Item
                    onClick={() => handleBanUser(row.original._id, "Banned")}
                  >
                    <Flex align={"center"}>
                      <img src={block} alt="block icon" width={15} />
                      &nbsp;&nbsp;Ban User
                    </Flex>
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    onClick={() => handleBanUser(row.original._id, "Active")}
                  >
                    <Flex align={"center"}>
                      <img src={block} alt="block icon" width={15} />
                      &nbsp;&nbsp;Activate Banned User
                    </Flex>
                  </Menu.Item>
                )}
              </Text>
              {row.original.status !== "Deleted" ? (
                <Menu.Item
                  onClick={() => openDeleteModal(row.original._id, "Deleted")}
                >
                  <Flex align={"center"}>
                    <img src={trash} alt="trash icon" width={15} />
                    &nbsp;&nbsp;Delete User
                  </Flex>
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => openDeleteModal(row.original._id, "Active")}
                >
                  <Flex align={"center"}>
                    <img src={trash} alt="trash icon" width={15} />
                    &nbsp;&nbsp;Activate Deleted User
                  </Flex>
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      ),
    },
  ];

  const [tableUserData, setTableUserData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  // Query
  useQuery("AllAdminList", listAllAdmin, {
    onSuccess: (res) => {
      setTableUserData([...res?.data?.data?.result]);
      setUserData({ ...userData, admin: res?.data?.data?.result });
    },
  });

  //   users
  useQuery("AllUsers", getAllUsers, {
    onSuccess: (res) => {
      setCustomersData([...res?.data?.data?.result]);
    },
  });

  //   Vendor
  useQuery("AllVendors", getAllVendor, {
    onSuccess: (res) => {
      setUserData({ ...userData, vendor: res?.data?.data?.result });
    },
  });
  useEffect(() => {
    if (hideAddBtn === 0) {
      setTableUserData([...userData.admin]);
    } else if (hideAddBtn === 1) {
      setTableUserData([...userData.vendor]);
    } else if (hideAddBtn === 2) {
      setTableUserData([...customersData]);
    }
  }, [hideAddBtn, userData.admin, userData.vendor, userData.user]);
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => tableUserData, [tableUserData]),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Hanlde Add Admin
  const hanldeAddAdmin = () => {
    handleAddAdminControl(
      addAdmin,
      setAddAdmin,
      validateData,
      setValidateData,
      queryClient,
      setUsersModalOpen
    );
  };

  //   UseEffect
  useEffect(() => {
    if (addAdmin.first_name) {
      setValidateData({ ...validateData, first_name: 0 });
    }
    if (addAdmin.last_name) {
      setValidateData({ ...validateData, last_name: 0 });
    }
    if (addAdmin.email) {
      setValidateData({ ...validateData, email: 0 });
    }
    if (addAdmin.password) {
      setValidateData({ ...validateData, password: 0 });
    }
    if (addAdmin.conformPassword) {
      setValidateData({ ...validateData, conformPassword: 0 });
    }
  }, [addAdmin]);
  return (
    <div>
      <div className="users-div">
        <Card className="min-height-90vh users-div-container">
          <div className="users-div-container-heading">
            <h4>Users</h4>
            <Button
              disabled={hideAddBtn !== 0}
              onClick={() => {
                setUsersModalOpen(true);
              }}
            >
              <img src={plus} width="12" alt="plus icon" />
              Add Admin
            </Button>
          </div>
          <div className="users-div-container-content">
            <Tabs variant="pills" defaultValue="admin">
              <Tabs.List>
                <Tabs.Tab
                  onClick={() => {
                    setHideAddBtn(0);
                    setTableUserData([...userData.admin]);
                  }}
                  value="admin"
                >
                  Admin
                </Tabs.Tab>
                <Tabs.Tab onClick={() => setHideAddBtn(1)} value="vendor">
                  Vendor
                </Tabs.Tab>
                <Tabs.Tab
                  onClick={() => {
                    setHideAddBtn(2);
                    setTableUserData([...userData.user]);
                  }}
                  value="users"
                >
                  Users
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel
                value="admin"
                className="users-div-container-content-tabs-panel"
                pt="xs"
              >
                <div className="payoutrequests-filter-container">
                  <div className="payoutrequests-filter-container-div1">
                    <Select
                      label="Show"
                      onChange={(e) => setPageSize(Number(e))}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"10"}
                      data={[
                        { value: "10", label: "10", key: "10" },
                        { value: "20", label: "20", key: "20" },
                        { value: "30", label: "30", key: "30" },
                        { value: "40", label: "40", key: "40" },
                        { value: pageCount * 10, label: "All", key: "All" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div2">
                    <Select
                      label="Status"
                      onChange={(e) => setSelectedStatus(e)}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"All"}
                      data={[
                        { value: "All", label: "All" },
                        { value: "Active", label: "Active" },
                        { value: "Banned", label: "Banned" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div4">
                    <Input.Wrapper label="Search" maw={320} mx="auto">
                      <Input
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search Filter"
                      />
                    </Input.Wrapper>
                  </div>
                </div>
                <div className="table-style">
                  <table {...getTableProps()} className="custum-table-style">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((header) => (
                            <th {...header.getHeaderProps()}>
                              {header.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0 ? (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            Nothing Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="react-table-pagination">
                  <Pagination.Root
                    total={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    value={pageIndex + 1}
                    onChange={(e) => gotoPage(e - 1)}
                  >
                    <Group spacing={5} position="center">
                      <Pagination.First
                        disabled={!canPreviousPage}
                        onClick={() => gotoPage(0)}
                      />
                      <Pagination.Previous
                        disabled={!canPreviousPage}
                        onClick={() => {
                          previousPage();
                          gotoPage(pageIndex - 1);
                        }}
                      />
                      <Pagination.Items value={2} />
                      <Pagination.Next
                        disabled={!canNextPage}
                        onClick={() => {
                          nextPage();
                          gotoPage(pageIndex + 1);
                        }}
                      />
                      <Pagination.Last
                        disabled={!canNextPage}
                        onClick={() => gotoPage(pageCount - 1)}
                      />
                    </Group>
                  </Pagination.Root>
                </div>
              </Tabs.Panel>

              <Tabs.Panel
                value="vendor"
                className="users-div-container-content-tabs-panel"
                pt="xs"
              >
                <div className="payoutrequests-filter-container">
                  <div className="payoutrequests-filter-container-div1">
                    <Select
                      label="Show"
                      onChange={(e) => setPageSize(Number(e))}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"10"}
                      data={[
                        { value: "10", label: "10", key: "10" },
                        { value: "20", label: "20", key: "20" },
                        { value: "30", label: "30", key: "30" },
                        { value: "40", label: "40", key: "40" },
                        { value: pageCount * 10, label: "All", key: "All" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div2">
                    <Select
                      label="Status"
                      onChange={(e) => setSelectedStatus(e)}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"All"}
                      data={[
                        { value: "All", label: "All" },
                        { value: "Active", label: "Active" },
                        { value: "Banned", label: "Banned" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div4">
                    <Input.Wrapper label="Search" maw={320} mx="auto">
                      <Input
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search Filter"
                      />
                    </Input.Wrapper>
                  </div>
                </div>
                <div className="table-style">
                  <table {...getTableProps()} className="custum-table-style">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((header) => (
                            <th {...header.getHeaderProps()}>
                              {header.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0 ? (
                        page
                          .filter(
                            (row) =>
                              selectedStatus === "All" ||
                              row.values.Status === selectedStatus
                          )
                          .map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                ))}
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            Nothing Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="react-table-pagination">
                  <Pagination.Root
                    total={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    value={pageIndex + 1}
                    onChange={(e) => gotoPage(e - 1)}
                  >
                    <Group spacing={5} position="center">
                      <Pagination.First
                        disabled={!canPreviousPage}
                        onClick={() => gotoPage(0)}
                      />
                      <Pagination.Previous
                        disabled={!canPreviousPage}
                        onClick={() => {
                          previousPage();
                          gotoPage(pageIndex - 1);
                        }}
                      />
                      <Pagination.Items value={2} />
                      <Pagination.Next
                        disabled={!canNextPage}
                        onClick={() => {
                          nextPage();
                          gotoPage(pageIndex + 1);
                        }}
                      />
                      <Pagination.Last
                        disabled={!canNextPage}
                        onClick={() => gotoPage(pageCount - 1)}
                      />
                    </Group>
                  </Pagination.Root>
                </div>
              </Tabs.Panel>

              <Tabs.Panel
                value="users"
                className="users-div-container-content-tabs-panel"
                pt="xs"
              >
                <div className="payoutrequests-filter-container">
                  <div className="payoutrequests-filter-container-div1">
                    <Select
                      label="Show"
                      onChange={(e) => setPageSize(Number(e))}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"10"}
                      data={[
                        { value: "10", label: "10", key: "10" },
                        { value: "20", label: "20", key: "20" },
                        { value: "30", label: "30", key: "30" },
                        { value: "40", label: "40", key: "40" },
                        { value: pageCount * 10, label: "All", key: "All" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div2">
                    <Select
                      label="Status"
                      onChange={(e) => setSelectedStatus(e)}
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      defaultValue={"All"}
                      data={[
                        { value: "All", label: "All" },
                        { value: "Active", label: "Active" },
                        { value: "Banned", label: "Banned" },
                      ]}
                    />
                  </div>
                  <div className="payoutrequests-filter-container-div4">
                    <Input.Wrapper label="Search" maw={320} mx="auto">
                      <Input
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search Filter"
                      />
                    </Input.Wrapper>
                  </div>
                </div>
                <div className="table-style">
                  <table {...getTableProps()} className="custum-table-style">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((header) => (
                            <th {...header.getHeaderProps()}>
                              {header.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0 ? (
                        page
                          .filter(
                            (row) =>
                              selectedStatus === "All" ||
                              row.values.Status === selectedStatus
                          )
                          .map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                ))}
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={headerGroups[0].headers.length}>
                            Nothing Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="react-table-pagination">
                  <Pagination.Root
                    total={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    value={pageIndex + 1}
                    onChange={(e) => gotoPage(e - 1)}
                  >
                    <Group spacing={5} position="center">
                      <Pagination.First
                        disabled={!canPreviousPage}
                        onClick={() => gotoPage(0)}
                      />
                      <Pagination.Previous
                        disabled={!canPreviousPage}
                        onClick={() => {
                          previousPage();
                          gotoPage(pageIndex - 1);
                        }}
                      />
                      <Pagination.Items value={2} />
                      <Pagination.Next
                        disabled={!canNextPage}
                        onClick={() => {
                          nextPage();
                          gotoPage(pageIndex + 1);
                        }}
                      />
                      <Pagination.Last
                        disabled={!canNextPage}
                        onClick={() => gotoPage(pageCount - 1)}
                      />
                    </Group>
                  </Pagination.Root>
                </div>
              </Tabs.Panel>
            </Tabs>
          </div>
        </Card>
      </div>

      <Modal
        zIndex={12121}
        size="lg"
        opened={usersModalOpen}
        onClose={() => setUsersModalOpen(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="sellerbalance-edit-modal"
      >
        <div className="sellerbalance-edit-modal-header">
          <h4>Add Admin</h4>
        </div>
        <div className="sellerbalance-edit-modal-body">
          <div className="sellerbalance-edit-modal-body-content">
            <div className="sellerbalance-edit-modal-body-content-input">
              <Input.Wrapper
                error={`${
                  validateData.first_name === 1 ? "Please Enter First Name" : ""
                }`}
                label="First Name"
              >
                <Input
                  placeholder="First Name"
                  onChange={(e) =>
                    setAddAdmin({ ...addAdmin, first_name: e.target.value })
                  }
                  value={addAdmin.first_name}
                />
              </Input.Wrapper>
            </div>
            <div className="sellerbalance-edit-modal-body-content-input">
              <Input.Wrapper
                error={`${
                  validateData.last_name === 1 ? "Please Enter Last Name" : ""
                }`}
                label="Last Name"
              >
                <Input
                  placeholder="Last Name"
                  onChange={(e) =>
                    setAddAdmin({ ...addAdmin, last_name: e.target.value })
                  }
                  value={addAdmin.last_name}
                />
              </Input.Wrapper>
            </div>
            <div className="sellerbalance-edit-modal-body-content-input">
              <Input.Wrapper
                error={`${
                  validateData.email === 1
                    ? "Please Enter Email"
                    : validateData.email === 2
                    ? "Please Enter Valid Email "
                    : ""
                }`}
                label="Email Address"
              >
                <Input
                  placeholder="Email"
                  onChange={(e) =>
                    setAddAdmin({ ...addAdmin, email: e.target.value })
                  }
                  value={addAdmin.email}
                />
              </Input.Wrapper>
            </div>
            <div className="sellerbalance-edit-modal-body-content-input">
              <Input.Wrapper
                error={`${
                  validateData.password === 1 ? "Please Enter Password" : ""
                }`}
              >
                <PasswordInput
                  label="Password"
                  placeholder="Password"
                  onChange={(e) =>
                    setAddAdmin({ ...addAdmin, password: e.target.value })
                  }
                  value={addAdmin.password}
                />
              </Input.Wrapper>
            </div>
            <div className="sellerbalance-edit-modal-body-content-input">
              <Input.Wrapper
                error={`${
                  validateData.conformPassword === 1
                    ? "Please Enter Conform Password"
                    : validateData.conformPassword === 2
                    ? "Conform Password not Match with Password"
                    : ""
                }`}
              >
                <PasswordInput
                  label="Password"
                  placeholder="Conform Password"
                  onChange={(e) =>
                    setAddAdmin({
                      ...addAdmin,
                      conformPassword: e.target.value,
                    })
                  }
                  value={addAdmin.conformPassword}
                />
              </Input.Wrapper>
            </div>
            <div className="sellerbalance-edit-modal-body-content-button">
              <button onClick={hanldeAddAdmin}>Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
