import React, { useEffect, useState } from "react";
import { Card, Input, PasswordInput } from "@mantine/core";
import "./ChangePassword.css";
import { handleChangePasswordAPI } from "../../../../controller/loginAuth/vendorAuth/vendorLogin";
const ChangePassword = () => {
  const [password, setPassword] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [validatePassword, setValidatePassword] = useState({
    oldpassword: 0,
    newpassword: 0,
    confirmpassword: 0,
  });
  const handleChangePassowrd = () => {
    handleChangePasswordAPI(
      password,
      setPassword,
      validatePassword,
      setValidatePassword
    );
  };
  useEffect(() => {
    if (password.oldpassword) {
      setValidatePassword({ ...validatePassword, oldpassword: 0 });
    }
    if (password.newpassword) {
      setValidatePassword({ ...validatePassword, newpassword: 0 });
    }
    if (password.confirmpassword) {
      setValidatePassword({ ...validatePassword, confirmpassword: 0 });
    }
  }, [password]);
  return (
    <div>
      <div className="vendor-changepassword-div">
        <Card className="vendor-changepassword-div-card">
          <div className="vendor-changepassword-div-card-form">
            <div className="vendor-changepassword-div-card-form-individual">
              <Input.Wrapper
                error={`
              ${
                validatePassword.oldpassword === 1
                  ? "Please Enter Old Password"
                  : validatePassword.oldpassword === 2
                  ? "Incorrect Old Password"
                  : ""
              }
              `}
              >
                <PasswordInput
                  label="Old Password"
                  radius="xs"
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      oldpassword: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </div>
            <div className="vendor-changepassword-div-card-form-individual">
              <Input.Wrapper
                error={`
              ${
                validatePassword.newpassword === 1
                  ? "Please Enter New Password"
                  : validatePassword.newpassword === 2
                  ? "Please Enter Valid Password"
                  : ""
              }
              `}
              >
                <PasswordInput
                  label="New Password"
                  radius="xs"
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      newpassword: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </div>
            <div className="vendor-changepassword-div-card-form-individual">
              <Input.Wrapper
                error={`
              ${
                validatePassword.confirmpassword === 1
                  ? "Please Enter Conform Password"
                  : validatePassword.confirmpassword === 2
                  ? "Conform Password not Match with New Password"
                  : ""
              }
              `}
              >
                <PasswordInput
                  label="Confirm Password"
                  radius="xs"
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      confirmpassword: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </div>

            <div className="vendor-changepassword-div-card-form-individual-btn">
              <button onClick={handleChangePassowrd}>Save Changes</button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
