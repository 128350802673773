// import react packages
import React, { useEffect, useState } from "react";

// import ShippingSettings css

import "./AddShippingZone.css";

// import mantine packages
import {
  Button,
  Card,
  Input,
  Select,
  Modal,
  MultiSelect,
  Switch,
} from "@mantine/core";

// import icons
import plus from "../../../../assets/admin/table/dropdown/plus.png";
import arrowdown from "../../../../assets/preheader/arrow-down.webp";
import anglebottom from "../../../../assets/admin/table/anglebottom.webp";

// import { selectedListStates } from "../../../StateHandler/InitialState/Address/AddressState";

const AddShippingZone = () => {
  const [shippingMethodModalOpen, setShippingMethodModalOpen] = useState(false);

  // Collect shipping Method Details
  const [shippingMethodDetails, setShippingMethodDetails] = useState({
    methodTitle: "",
    taxStatus: "",
    cost: "",
  });

  // validate shipping Method
  const [
    validateDataShippingMethodDetails,
    setValidateDataShippingMethodDetails,
  ] = useState({
    methodTitle: 0,
    taxStatus: 0,
    cost: 0,
  });

  // to handle cost field
  const handleOnchange = (event) => {
    console.log(event.target.value);
    const { name, value } = event.target;
    console.log(name);
  };

  // array of ojects to display method title options
  const methodTitle = [
    {
      value: "Flat rate",
      name: "flat_rate",
    },
    {
      value: "Free Shipping",
      name: "free_shipping",
    },
    {
      value: "Local pickup",
      name: "local_pickup",
    },
    {
      value: "Weight",
      name: "weight",
    },
  ];

  // array of ojects to display tax status options
  const taxStatus = [
    {
      value: "Taxable",
      name: "taxable",
    },
    {
      value: "None",
      name: "none",
    },
  ];

  // to handle Selected vaules from select fields

  const handleSelect = (selectedValue) => {
    const selectedOption = methodTitle.find(
      (option) => option.value === selectedValue
    );

    const selectedTaxOption = taxStatus.find(
      (option) => option.value === selectedValue
    );

    selectedOption
      ? setShippingMethodDetails({
          ...shippingMethodDetails,
          [selectedOption.name]: selectedOption.value,
        })
      : setShippingMethodDetails({
          ...shippingMethodDetails,
          [selectedTaxOption.name]: selectedTaxOption.value,
        });
  };

  return (
    <div>
      <Card>
        <div className="add-shipping-zone-div">
          <div className="add-shipping-zone-div-input-content">
            <Input.Wrapper
              label="Zone Name"
              description="This the name of the zone for your refernce"
              error=""
            >
              <Input placeholder="Enter Zone Name" />
            </Input.Wrapper>
          </div>
          <div className="add-shipping-zone-div-input-content">
            <MultiSelect
              label="Zone Regions"
              description="There are regions inside this zone.Customers will be matched against these regions"
              placeholder="Select Region"
              data={["Tamil Nadu", "Andra Pradesh", "Bangalore", "Kerala"]}
            />
          </div>

          <div className="add-shipping-method-div">
            <div className="add-shipping-method-header">
              <h4>Shipping Methods</h4>

              <Modal
                zIndex={12121}
                size="lg"
                opened={shippingMethodModalOpen}
                onClose={() => setShippingMethodModalOpen(false)}
                title=""
                transitionProps={{
                  transition: "fade",
                  duration: 350,
                  timingFunction: "linear",
                }}
                className="sellerbalance-edit-modal"
              >
                <div className="add-shipping-method-main-div">
                  <div className="add-shipping-method-div">
                    <div className="add-shipping-method-div-input-content">
                      <Select
                        label="Method Title"
                        placeholder="Select a shipping method"
                        name="methodTitle"
                        rightSection={
                          <img
                            src={arrowdown}
                            width={10}
                            alt="arrow down icon"
                          />
                        }
                        data={methodTitle.map((option) => option.value)}
                        onChange={handleSelect}
                      />
                    </div>
                    <div className="add-shipping-method-div-input-content">
                      <Select
                        label="Tax Status"
                        placeholder="Select Tax"
                        name="taxStatus"
                        rightSection={
                          <img
                            src={arrowdown}
                            width={10}
                            alt="arrow down icon"
                          />
                        }
                        data={taxStatus.map((option) => option.value)}
                        onChange={handleSelect}
                      />
                    </div>
                    <div className="add-shipping-method-div-input-content">
                      <Input.Wrapper
                        label="Cost"
                        error={`${
                          validateDataShippingMethodDetails.cost === 1
                            ? "Please enter the cost"
                            : ""
                        }`}
                      >
                        <Input
                          placeholder="Enter cost in rupees"
                          name="cost"
                          onChange={handleOnchange}
                        />
                      </Input.Wrapper>
                    </div>

                    <div className="add-shipping-method-header1">
                      <h4></h4>
                      <Button className="add-shipping-zone-save-btn">
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <table className="shipping-zone-display-table">
                <thead>
                  <tr>
                    <th>Method Title</th>
                    <th>Tax Status</th>
                    <th>Enabled</th>
                    <th>Cost</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Flat Rate</td>
                    <td>Taxable</td>
                    <td>
                      <div className="switchDiv">
                        <Switch
                          className="enableSwitchBtn"
                          defaultChecked
                          label=""
                        />
                      </div>
                    </td>

                    <td>₹ 50</td>
                    <td>
                      <button className="table-select-dropdown">
                        <img src={anglebottom} alt="anglebottom" width={15} />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Flat Rate</td>
                    <td>Taxable</td>
                    <td>
                      <div className="switchDiv">
                        <Switch
                          className="enableSwitchBtn"
                          defaultChecked
                          label=""
                        />
                      </div>
                    </td>

                    <td>₹ 50</td>
                    <td>
                      <button className="table-select-dropdown">
                        <img src={anglebottom} alt="anglebottom" width={15} />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <Button
              className="add-shipping-zone-btn"
              onClick={() => {
                setShippingMethodModalOpen(true);
              }}
            >
              <img src={plus} width="12" alt="plus icon" />
              Add Shipping Method
            </Button>
          </div>
        </div>
        <div>
          <div className="add-shipping-method-header">
            <h4></h4>
            <Button className="add-shipping-zone-btn">
              Save Shipping Zone
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddShippingZone;
