import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Card, Input, Textarea, Select } from "@mantine/core";
import "./Profile.css";
import userprofile from "../../../../assets/admin/User/user_profile.png";
import cameraicon from "../../../../assets/admin/User/cameraicon.png";
import { vendorDetailsControl } from "../../../../controller/loginAuth/vendorAuth/vendorLogin";
import { useQueryClient, useQuery } from "react-query";
import { getVendorByid } from "../../../../config/quries/vendorLogin/vendorQuries";
import { useDispatch, useSelector } from "react-redux";
import { setVendorData } from "../../../../StateHandler/Slice/VendorData/VendorDataSlice";
import { Pencil } from "tabler-icons-react";
import config from "../../../../config/server/Servers";

import arrowdown from "../../../../assets/preheader/arrow-down.webp";
import {
  addressCountries,
  selectedListStates,
} from "../../../../StateHandler/InitialState/Address/AddressState";
const Profile = () => {
  const vendorId = sessionStorage.getItem("MogovendorAccessToken101");
  const vendorData = useSelector((state) => state?.vendorData?.value);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  // Getting vendor Data
  useQuery("vendorData", getVendorByid, {
    onSuccess: (res) => {
      dispatch(setVendorData(res?.data?.data));
    },
  });
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobilenumber: "",
    address: "",
    company_name: "",
    GST_IN: "",
    country: "",
    state: "",
    zip_code: "",
  });
  const [validateData, setValidateData] = useState({
    firstname: 0,
    lastname: 0,
    email: 0,
    mobilenumber: 0,
    address: 0,
    company_name: 0,
    GST_IN: 0,
    country: 0,
    state: 0,
    zip_code: 0,
  });
  const [editProfile, setEditProfile] = useState(false);
  const fileInputRef = useRef();
  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setProfile({ ...profile, profile_image: selectedFile });
  };
  const handleInputOpen = () => {
    fileInputRef.current.click();
  };

  const handleVendorDetails = () => {
    vendorDetailsControl(
      profile,
      setProfile,
      validateData,
      setValidateData,
      queryClient,
      setEditProfile
    );
  };

  useEffect(() => {
    if (profile.firstname) {
      setValidateData({ ...validateData, firstname: 0 });
    }
    if (profile.lastname) {
      setValidateData({ ...validateData, lastname: 0 });
    }
    if (profile.mobilenumber) {
      setValidateData({ ...validateData, mobilenumber: 0 });
    }
    if (profile.address) {
      setValidateData({ ...validateData, address: 0 });
    }
  }, [profile]);

  useEffect(() => {
    if (vendorData) {
      setProfile({
        ...profile,
        firstname: vendorData?.first_name,
        lastname: vendorData?.last_name,
        email: vendorData?.email,
        mobilenumber: vendorData?.mobile,
        address: vendorData?.address,
        profile_image: vendorData?.profile_image,
        company_name: vendorData?.company_name,
        GST_IN: vendorData?.GSTIN,
        country: vendorData?.country,
        state: vendorData?.state,
        zip_code: vendorData?.zip_code,
      });
    }
  }, [vendorData]);
  return (
    <div>
      <div className="vendorprofile-div">
        {vendorData?.first_name && editProfile === false ? (
          <Card>
            <div className="vendor_address_details-profile-image">
              <div className="user-vendorprofile-image-div">
                {vendorData.profile_image ? (
                  <img
                    src={`${config.baseUrlApi}/assets/vendorProfile/${vendorData.profile_image}`}
                    alt="user_profile"
                  />
                ) : (
                  <img src={userprofile} />
                )}
              </div>
            </div>
            <div className="vendor_address_details">
              <Flex>
                Name : {vendorData?.first_name + " " + vendorData?.last_name}{" "}
              </Flex>
              <Flex>Email Id : {vendorData?.email} </Flex>
              <Flex>Mobile Number: {vendorData?.mobile_number} </Flex>
              <Flex>Company Name : {vendorData?.company_name} </Flex>
              <Flex>GSTIN : {vendorData?.GSTIN} </Flex>
              <Flex>Address : {vendorData?.address} </Flex>
              <Flex>Country: {vendorData?.country} </Flex>
              <Flex>State: {vendorData?.state} </Flex>
              <Flex>Zip Code: {vendorData?.zip_code} </Flex>
            </div>
            <div className="vendor_address_details_edit_btn">
              <Button onClick={() => setEditProfile(true)}>
                <Flex align={"center"} gap={4}>
                  <Pencil size={"1rem"} />
                  Edit
                </Flex>
              </Button>
            </div>
          </Card>
        ) : (
          <Card className="vendorprofile-div-card">
            <div className="vendorprofile-div-card-userprofile">
              <div className="user-vendorprofile-image-div">
                {profile.profile_image ? (
                  profile.profile_image?.name ? (
                    <img
                      src={URL.createObjectURL(profile.profile_image)}
                      alt="user_profile"
                    />
                  ) : (
                    <img
                      src={`${config.baseUrlApi}/assets/vendorProfile/${vendorData.profile_image}`}
                      alt="user_profile"
                    />
                  )
                ) : (
                  <img src={userprofile} />
                )}
              </div>
              <div
                onClick={handleInputOpen}
                className="user-vendorprofile-image-div-upload"
              >
                <img src={cameraicon} />
              </div>
            </div>
            <input
              onChange={handleFileUpload}
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="vendorprofile-div-card-form">
              <div className="vendorprofile-div-card-form-group">
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.firstname === 1
                        ? "Please Enter First Name"
                        : ""
                    }`}
                    label="First Name"
                  >
                    <Input
                      value={profile.firstname}
                      placeholder="First Name"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          firstname: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.lastname === 1
                        ? "Please Enter Last Name"
                        : ""
                    }`}
                    label="Last Name"
                  >
                    <Input
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          lastname: e.target.value,
                        });
                      }}
                      value={profile.lastname}
                      placeholder="Last Name"
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="vendorprofile-div-card-form-group">
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.email === 1 ? "Please Enter Email" : ""
                    }`}
                    label="Email"
                  >
                    <Input
                      value={profile.email}
                      disabled
                      placeholder="Email"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          email: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.mobilenumber === 1
                        ? "Please Enter Mobile Number"
                        : validateData.mobilenumber === 2
                        ? "Please Enter Valid Mobile Number"
                        : ""
                    }`}
                    label="Mobile Number"
                  >
                    <Input
                      value={profile.mobilenumber}
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          mobilenumber: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="vendorprofile-div-card-form-group">
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.company_name === 1
                        ? "Please Enter Company Name"
                        : ""
                    }`}
                    label="Company Name"
                  >
                    <Input
                      value={profile.company_name}
                      placeholder="company  Name"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          company_name: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.GST_IN === 1
                        ? "Please Enter GSTIN"
                        : validateData.GST_IN === 2
                        ? "Please Enter Valid GSTIN"
                        : ""
                    }`}
                    label="GSTIN"
                  >
                    <Input
                      value={profile.GST_IN}
                      placeholder="GSTIN"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          GST_IN: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="vendorprofile-div-card-form-individual">
                <Input.Wrapper
                  error={`${
                    validateData.address === 1 ? "Please Enter Address" : ""
                  }`}
                >
                  <Textarea
                    value={profile.address}
                    error={validateData.address === 1 ? true : false}
                    label="Address"
                    radius="xs"
                    autosize
                    minRows={3}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        address: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="vendorprofile-div-card-form-group">
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.country === 1 ? "Please Select Country" : ""
                    }`}
                    label="Country"
                  >
                    <Select
                      size="md"
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      dropdownPosition="top"
                      placeholder="Select Country"
                      searchable
                      nothingFound="No options"
                      data={addressCountries?.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      value={profile.country}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          country: e,
                          state: "",
                        })
                      }
                    />
                  </Input.Wrapper>
                </div>
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.state === 1 ? "Please Select State" : ""
                    }`}
                    label="State"
                  >
                    <Select
                      size="md"
                      rightSection={
                        <img src={arrowdown} width={10} alt="arrow down icon" />
                      }
                      value={profile.state}
                      dropdownPosition="top"
                      placeholder="Select State"
                      searchable
                      nothingFound="No options"
                      data={selectedListStates[
                        profile.country ? profile.country : "India"
                      ]?.map((data) => ({
                        value: data,
                        label: data,
                      }))}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          state: e,
                        })
                      }
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="vendorprofile-div-card-form-group">
                <div className="vendorprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.zip_code === 1
                        ? "Please Enter Zip code"
                        : validateData.zip_code === 2
                        ? "Please Enter Valid Zip Code"
                        : ""
                    }`}
                    label="Zip Code"
                  >
                    <Input
                      value={profile.zip_code}
                      placeholder="Zip Code"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          zip_code: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="vendorprofile-div-card-form-individual-btn">
                <Flex gap={5}>
                  <Button onClick={() => setEditProfile(false)}>Cancel</Button>
                  <button onClick={handleVendorDetails}>Save Changes</button>
                </Flex>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Profile;
