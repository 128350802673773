//import react packages
import { Card, Flex, Modal, Rating, Text, Textarea } from "@mantine/core";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

//import ProductIndividual css
import "./ProductIndividual.css";

//import icons
import blockfilled from "../../../../assets/admin/table/dropdown/block-filled.png";
import editfilled from "../../../../assets/admin/table/dropdown/edit-filled.png";

import { useQuery, useQueryClient } from "react-query";
import { getProductByID } from "../../../../config/quries/Products/ProductQuries";

// Server
import config from "../../../../config/server/Servers";

const ProductIndividual = () => {
  const location = useLocation();

  const [rejectModalOpen, setrejectModalOpen] = useState(false);
  const [individualProduct, setIndividualProduct] = useState();

  const productId = location.pathname.split("/")[2];
  // Query
  useQuery(["individualProduct", productId], getProductByID, {
    enabled: !!productId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    onSuccess: (res) => {
      setIndividualProduct(res.data.data.result);
    },
  });

  return (
    <div>
      <div className="productindividual-div">
        <Card className=" min-height-90vh productindividual-div-card">
          <div className="productindividual-div-card-head">
            <h3>Product Details</h3>
          </div>
          <div className="productindividual-div-card-body">
            <div className="productindividual-div-card-body-image">
              <div>
                <label>
                  <p>Product Image</p>
                </label>
                <div className="productindividual-div-card-body-image-show-div">
                  {Array.isArray(individualProduct)
                    ? individualProduct.map((value, index) => (
                        <img
                          key={index}
                          src={`${config.baseUrlApi}/assets/productImages/${value.product_image}`}
                          alt="Product"
                        />
                      ))
                    : ""}
                </div>
              </div>
            </div>
            <div>
              <label>
                <p>Product Gallery Image</p>
              </label>
              <Flex gap={"3rem"} w={"100%"} pb={"1rem"}>
                {Array.isArray(individualProduct)
                  ? individualProduct.map((values, index) =>
                      values.product_gallery_image?.map((value, index) => {
                        return (
                          <div
                            key={index}
                            className="productindividual-div-card-body-image-show-div"
                          >
                            <img
                              src={`${config.baseUrlApi}/assets/productImages/${value}`}
                              alt="Product"
                            />
                          </div>
                        );
                      })
                    )
                  : ""}
              </Flex>
            </div>
            {Array.isArray(individualProduct)
              ? individualProduct.map((value, index) => {
                  const categoryName = value.CategoryDetails[0].name.replace(
                    " ",
                    "_"
                  );
                  const subCategoryName =
                    value.subCategoryDetails[0].name.replaceAll(" ", "_");
                  return (
                    <div key={index}>
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Link
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          <Link
                            to={`/product/${categoryName}/${subCategoryName}/${value._id}`}
                          >
                            {`${config.baseUrlApi}/product/${categoryName}/${subCategoryName}/${value._id}`}
                          </Link>
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Status
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          <span className="individual-product-status">
                            {value.product_status
                              ? value.product_status
                              : "Active"}
                          </span>
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Visibility
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          <span className="individual-product-status">
                            {value.product_status
                              ? value.product_status
                              : "Active"}
                          </span>
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Id
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value._id}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Title
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value.name}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Slug
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value.slug ? value.slug : value.name}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Category
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value.CategoryDetails[0].name}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Subcategory
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value?.subCategoryDetails[0]?.name}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Actual Price
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          ₹ {value.actual_price}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Sales Price
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          ₹ {value.sale_price}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Stock
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value.stock_status}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          User
                        </div>
                        <div className="productindividual-div-card-body-product-details-right">
                          {value.adminDetails
                            ? value.adminDetails[0].name
                              ? value.adminDetails[0].name
                              : "Admin"
                            : value.vendorDetails[0].name
                            ? value.vendorDetails[0].name
                            : "Vendor"}
                        </div>
                      </div>
                      <div className="hr-line mt-10" />
                      <div className="productindividual-div-card-body-product-details">
                        <div className="productindividual-div-card-body-product-details-left">
                          Description
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: value.description,
                          }}
                          className="productindividual-div-card-body-product-details-right"
                        ></div>
                      </div>
                      <div className="hr-line mt-10" />
                    </div>
                  );
                })
              : ""}
          </div>
        </Card>
      </div>
      <Modal
        zIndex={12121}
        size="md"
        opened={rejectModalOpen}
        onClose={() => setrejectModalOpen(false)}
        title=""
        centered
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="sellerbalance-edit-modal"
      >
        <div className="sellerbalance-edit-modal-header">
          <h4>Reject</h4>
        </div>
        <div className="sellerbalance-edit-modal-body">
          <div className="sellerbalance-edit-modal-body-content">
            <div className="sellerbalance-edit-modal-body-content-input">
              <Textarea
                placeholder="Reason"
                radius="xs"
                size="xs"
                autosize
                minRows={4}
              />
            </div>
            <div className="sellerbalance-edit-modal-body-content-button remove-padding">
              <button onClick={() => setrejectModalOpen(false)}>Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductIndividual;
