import { createSlice } from "@reduxjs/toolkit";
import {
  allUserList,
  userDataState,
} from "../../InitialState/userState/userState";

export const userDataSlice = createSlice({
  name: "userData",
  initialState: userDataState,
  reducers: {
    setUserData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const allUserListSlice = createSlice({
  name: "allUsers",
  initialState: allUserList,
  reducers: {
    setAllUsers: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserData } = userDataSlice.actions;
export const { setAllUsers } = allUserListSlice.actions;
