export const addressCountries = [
    'India',
    'Albania',
    'Andorra',
    'Austria',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Faroe Islands',
    'Finland',
    'France',
    'Germany',
    'Gibraltar',
    'Greece',
    'Guernsey',
    'Holy See',
    'Hungary',
    'Iceland',
    'Ireland',
    'Isle of Man',
    'Italy',
    'Jersey',
    'Kosovo',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Malta',
    'Moldova',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'Russia',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Ukraine',
    'United Kingdom',
]



export const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Delhi", "Puducherry"
]

// All Districts States List 
// Puducherry
const puducherryDistricts = ["Puducherry (Puducherry)", "Karaikal", "Yanam", "Mahe"];
// Delhi 
const delhiDistricts = ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", "South Delhi", "South East Delhi", "South West Delhi", "West Delhi"];
// West Bengal
const westBengalDistricts = ["Kolkata", "Howrah", "Hooghly", "North 24 Parganas", "South 24 Parganas", "Darjeeling",
    "Jalpaiguri", "Cooch Behar", "Alipurduar", "Malda", "Murshidabad", "Nadia", "Birbhum", "Purba Bardhaman (East Bardhaman)", "Paschim Bardhaman (West Bardhaman)", "Bankura", "Purulia", "Jhargram", "Purba Medinipur (East Medinipur)", "Paschim Medinipur (West Medinipur)"];
// Uttarakhand
const uttarakhandDistricts = ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital",
    "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"];
// Uttar Pradesh
const uttarPradeshDistricts = ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Amethi", "Amroha", "Auraiya",
    "Azamgarh", "Baghpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bhadohi", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddh Nagar (Noida)", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hapur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kasganj", "Kaushambi", "Kushinagar (Padrauna)", "Lakhimpur Kheri", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Rae Bareli", "Rampur", "Saharanpur", "Sambhal (Bhim Nagar)", "Sant Kabir Nagar", "Shahjahanpur", "Shamli", "Shravasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"];
// Tripura
const tripuraDistricts = ["Dhalai", "Gomati", "Khowai", "North Tripura", "Sepahijala", "South Tripura", "Unakoti",
    "West Tripura"];
// Telangana
const telanganaDistricts = ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar Bhupalpally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem Asifabad", "Mahabubabad",
    "Mahabubnagar", "Mancherial", "Medak",]
// Tamil Nadu
const tamilNaduDistricts = ["Ariyalur", "Chengalpattu", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kallakurichi", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Mayiladuthurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Ranipet", "Salem", "Sivaganga", "Tenkasi", "Thanjavur", "Theni", "Thoothukudi", "Tiruchirappalli", "Tirunelveli", "Tirupathur", "Tiruppur", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Vellore", "Viluppuram", "Virudhunagar"];
// Sikkim
const sikkimDistricts = ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"];
// Rajasthan
const rajasthanDistricts = ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalore", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"];
// Punjab
const punjabDistricts = ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Ferozepur",
    "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Muktsar", "Pathankot", "Patiala", "Rupnagar", "Sahibzada Ajit Singh Nagar (Mohali)", "Sangrur", "Shahid Bhagat Singh Nagar (Nawanshahr)", "Sri Muktsar Sahib", "Tarn Taran"]
// odisha
const odishaDistricts = ["Angul", "Balangir", "Balasore", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Deogarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghpur", "Jajpur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar (Keonjhar)", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Subarnapur (Sonepur)", "Sundargarh"];
// Nagaland
const nagalandDistricts = ["Dimapur", "Kohima", "Mokokchung", "Wokha", "Mon", "Phek", "Zunheboto", "Tuensang", "Longleng",];
// Meghalaya
const meghalayaDistricts = ["East Garo Hills", "East Jaintia Hills", "East Khasi Hills", "North Garo Hills", "Ri Bhoi", "South Garo Hills", "South West Garo Hills", "South West Khasi Hills", "West Garo Hills", "West Jaintia Hills", "West Khasi Hills"];
// Manipur
const manipurDistricts = ["Bishnupur", "Chandel", "Churachandpur", "Imphal East", "Imphal West", "Jiribam", "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal", "Thoubal", "Ukhrul"];
// Maharashtra
const maharashtraDistricts = ["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana",
    "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"]
// Madhya Pradesh
const madhyaPradeshDistricts = ["Bhopal", "Indore", "Jabalpur", "Gwalior", "Ujjain", "Sagar", "Dewas", "Satna", "Ratlam", "Rewa", "Khandwa", "Burhanpur", "Chhindwara", "Guna", "Shivpuri", "Vidisha", "Betul", "Hoshangabad",
    "Damoh", "Mandsaur", "Khargone", "Neemuch", "Panna", "Seoni",
    "Raisen",
    "Harda",
    "Morena",
    "Balaghat",
    "Bhind",
    "Shajapur",
    "Sheopur",
    "Ashoknagar",
    "Datia",
    "Agar Malwa",
    "Narsinghpur",
    "Sehore",
    "Anuppur",
    "Alirajpur",
    "Tikamgarh",
    "Sidhi",
    "Singrauli",
    "Barwani",
    "Dindori",
    "Jhabua",
    "Mandla",
    "Niwari",
    "Dhar",
    "Umaria",
    "Katni",
    "Chhatarpur",
    "Badwani",
    "Khandwa",
    "Sagar",
    "Shivpuri",
    "Tikamgarh",
    "Anuppur",
    "Bhind",
    "Guna",
    "Dindori",
    "Harda",
    "Jhabua",
    "Mandla",
    "Niwari",
    "Raisen",
    "Sehore",
    "Sheopur",
    "Singrauli",
    "Umaria"
];


// Kerala
const keralaDistricts = [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad"
];

// Karnataka
const karnatakaDistricts = [
    "Bagalkot",
    "Ballari (Bellary)",
    "Belagavi (Belgaum)",
    "Bengaluru Rural",
    "Bengaluru Urban",
    "Bidar",
    "Chamarajanagar",
    "Chikkaballapur",
    "Chikkamagaluru (Chikmagalur)",
    "Chitradurga",
    "Dakshina Kannada",
    "Davanagere",
    "Dharwad",
    "Gadag",
    "Hassan",
    "Haveri",
    "Kalaburagi (Gulbarga)",
    "Kodagu (Coorg)",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysuru (Mysore)",
    "Raichur",
    "Ramanagara",
    "Shivamogga (Shimoga)",
    "Tumakuru (Tumkur)",
    "Udupi",
    "Uttara Kannada (Karwar)",
    "Vijayapura (Bijapur)",
    "Yadgir"
];

// Jharkhand
const jharkhandDistricts = [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahibganj",
    "Seraikela Kharsawan",
    "Simdega",
    "West Singhbhum"
];


// Himachal Pradesh
const himachalPradeshDistricts = [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul and Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Una"
];


// Haryana
const haryanaDistricts = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram (Gurgaon)",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Nuh (Mewat)",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar"
];

// Gujarat
const gujaratDistricts = [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udaipur",
    "Dahod",
    "Dang",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kheda",
    "Kutch",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada",
    "Navsari",
    "Panchmahal",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha",
    "Surat",
    "Surendranagar",
    "Tapi",
    "Vadodara",
    "Valsad"
];

// Goa
const goaDistricts = [
    "North Goa",
    "South Goa"
];

// Andhra Pradesh
const andhraPradeshDistricts = [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Krishna",
    "Kurnool",
    "Nellore",
    "Prakasam",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
    "Y.S.R. Kadapa"
];

// Arunachal Pradesh
const arunachalPradeshDistricts = [
    "Tawang",
    "West Kameng",
    "East Kameng",
    "Papum Pare",
    "Kurung Kumey",
    "Kra Daadi",
    "Lower Subansiri",
    "Upper Subansiri",
    "West Siang",
    "East Siang",
    "Siang",
    "Upper Siang",
    "Lower Siang",
    "Lower Dibang Valley",
    "Dibang Valley",
    "Anjaw",
    "Lohit",
    "Namsai",
    "Changlang",
    "Tirap",
    "Longding"
];

// Assam
const assamDistricts = [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Dima Hasao (North Cachar Hills)",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup",
    "Kamrup Metropolitan",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Sivasagar",
    "Sonitpur",
    "South Salmara-Mankachar",
    "Tinsukia",
    "Udalguri",
];

// Bihar
const biharDistricts = [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran"
];

// chhattisgar
const chhattisgarhDistricts = [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada",
    "Dhamtari",
    "Durg",
    "Gariaband",
    "Janjgir-Champa",
    "Jashpur",
    "Kabirdham (Kawardha)",
    "Kanker",
    "Kondagaon",
    "Korba",
    "Korea (Koriya)",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur",
    "Surguja"
];


// Indian States
export const indianStatesWithDistrict = {
    'Andhra Pradesh': andhraPradeshDistricts,
    "Arunachal Pradesh": arunachalPradeshDistricts,
    "Assam": assamDistricts,
    "Bihar": biharDistricts,
    "Chhattisgarh": chhattisgarhDistricts,
    "Goa": goaDistricts,
    "Gujarat": gujaratDistricts,
    "Haryana": haryanaDistricts,
    "Himachal Pradesh": himachalPradeshDistricts,
    "Jharkhand": jharkhandDistricts,
    "Karnataka": karnatakaDistricts,
    "Kerala": keralaDistricts,
    "Madhya Pradesh": madhyaPradeshDistricts,
    "Maharashtra": maharashtraDistricts,
    "Manipur": manipurDistricts,
    "Meghalaya": meghalayaDistricts,
    "Nagaland": nagalandDistricts,
    "Odisha": odishaDistricts,
    "Punjab": punjabDistricts,
    "Rajasthan": rajasthanDistricts,
    "Sikkim": sikkimDistricts,
    "Tamil Nadu": tamilNaduDistricts,
    "Telangana": telanganaDistricts,
    "Tripura": tripuraDistricts,
    "Uttar Pradesh": uttarPradeshDistricts,
    "Uttarakhand": uttarakhandDistricts,
    "West Bengal": westBengalDistricts,
    "Delhi": delhiDistricts,
    "Puducherry": puducherryDistricts

}


// Erupoe
const albaniaCities = [
    'Tirana',
    'Durrës',
    'Vlorë',
    'Shkodër',
    'Elbasan',
    'Korçë',
    'Fier',
    'Berat',
    'Lushnjë',
    'Kavajë',
    'Gjirokastër',
    'Patos',
    'Lezhë',
    'Kuçovë',
    'Peshkopi',
    'Tropojë',
    'Burrel',
    'Gramsh',
    'Përmet',
    'Sarandë',
    'Pogradec',
    'Bajram Curri',
    'Kukës',
    'Librazhd',
    'Rrëshen',
    'Ura Vajgurore',
    'Ersekë',
    'Laç',
    'Çorovodë',
    'Himara',
    'Bilisht',
    'Rrogozhinë',
    'Peqin',
    'Ballsh',
    'Bulqizë',
    'Roskovec',
    'Çorovodë',
    'Selenicë',
    'Patos',
];


const andorraCities = [
    "Andorra la Vella",
    "Escaldes-Engordany",
    "Encamp",
    "Sant Julia de Loria",
    "La Massana",
    "Santa Coloma",
    "Ordino",
    "Canillo",
];

const austrianCities = [
    "Vienna",
    "Graz",
    "Linz",
    "Salzburg",
    "Innsbruck",
    "Klagenfurt",
    "Villach",
    "Wels",
    "Sankt Pölten",
    "Dornbirn",
];

const belarus_cities = [
    "Minsk",
    "Gomel",
    "Mogilev",
    "Vitebsk",
    "Hrodna",
    "Brest",
    "Babruysk",
    "Baranavichy",
    "Barysaw",
    "Pinsk",
    "Orsha",
    "Mazyr",
    "Soligorsk",
    "Svetlahorsk",
    "Rechytsa",
    "Novopolotsk",
    "Polatsk",
    "Zhlobin",
    "Lida",
    "Slutsk",
    "Maladzyechna",
    "Kobryn",
    "Salihorsk",
    "Vawkavysk",
    "Grodno",
    "Navapolatsk",
    "Horki",
    "Dzyarzhynsk",
    "Hlybokaye",
    "Kalinkavichy",
    "Dobrush",
    "Pastavy",
    "Bykhaw",
    "Shklow",
    "Krychaw",
    "Smalyavichy",
    "Stolin",
    "Slonim",
    "Brahin",
    "Lyepyel",
    "Zaslawye",
]

const belgiumCities = [
    "Brussels",
    "Antwerp",
    "Ghent",
    "Bruges",
    "Liege",
    "Namur",
    "Charleroi",
    "Leuven",
    "Mons",
    "Aalst",
    "Mechelen",
    "Kortrijk",
    "Ostend",
    "Sint-Niklaas",
    "Hasselt",
    "Genk",
    "Tournai",
    "Seraing",
    "Roeselare",
    "Mouscron",
    "Verviers",
    "Beveren",
    "Dendermonde",
    "Beringen",
    "Turnhout",
    "Dilbeek",
    "Heist-op-den-Berg",
    "Sint-Truiden",
    "Lokeren"
];

const citiesInBosniaAndHerzegovina = [
    "Sarajevo",
    "Banja Luka",
    "Tuzla",
    "Zenica",
    "Mostar",
    "Bihac",
    "Brcko",
    "Prijedor",
    "Doboj",
    "Cazin",
    "Trebinje",
    "Travnik",
    "Gradiska",
    "Sanski Most",
    "Livno",
    "Visegrad",
    "Konjic",
    "Srebrenik",
    "Gorazde",
    "Tesanj",
    "Zvornik",
    "Gracanica",
    "Laktaši",
    "Fojnica",
    "Bosanska Krupa",
    "Bosanska Gradiska",
    "Zavidovici",
    "Capljina",
];

const citiesInBulgaria = [
    "Sofia",
    "Plovdiv",
    "Varna",
    "Burgas",
    "Ruse",
    "Stara Zagora",
    "Pleven",
    "Sliven",
    "Dobrich",
    "Shumen",
    "Haskovo",
    "Blagoevgrad",
    "Gabrovo",
    "Pernik",
    "Yambol",
    "Pazardzhik",
    "Veliko Tarnovo",
    "Vratsa",
    "Vidin",
    "Montana",
    "Lovech",
    "Silistra",
    "Targovishte",
    "Kardzhali",
    "Kyustendil",
    "Asenovgrad",
    "Kazanlak",
    "Dimitrovgrad",
    "Harmanli",
    "Gotse Delchev",
    "Karlovo",
    "Dupnitsa",
    "Nova Zagora",
    "Svishtov",
    "Gorna Oryahovitsa",
    "Petrich",
    "Samokov",
    "Lom",
    "Sevlievo",
    "Kavarna",
    "Troyan",
    "Pomorie",
    "Radomir",
    "Peshtera",
    "Cherven Bryag",
    "Panagyurishte",
    "Botevgrad",
    "Sandanski",
    "Kazanlak",
    "Knezha",
    "Razgrad",
    "Teteven",
    "Berkovitsa",
    "Svilengrad",
    "Velingrad",
    "Provadia",
    "Sopot",
    "Aytos",
    "Krumovgrad",
    "Elhovo",
    "Lukovit",
    "Sredets",
    "Belene",
    "Byala Slatina",
    "Tutrakan",
    "Svoge",
    "Devnya",
    "Straldzha",
];

const croatianCities = [
    "Zagreb",
    "Split",
    "Rijeka",
    "Osijek",
    "Zadar",
    "Pula",
    "Šibenik",
    "Dubrovnik",
    "Slavonski Brod",
    "Karlovac",
    "Varaždin",
    "Sisak",
    "Vukovar",
    "Bjelovar",
    "Koprivnica",
    "Vinkovci",
    "Požega",
    "Samobor",
    "Velika Gorica",
    "Krapina",
    "Virovitica",
    "Makarska",
    "Poreč",
    "Vukovar",
];

const cyprusCities = [
    "Nicosia",
    "Limassol",
    "Larnaca",
    "Paphos",
    "Famagusta",
    "Kyrenia",
    "Protaras",
    "Paralimni",
    "Morphou",
    "Aradippou",
    "Geri",
    "Lakatamia",
    "Strovolos",
    "Agios Dometios",
    "Kato Polemidia",
    "Aglandjia",
    "Dali",
    "Latsia",
    "Ypsonas",
    "Agia Napa",
    "Xylophagou",
    "Sotira",
    "Deryneia",
    "Avgorou",
    "Liopetri",
    "Ammochostos",
    "Kokkinotrimithia",
    "Trikomo",
    "Arsos",
    "Choirokoitia",
    "Polis Chrysochous",
    "Emba",
    "Pano Lefkara",
    "Oroklini",
    "Parekklisia",
    "Kolossi",
    "Kiti",
    "Pano Polemidia",
    "Pissouri",
    "Yeri",
    "Erimi",
    "Episkopi",
    "Zygi",
    "Mandria",
    "Tochni",
    "Kalavasos",
    "Anogyra",
    "Platres",
    "Polemi",
    "Kouklia",
    "Chloraka",
    "Konia",
    "Peyia",
    "Neo Chorio",
    "Polis",
];
const czechCities = [
    "Prague",
    "Brno",
    "Ostrava",
    "Plzeň",
    "Liberec",
    "Olomouc",
    "České Budějovice",
    "Hradec Králové",
    "Pardubice",
    "Ústí nad Labem",
    "Zlín",
    "Karlovy Vary",
    "Jihlava",
    "Teplice",
    "Děčín",
    "Kladno",
    "Havířov",
    "Mladá Boleslav",
    "Opava",
    "Frýdek-Místek",
    "Most",
    "Karviná",
    "Jablonec nad Nisou",
    "Třebíč"
];

const danishCities = [
    "Copenhagen",
    "Aarhus",
    "Odense",
    "Aalborg",
    "Esbjerg",
    "Randers",
    "Kolding",
    "Horsens",
    "Vejle",
    "Roskilde",
    "Helsingør",
    "Herning",
    "Silkeborg",
    "Næstved",
    "Greve",
    "Ballerup",
    "Rødovre",
    "Viborg",
    "Køge",
    "Holstebro",
    "Taastrup",
    "Slagelse",
    "Hillerød",
    "Albertslund",
    "Sønderborg",
    "Fredericia",
    "Hørsholm",
    "Hørning",
    "Hjørring",
];

const citiesInEstonia = [
    "Tallinn",
    "Tartu",
    "Narva",
    "Kohtla-Järve",
    "Pärnu",
    "Viljandi",
    "Rakvere",
    "Maardu",
    "Sillamäe",
    "Kuressaare",
    "Võru",
    "Valga",
    "Haapsalu",
    "Jõhvi",
    "Paide",
    "Keila",
    "Türi",
    "Elva",
    "Saue",
    "Põlva",
    "Tõrva",
    "Paldiski",
    "Kiviõli",
    "Tapa",
    "Võhma",
    "Loksa",
    "Kallaste",
    "Rapla",
    "Haapsalu",
    "Järvakandi",
    "Kunda",
    "Kohtla-Nõmme",
    "Lihula",
    "Tamsalu",
    "Suure-Jaani",
    "Abja-Paluoja",
    "Põltsamaa",
    "Narva-Jõesuu",
    "Tõstamaa",
    "Antsla",
    "Mustvee",
    "Viljandi",
    "Rakvere",
    "Rapla",
    "Kilingi-Nõmme",
    "Otepää",
    "Kärdla",
    "Püssi",
    "Karksi-Nuia",
    "Koeru",
    "Põlva",
    "Võhma",
    "Mõisaküla",
    "Lihula",
    "Orissaare",
    "Toila",
    "Kunda",
    "Kohtla-Nõmme",
    "Paldiski",
    "Kohtla-Järve",
    "Rummu",
    "Saue",
    "Kuressaare",
    "Haapsalu",
    "Narva",
    "Tartu",
    "Pärnu",
    "Tallinn"
];

const faroeIslandsTownsAndVillages = [
    "Tórshavn",
    "Klaksvík",
    "Runavík",
    "Fuglafjørður",
    "Vestmanna",
    "Tvøroyri",
    "Vágur",
    "Sorvágur",
    "Kollafjørður",
    "Nólsoy",
    "Hvalvík",
    "Sandur",
    "Strendur",
    "Skopun",
    "Hósvík",
    "Leynar",
    "Argir",
    "Nes",
    "Sørvágur",
    "Miðvágur",
    "Kirkjubøur",
    "Hvannasund",
    "Saltangará",
    "Sandavágur",
    "Skálavík",
    "Leirvík",
    "Gøta",
    "Toftir",
    "Oyrarbakki",
    "Eiði",
    "Hvítanes",
    "Funningsfjørður",
    "Skálafjørður",
    "Viðareiði",
    "Klaksvíkarfjørður",
    "Kvívík",
    "Kaldbak",
    "Kunoy",
    "Syðrugøta",
    "Norðdepil",
    "Húsavík",
    "Strendur",
    "Oyri",
    "Skipanes",
    "Vatnsoyrar",
    "Hvalba",
    "Sandur",
    "Glyvrar",
    "Húsar",
    "Øravík",
    "Skarvanes",
    "Ljósá",
    "Rituvík",
    "Lopra",
    "Haldarsvík",
    "Kvívíkarfjørður",
    "Norðskáli",
    "Fámjin",
    "Skúvoy",
    "Hattarvík",
    "Dalur",
    "Trongisvágur",
    "Hov",
    "Sumba",
    "Sandur",
    "Vágur",
];

const finnishCities = [
    "Helsinki",
    "Espoo",
    "Tampere",
    "Vantaa",
    "Oulu",
    "Turku",
    "Jyväskylä",
    "Lahti",
    "Kuopio",
    "Kouvola",
    "Pori",
    "Joensuu",
    "Lappeenranta",
    "Hämeenlinna",
    "Vaasa",
    "Seinäjoki",
    "Rovaniemi",
    "Mikkeli",
    "Kotka",
    "Salo",
    "Porvoo",
    "Kokkola",
    "Hyvinkää",
    "Lohja",
    "Järvenpää",
    "Nurmijärvi",
    "Rauma",
    "Tuusula",
    "Kirkkonummi",
    "Kajaani",
    "Kerava",
    "Savonlinna",
    "Kaarina",
    "Ylöjärvi",
    "Kangasala",
    "Riihimäki",
    "Loviisa",
    "Imatra",
    "Nokia",
    "Varkaus",
    "Raisio",
    "Kemi",
    "Iisalmi",
    "Sipoo",
    "Vihti",
    "Äänekoski",
    "Raahe",
    "Ylivieska",
    "Forssa",
    "Kauhava",
    "Uusikaupunki",
    "Jakobstad",
    "Kemijärvi",
    "Valkeakoski",
    "Pargas",
    "Eura",
];
const cities_in_france = [
    "Paris",
    "Marseille",
    "Lyon",
    "Toulouse",
    "Nice",
    "Nantes",
    "Strasbourg",
    "Montpellier",
    "Bordeaux",
    "Lille",
    "Rennes",
    "Reims",
    "Le Havre",
    "Cannes",
    "Toulon",
    "Grenoble",
    "Dijon",
    "Angers",
    "Nîmes",
    "Aix-en-Provence",
    "Saint-Étienne",
    "Limoges",
    "Tours",
    "Amiens",
    "Clermont-Ferrand",
    "Villeurbanne",
    "Metz",
    "Besançon",
    "Perpignan",
    "Orléans",
    "Mulhouse",
    "Rouen",
    "Caen",
    "Boulogne-Billancourt",
    "Nancy",
    "Argenteuil",
    "Montreuil",
    "Roubaix",
    "Tourcoing",
    "Nanterre",
    "Avignon",
    "Vitry-sur-Seine",
    "Créteil",
    "Poitiers",
    "Dunkerque",
    "Aubervilliers",
]

const germanCities = [
    "Berlin",
    "Hamburg",
    "Munich (München)",
    "Cologne (Köln)",
    "Frankfurt",
    "Stuttgart",
    "Düsseldorf",
    "Dortmund",
    "Essen",
    "Leipzig",
    "Bremen",
    "Dresden",
    "Hanover (Hannover)",
    "Nuremberg (Nürnberg)",
    "Duisburg",
    "Bochum",
    "Wuppertal",
    "Bonn",
    "Bielefeld",
    "Mannheim",
    "Karlsruhe",
    "Augsburg",
    "Wiesbaden",
    "Gelsenkirchen",
];


const citiesInGibraltar = [
    "Gibraltar (Main Town)",
    "La Línea de la Concepción (bordering Spanish city)",
    "Catalan Bay",
    "Sandy Bay",
    "Rosia Bay",
    "Camp Bay",
    "Europa Point",
];

const citiesInGreece = [
    "Athens",
    "Thessaloniki",
    "Patras",
    "Heraklion",
    "Larissa",
    "Volos",
    "Ioannina",
    "Kavala",
    "Rhodes",
    "Chania",
    "Sparti",
    "Trikala",
    "Lamia",
    "Chalkida",
    "Serres",
    "Komotini",
    "Alexandroupoli",
    "Kalamata",
    "Kozani",
];

const guernseyCities = [
    ' St.Peter Port',
    'St.Sampson',
    'Castel',
    'St.Saviour',
    'Vale',
    'Forest'
]

const citiesInHungary = [
    "Budapest",
    "Debrecen",
    "Szeged",
    "Miskolc",
    "Pécs",
    "Győr",
    "Nyíregyháza",
    "Kecskemét",
    "Székesfehérvár",
    "Szombathely",
    "Érd",
    "Dunaújváros",
    "Sopron",
    "Szolnok",
    "Veszprém",
    "Tatabánya",
    "Kaposvár",
    "Zalaegerszeg",
    "Nagykanizsa",
    "Hódmezővásárhely",
];

const icelandCities = [
    "Reykjavik",
    "Kópavogur",
    "Hafnarfjörður",
    "Akureyri",
    "Reykjanesbær",
    "Garðabær",
    "Mosfellsbær",
    "Árborg",
    "Akranes",
    "Fjarðabyggð",
    "Selfoss",
    "Seyðisfjörður",
    "Egilsstaðir",
    "Vestmannaeyjar",
    "Hveragerði",
    "Húsavík",
    "Ísafjörður",
    "Njarðvík",
    "Ólafsvík",
    "Siglufjörður",
    "Vopnafjörður",
    "Borgarnes",
    "Dalvík",
    "Djúpivogur",
    "Grindavík",
    "Höfn",
    "Húsafell",
    "Hvammstangi",
    "Keflavik",
    "Raufarhöfn",
    "Sauðárkrókur",
    "Stykkishólmur",
    "Varmahlið",
    "Vesturbyggð",
    "Vogar",
    "Þorlákshöfn"
];

const cities_in_ireland = [
    "Dublin",
    "Cork",
    "Limerick",
    "Galway",
    "Waterford",
    "Derry",
    "Belfast",
    "Kilkenny",
    "Sligo",
    "Killarney",
    "Tralee",
    "Athlone",
    "Wexford",
    "Dundalk",
    "Letterkenny",
    "Ennis",
    "Drogheda",
    "Cavan",
    "Mullingar",
    "Carlow",
    "Bray",
    "Navan",
    "Kilkenny",
    "Wicklow",
]

const isleOfManCities = [
    "Douglas",
    "Peel",
    "Ramsey",
    "Castletown",
    "Port Erin",
    "Port St Mary",
    "Onchan",
    "Laxey",
    "Kirk Michael",
    "Ballasalla",
    "Santon",
    "Colby",
    "St Johns",
    "Ballaskeig",
    "Andreas",
    "Jurby",
    "Bride",
    "Maughold",
];

const italian_cities = [
    "Rome",
    "Milan",
    "Naples",
    "Turin",
    "Palermo",
    "Genoa",
    "Bologna",
    "Florence",
    "Venice",
    "Verona",
    "Bari",
    "Catania",
    "Trieste",
    "Padua",
    "Brescia",
    "Parma",
    "Taranto",
    "Reggio Calabria",
    "Modena",
    "Cagliari"
]

const cities_in_jersey = [
    'Saint Helier',
    'Saint Brélade',
    'Saint Clément',
    'Saint John',
    'Saint Lawrence',
    'Saint Martin',
    'Saint Mary',
    'Saint Ouen',
    'Saint Peter',
    'Saint Saviour',
    'Trinity',
    'Grouville'
]


const citiesInKosovo = [
    "Pristina",
    "Prizren",
    "Gjakova",
    "Peja",
    "Mitrovica",
    "Ferizaj",
    "Gjilan",
    "Decan",
    "Istog",
    "Kamenica",
    "Leposavic",
    "Malisheva",
    "Rahovec",
    "Shtime",
    "Suhareka",
    "Viti",
    "Zubin Potok",
    "Zvecan",
];

const latviaCities = [
    "Riga",
    "Daugavpils",
    "Liepāja",
    "Jelgava",
    "Jūrmala",
    "Ventspils",
    "Rēzekne",
    "Valmiera",
    "Jēkabpils",
    "Ogre",
    "Tukums",
    "Cēsis",
    "Salaspils",
    "Kuldīga",
    "Bauska",
    "Limbazi",
    "Sigulda",
    "Madona",
    "Lielvārde",
    "Saldus"
];


const municipalitiesInLiechtenstein = [
    "Balzers",
    "Eschen",
    "Gamprin",
    "Mauren",
    "Planken",
    "Ruggell",
    "Schaan",
    "Schellenberg",
    "Triesen",
    "Triesenberg",
    "Vaduz"
];

const lithuanianCities = [
    "Vilnius",
    "Kaunas",
    "Klaipėda",
    "Šiauliai",
    "Panevėžys",
    "Alytus",
    "Marijampolė",
    "Mažeikiai",
    "Jonava",
    "Utena",
    "Kėdainiai",
    "Tauragė",
    "Telšiai",
    "Visaginas",
    "Telsiai",
    "Plunge",
];
const luxembourgCities = [
    "Luxembourg City",
    "Esch-sur-Alzette",
    "Differdange",
    "Dudelange",
    "Ettelbruck",
    "Diekirch",
    "Wiltz",
    "Echternach",
    "Rumelange",
    "Grevenmacher",
    "Remich",
    "Vianden",
    "Beaufort",
    "Mersch",
    "Clervaux",
    "Redange-sur-Attert",
    "Schifflange",
    "Kayl",
    "Strassen",
    "Bertrange",
    "Pétange",
    "Sanem",
    "Soleuvre",
    "Bettembourg",
];

const citiesInMacedonia = [
    "Skopje",
    "Bitola",
    "Kumanovo",
    "Prilep",
    "Tetovo",
    "Ohrid",
    "Gostivar",
    "Strumica",
    "Veles",
    "Štip",
    "Kavadarci",
    "Struga",
    "Kočani",
    "Kičevo",
    "Kriva Palanka",
    "Radoviš",
    "Tearce",
    "Krusevo",
    "Delčevo",
    "Negotino",
];

const maltaCities = [
    "Valletta",
    "Birkirkara",
    "Mosta",
    "Qormi",
    "Sliema",
    "Zabbar",
    "San Gwann",
    "Paola",
    "Zejtun",
    "Rabat",
    "Naxxar",
    "Fgura",
    "Marsaskala",
    "Siggiewi",
    "Luqa",
    "Gzira",
    "Mellieha",
    "Victoria (Gozo)",
    "Marsa",
    "Balzan",
    "Isla",
    "Hamrun",
    "Xghajra",
    "Marsaxlokk",
    "Qrendi",
    "San Pawl il-Bahar (St. Paul's Bay)",
    "Dingli",
    "Mtarfa",
    "Xewkija (Gozo)",
    "Gharb (Gozo)",
    "Xaghra (Gozo)",
    "Munxar (Gozo)",
    "Sannat (Gozo)",
    "Kercem (Gozo)",
    "Fontana (Gozo)",
    "Xlendi (Gozo)",
    "Nadur (Gozo)",
    "Qala (Gozo)",
    "Marsalforn (Gozo)",
    "Gharb (Gozo)",
    "Victoria (Gozo)",
];

const moldovaCities = [
    "Chisinau",
    "Tiraspol",
    "Balti",
    "Bender",
    "Ribnita",
    "Cahul",
    "Ungheni",
    "Soroca",
    "Orhei",
    "Dubasari",
    "Comrat",
    "Causeni",
    "Edinet",
    "Hincesti",
    "Straseni",
    "Floresti",
    "Drochia",
    "Ocnita",
    "Ceadir-Lunga",
    "Nisporeni"
];

const citiesInMontenegro = [
    "Podgorica",
    "Nikšić",
    "Herceg Novi",
    "Bar",
    "Budva",
    "Kotor",
    "Cetinje",
    "Pljevlja",
    "Rožaje",
    "Tivat",
];

const citiesInNetherlands = [
    "Amsterdam",
    "Rotterdam",
    "The Hague (Den Haag)",
    "Utrecht",
    "Eindhoven",
    "Tilburg",
    "Groningen",
    "Almere",
    "Breda",
    "Nijmegen",
    "Enschede",
    "Haarlem",
    "Arnhem",
    "Zaanstad",
    "Amersfoort",
    "Apeldoorn",
    "Hoofddorp",
    "Maastricht",
    "Leiden",
    "Dordrecht",
    "Zwolle",
    "Emmen",
    "Ede",
    "Westland",
];

const norwegianCities = [
    "Oslo",
    "Bergen",
    "Trondheim",
    "Stavanger",
    "Drammen",
    "Fredrikstad",
    "Kristiansand",
    "Tromsø",
    "Sarpsborg",
    "Sandnes",
    "Skien",
    "Ålesund",
    "Sandefjord",
    "Haugesund",
    "Tønsberg",
    "Moss",
    "Porsgrunn",
    "Arendal",
    "Molde",
    "Hamar",
    "Ytrebygda",
    "Larvik",
    "Halden",
    "Askøy",
    "Kongsberg",
    "Harstad",
    "Kristiansund",
    "Leirvik",
    "Moss",
    "Mysen",
    "Narvik",
    "Osøyro",
    "Ski",
    "Vennesla",
    "Gjøvik",
    "Mo i Rana",
    "Lillehammer",
    "Horten",
    "Nesoddtangen",
    "Elverum",
    "Hønefoss",
    "Grimstad",
    "Kongsvinger",
];

const cities_in_poland = [
    "Warsaw",
    "Kraków",
    "Łódź",
    "Wrocław",
    "Poznań",
    "Gdańsk",
    "Szczecin",
    "Bydgoszcz",
    "Lublin",
    "Białystok",
    "Katowice",
    "Gdynia",
    "Częstochowa",
    "Radom",
    "Sosnowiec",
    "Toruń",
    "Kielce",
    "Rzeszów",
    "Gliwice",
    "Zabrze"
]

const citiesInPortugal = [
    "Lisbon",
    "Porto",
    "Vila Nova de Gaia",
    "Amadora",
    "Braga",
    "Setúbal",
    "Coimbra",
    "Queluz",
    "Funchal",
    "Cacém",
    "Vila Franca de Xira",
    "Agualva-Cacém",
    "Viseu",
    "Loures",
    "Odivelas",
    "Maiorca",
    "Matosinhos",
    "Guimarães",
    "Almada",
    "Seixal",
];

const romanianCities = [
    "Bucharest",
    "Cluj-Napoca",
    "Timișoara",
    "Iași",
    "Constanța",
    "Craiova",
    "Brașov",
    "Galați",
    "Oradea",
    "Ploiești",
    "Arad",
    "Sibiu",
    "Brăila",
    "Bacău",
    "Târgu Mureș",
    "Baia Mare",
    "Buzău",
    "Oradea",
    "Pitești",
    "Satu Mare",
    "Drobeta-Turnu Severin",
    "Târgu Jiu",
    "Focșani",
    "Târgu-Mureș",
    "Suceava",
    "Piatra Neamț",
    "Botoșani",
    "Râmnicu Vâlcea",
    "Tulcea",
    "Târgoviște",
    "Deva",
    "Alba Iulia",
    "Giurgiu",
    "Călărași",
    "Zalău",
    "Sfântu Gheorghe",
    "Râmnicu Sărat",
    "Câmpina",
    "Slatina",
    "Fălticeni",
    "Hunedoara",
    "Medgidia",
    "Slobozia",
    "Caracal",
    "Turnu Măgurele",
    "Dorohoi",
    "Miercurea Ciuc",
    "Vaslui",
    "Turda",
    "Lugoj",
    "Aiud",
    "Roman",
    "Buftea",
    "Câmpulung Moldovenesc",
    "Tecuci",
    "Petrosani",
    "Reșița",
    "Odorheiu Secuiesc",
    "Hârlău",
    "Sighetu Marmației",
    "Drobeta-Turnu Severin",
    "Râșnov",
    "Târnăveni",
    "Bistrița",
    "Cisnădie",
    "Bocșa",
    "Adjud",
    "Cernavodă",
    "Mioveni",
    "Pașcani",
    "Băile Tușnad",
    "Făgăraș",
    "Borsa",
    "Călimănești",
    "Șimleu Silvaniei",
    "Victoria",
    "Beiuș",
    "Călan",
    "Ștei",
    "Oltenița",
    "Bolintin-Vale",
    "Gheorgheni",
    "Săcele",
    "Ocna Mureș",
    "Simeria",
    "Rupea",
    "Tălmaciu",
    "Cavnic",
    "Pantelimon",
    "Moinești",
    "Bumbești-Jiu",
    "Rovinari",
    "Zărnești",
    "Târgu Cărbunești",
    "Băicoi",
    "Făurei",
    "Sângeorz-Băi",
    "Dolhasca",
    "Bârlad",
    "Săcueni",
    "Cugir",
    "Odobești",
    "Câmpia Turzii",
    "Mangalia",
    "Vatra Dornei",
    "Bârlad",
    "Bacău",
    "Focșani",
    "Suceava",
    "Piatra Neamț",
    "Botoșani",
    "Brăila",
    "Arad",
    "Drobeta-Turnu Severin",
    "Târgu Jiu",
    "Slatina",
    "Târgu-Mureș",
    "Oradea",
    "Sibiu",
    "Ploiești",
    "Tulcea",
    "Craiova",
    "Constanța",
    "Galați",
    "Timișoara",
    "Cluj-Napoca",
    "Iași",
    "Bucharest"
];

const russianCities = [
    "Moscow",
    "Saint Petersburg",
    "Novosibirsk",
    "Yekaterinburg",
    "Nizhny Novgorod",
    "Kazan",
    "Chelyabinsk",
    "Omsk",
    "Samara",
    "Rostov-on-Don",
    "Ufa",
    "Volgograd",
    "Perm",
    "Krasnoyarsk",
    "Voronezh",
    "Saratov",
    "Krasnodar",
    "Tolyatti",
    "Irkutsk",
    "Ulyanovsk",
    "Tyumen",
    "Barnaul",
    "Vladivostok",
];

const sanMarinoCities = [
    "San Marino (City of San Marino)",
    "Borgo Maggiore",
    "Serravalle",
    "Domagnano",
    "Faetano",
    "Fiorentino",
    "Montegiardino",
    "Chiesanuova",
    "Acquaviva",
    "City of San Marino (Città)"
];

const citiesInSerbia = [
    "Belgrade",
    "Novi Sad",
    "Niš",
    "Kragujevac",
    "Subotica",
    "Zrenjanin",
    "Pančevo",
    "Čačak",
    "Kraljevo",
    "Novi Pazar",
    "Leskovac",
    "Valjevo",
    "Smederevo",
    "Užice",
    "Vranje",
    "Šabac",
    "Požarevac",
    "Zaječar",
    "Kruševac",
    "Sombor",
    "Pirot",
    "Bor",
    "Vršac",
    "Ruma",
    "Sremska Mitrovica",
    "Aranđelovac",
    "Inđija",
    "Smederevska Palanka",
    "Prokuplje",
    "Loznica",
    "Užice",
    "Sombor",
    "Kikinda",
    "Prijepolje",
    "Vršac",
    "Vrbas",
    "Bačka Palanka",
    "Apatin",
    "Bačka Topola",
    "Zrenjanin",
    "Kikinda",
    "Sremska Mitrovica",
    "Sombor",
    "Subotica",
    "Vršac",
    "Zrenjanin"
];

const cities_in_slovakia = [
    "Bratislava",
    "Košice",
    "Prešov",
    "Žilina",
    "Nitra",
    "Banská Bystrica",
    "Trnava",
    "Martin",
    "Trenčín",
    "Poprad",
]

const slovenianCities = [
    "Ljubljana",
    "Maribor",
    "Celje",
    "Kranj",
    "Velenje",
    "Koper",
    "Novo Mesto",
    "Ptuj",
    "Trbovlje",
    "Kamnik",
    "Jesenice",
    "Nova Gorica",
    "Domžale",
    "Škofja Loka",
    "Murska Sobota",
    "Izola",
    "Postojna",
    "Logatec",
    "Nova Gorica",
];

const SpainOptions = [
    "A Coruña",
    "Álava",
    "Albacete",
    "Alicante",
    "Almería",
    "Asturias",
    "Ávila",
    "Badajoz",
    "Barcelona",
    "Burgos",
    "Cáceres",
    "Cádiz",
    "Cantabria",
    "Castellón",
    "Ceuta",
    "Ciudad Real",
    "Córdoba",
    "Cuenca",
    "Girona",
    "Granada",
    "Guadalajara",
    "Guipúzcoa",
    "Huelva",
    "Huesca",
    "Illes Balears",
    "Jaén",
    "La Rioja",
    "Las Palmas",
    "León",
    "Lleida",
    "Lugo",
    "Madrid",
    "Málaga",
    "Melilla",
    "Murcia",
    "Navarra",
    "Ourense",
    "Palencia",
    "Pontevedra",
    "Salamanca",
    "Santa Cruz de Tenerife",
    "Segovia",
    "Sevilla",
    "Soria",
    "Tarragona",
    "Teruel",
    "Toledo",
    "Valencia",
    "Valladolid",
    "Vizcaya",
    "Zamora",
    "Zaragoza",
];


const swedishCities = [
    "Stockholm",
    "Gothenburg (Göteborg)",
    "Malmö",
    "Uppsala",
    "Linköping",
    "Västerås",
    "Örebro",
    "Helsingborg",
    "Norrköping",
    "Jönköping",
    "Lund",
    "Umeå",
    "Gävle",
    "Borås",
    "Södertälje",
    "Huddinge",
    "Eskilstuna",
    "Solna",
    "Växjö",
    "Karlstad",
    "Täby",
    "Västerås",
    "Halmstad",
    "Sundsvall",
    "Luleå",
    "Trollhättan",
    "Östersund",
    "Borlänge",
    "Upplands Väsby",
    "Falun",
    "Kalmar",
    "Skövde",
    "Karlshamn",
    "Kristianstad",
    "Nyköping",
    "Partille",
    "Motala",
    "Landskrona",
    "Lidingö",
    "Alingsås",
    "Kungsbacka",
    "Visby",
    "Mariefred"
];

const swissCities = [
    "Zurich",
    "Geneva",
    "Bern",
    "Basel",
    "Lausanne",
    "Lucerne",
    "St. Gallen",
    "Lugano",
    "Winterthur",
    "La Chaux-de-Fonds",
    "Fribourg",
    "Neuchâtel",
    "Thun",
    "Schaffhausen",
    "Vernier",
    "Chur",
    "Sion",
    "Uster",
    "Emmen",
    "Zug"
];

const cities_in_ukraine = [
    "Kyiv",
    "Kharkiv",
    "Lviv",
    "Odesa",
    "Dnipro",
    "Donetsk",
    "Zaporizhzhia",
    "Kryvyi Rih",
    "Mykolaiv",
    "Mariupol",
    "Luhansk",
    "Vinnytsia",
    "Poltava",
    "Chernihiv",
    "Kherson",
    "Sumy",
    "Rivne",
    "Ternopil",
    "Ivano-Frankivsk",
    "Cherkasy",
    "Khmelnytskyi",
    "Kirovohrad",
    "Zhytomyr",
    "Lutsk",
    "Makiivka",
    "Sevastopol",
    "Simferopol",
    "Kramatorsk",
    "Melitopol",
    "Nikopol",
    "Uzhhorod",
    "Berdyansk",
    "Alchevsk",
    "Bila Tserkva",
    "Kremenchuk",
    "Hmelnytskyi",
    "Kerch",
    "Yalta",
    "Berdyansk",
    "Feodosiya",
    "Evpatoria",
    "Kamianske",
    "Drohobych",
    "Chernivtsi",
    "Berdiansk",
    "Nizhyn",
    "Kostiantynivka",
    "Sievierodonetsk",
    "Brovary",
    "Uman",
    "Balakliia",
    "Chernobyl",
    "Kovel",
    "Mukachevo",
    "Khust",
    "Sloviansk",
    "Boryspil",
    "Fastiv",
    "Korsun-Shevchenkivskyi",
    "Berdychiv",
    "Shostka",
    "Kupyansk",
    "Izmail",
    "Kamianets-Podilskyi",
]

const ukCities = [
    "London",
    "Manchester",
    "Birmingham",
    "Glasgow",
    "Liverpool",
    "Leeds",
    "Newcastle",
    "Bristol",
    "Sheffield",
    "Cardiff",
    "Edinburgh",
    "Belfast",
    "Nottingham",
    "Southampton",
    "Leicester",
    "Coventry",
    "Brighton",
    "Aberdeen",
    "Cambridge",
    "Oxford",
];


export const selectedListStates = {
    'India': indianStates,
    'Albania': albaniaCities,
    'Andorra': andorraCities,
    'Austria': austrianCities,
    'Belarus': belarus_cities,
    'Belgium': belgiumCities,
    'Bosnia and Herzegovina': citiesInBosniaAndHerzegovina,
    'Bulgaria': citiesInBulgaria,
    'Croatia': croatianCities,
    'Cyprus': cyprusCities,
    'Czech Republic': czechCities,
    'Denmark': danishCities,
    'Estonia': citiesInEstonia,
    'Faroe Islands': faroeIslandsTownsAndVillages,
    'Finland': finnishCities,
    'France': cities_in_france,
    'Germany': germanCities,
    'Gibraltar': citiesInGibraltar,
    'Greece': citiesInGreece,
    'Guernsey': guernseyCities,
    // 'Holy See': '',
    'Hungary': citiesInHungary,
    'Iceland': icelandCities,
    'Ireland': cities_in_ireland,
    'Isle of Man': isleOfManCities,
    'Italy': italian_cities,
    'Jersey': cities_in_jersey,
    'Kosovo': citiesInKosovo,
    'Latvia': latviaCities,
    'Liechtenstein': municipalitiesInLiechtenstein,
    'Lithuania': lithuanianCities,
    'Luxembourg': luxembourgCities,
    'Macedonia': citiesInMacedonia,
    'Malta': maltaCities,
    'Moldova': moldovaCities,
    // 'Monaco': '',
    'Montenegro': citiesInMontenegro,
    'Netherlands': citiesInNetherlands,
    'Norway': norwegianCities,
    'Poland': cities_in_poland,
    'Portugal': citiesInPortugal,
    'Romania': romanianCities,
    'Russia': russianCities,
    'San Marino': sanMarinoCities,
    'Serbia': citiesInSerbia,
    'Slovakia': cities_in_slovakia,
    'Slovenia': slovenianCities,
    'Spain': SpainOptions,
    // 'Svalbard and Jan Mayen': '',
    'Sweden': swedishCities,
    'Switzerland': swissCities,
    'Ukraine': cities_in_ukraine,
    'United Kingdom': ukCities

}


export const userAddressState = {
    value: []
}


export const allAddressState = {
    value:[]
}

