import axios from "axios";
import config from "../../server/Servers";

const token = sessionStorage.getItem("MogoAdminAccessToken102");
const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

// Create Product
export const createProductAPI = (formData) => {
  return axios.post(
    `${config.baseUrlApi}/products/create`,
    formData,
    commonHeader
  );
};

// List All Product
export const listAllProduct = () => {
  return axios.get(`${config.baseUrlApi}/products`);
};

// Get By id
export const getProductByID = ({ queryKey }) => {
  const [_, id] = queryKey;
  return axios.get(`${config.baseUrlApi}/products/getbyid/${id}`);
};

// Array of Product
export const getArrayOfProductid = (payload) => {
  return axios.post(`${config.baseUrlApi}/products/arrayofproduct`, payload);
};

// Delete Product By Id
export const deleteProductById = (product_id) => {
  return axios.delete(`${config.baseUrlApi}/products/${product_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// update Products
export const updateProducts = (payload) => {
  const { id, body } = payload;
  return axios.put(`${config.baseUrlApi}/products/updatebyid/${id}`, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createProductAPIByVendor = (formData) => {
  return axios.post(`${config.baseUrlApi}/products/createbyvendor`, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem(
        "MogovendorAccessToken102"
      )}`,
    },
  });
};
