export const categoryArray = [
    {
        category: 'Products',
        link: '/products',
        value: [
            { value: 'Table Linen', link: '/category/table_linen', },
            { value: 'Kitchen Linen', link: '/category/kitchen_linen', },
            { value: 'Bath Linen', link: '/category/bath_linen', },
            { value: 'Bed Linen', link: '/category/bed_linen', },
            { value: 'Home Linen', link: '/category/home_linen', },
            { value: 'Baby Linen', link: '/category/baby_linen', },
        ]
    },
    {
        category: 'Table Linen',
        value: [
            { value: 'Table Cloth', link: '/table_linen/table_cloth', },
            { value: 'Place Mat', link: '/table_linen/place_mat', },
            { value: 'Table Runner', link: '/table_linen/table_runner', },
            { value: 'Table Napkins', link: '/table_linen/table_napkins', },
            { value: 'Napkins Rings', link: '/table_linen/napkins_rings', },
            { value: 'Coasters', link: '/table_linen/coasters', },
            { value: 'Bread Baskets', link: '/table_linen/bread_baskets', },
        ]
    },
    {
        category: 'Kitchen Linen',
        value: [
            { value: 'Aprons', link: '/kitchen_linen/aprons', },
            { value: 'Gloves', link: '/kitchen_linen/gloves', },
            { value: 'Pot Holders', link: '/kitchen_linen/pot_holders', },
            { value: 'kitchen Towels', link: '/kitchen_linen/kitchen_towels', },
            { value: 'Dish Cloths', link: '/kitchen_linen/dish_cloths', },
        ]
    },
    {
        category: 'Bath Linen',
        value: [
            { value: 'Bath Towels', link: '/bath_linen/bath_towels', },
            { value: 'Hand Towels', link: '/bath_linen/hand_towels', },
            { value: 'Face Towels', link: '/bath_linen/face_towels', },
            { value: 'Bath Mats', link: '/bath_linen/bath_mats', },
            { value: 'Bathrobes', link: '/bath_linen/bathrobes', },
            { value: 'Shower Curtains', link: '/bath_linen/shower_curtains', },
            { value: 'Towel Racks', link: '/bath_linen/towel_racks', },
            { value: 'Towel Rings', link: '/bath_linen/towel_rings', },
            { value: 'Towel Hooks', link: '/bath_linen/towel_hooks', },
        ]
    },
    {
        category: 'Bed Linen',
        value: [
            { value: 'Bed Sheets', link: '/bed_linen/bed_sheets', },
            { value: 'Pillowcases', link: '/bed_linen/pillowcases', },
            { value: 'Duvet Covers', link: '/bed_linen/duvet_covers', },
            { value: 'Bedspreads', link: '/bed_linen/bedspreads', },
            { value: 'Blankets and Throws', link: '/bed_linen/blankets_and_throws', },
            { value: 'Cushions', link: '/bed_linen/cushions', },
            { value: 'Thermal Blankets', link: '/bed_linen/thermal_blankets', },
            { value: 'Quilts', link: '/bed_linen/quilts', },
            { value: 'Bolsters', link: '/bed_linen/bolsters', },
            { value: 'Shams', link: '/bed_linen/shams', },
        ]
    },
    {
        category: 'Home Linen',
        value: [
            { value: 'Curtains', link: '/home_linen/curtains', },
            { value: 'Throws', link: '/home_linen/throws', },
            { value: 'Mattress', link: '/home_linen/mattress', },
            { value: 'Window Panel', link: '/home_linen/window_panel', },
            { value: 'Cushion', link: '/home_linen/cushion', },
            { value: 'Chair Pads', link: '/home_linen/chair_pads', },
            { value: 'Box Cushions', link: '/home_linen/box_cushions', },
            { value: 'Hammocks', link: '/home_linen/hammocks', },
            { value: 'Chair Covers', link: '/home_linen/chair_covers', },
            { value: 'Door Stoppers', link: '/home_linen/door_stoppers', },
        ]
    },
    {
        category: 'Baby Linen',
        value: [
            { value: 'Hoodies', link: '/baby_linen/hoodies', },
            { value: 'Blanket', link: '/baby_linen/blanket', },
            { value: 'Booties', link: '/baby_linen/booties', },
            { value: 'Cap', link: '/baby_linen/cap', },
            { value: 'Head Band', link: '/baby_linen/head_band', },
            { value: 'Wrap', link: '/baby_linen/wrap', },
            { value: 'Wipes', link: '/baby_linen/wipes', },
            { value: 'Gloves', link: '/baby_linen/gloves', },
            { value: 'Bathrobe', link: '/baby_linen/bathrobe', },
            { value: 'Playsuit', link: '/baby_linen/playsuit', },
            { value: 'Sleeping Bag', link: '/baby_linen/sleeping_bag', },
            { value: 'Changing Pad', link: '/baby_linen/changing_pad', },
            { value: 'Duvet & Pillow', link: '/baby_linen/duvet_&_pillow', },
            { value: 'Bib', link: '/baby_linen/bib', },
            { value: 'Soft Toys', link: '/baby_linen/soft_toys', },
            { value: 'Feeding Bottle Cover', link: '/baby_linen/feeding_bottle_cover', },
            { value: 'Fitted Sheet', link: '/baby_linen/fitted_sheet', },
            { value: 'Apron', link: '/baby_linen/apron', },
            { value: 'Bumper', link: '/baby_linen/bumper', },
        ]
    },
]