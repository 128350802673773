import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Flex, Input, Textarea } from "@mantine/core";
import "./Profile.css";
import userprofile from "../../../../assets/admin/User/user_profile.png";
import cameraicon from "../../../../assets/admin/User/cameraicon.png";
import { adminDetailsControl } from "../../../../controller/loginAuth/adminAuth/AdminControl";
import { useQuery, useQueryClient } from "react-query";
import { getAdminByID } from "../../../../config/quries/AdminLogin/Quries";
import { useDispatch, useSelector } from "react-redux";
import { setAdminData } from "../../../../StateHandler/Slice/AdminData/AdminDataSlice";
import { Pencil } from "tabler-icons-react";

import config from "../../../../config/server/Servers";
const Profile = () => {
  const adminId = sessionStorage.getItem("MogoAdminAccessToken101");
  const adminData = useSelector((state) => state?.adminData?.value);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  // Getting Admin Data
  useQuery(["adminData", adminId], getAdminByID, {
    enabled: !!adminId,
    onSuccess: (res) => {
      dispatch(setAdminData(res?.data?.data));
    },
  });
  const [profile, setProfile] = useState({
    firstname: adminData.first_name,
    lastname: adminData.last_name,
    email: adminData.email,
    mobilenumber: adminData.mobile,
    address: adminData.address,
    profile_image: adminData.profile_image,
  });
  const [validateData, setValidateData] = useState({
    firstname: 0,
    lastname: 0,
    email: 0,
    mobilenumber: 0,
    address: 0,
  });
  const [editProfile, setEditProfile] = useState(false);
  const fileInputRef = useRef();
  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setProfile({ ...profile, profile_image: selectedFile });
  };
  const handleInputOpen = () => {
    fileInputRef.current.click();
  };

  const handleAdminDetails = () => {
    adminDetailsControl(
      profile,
      setProfile,
      validateData,
      setValidateData,
      queryClient,
      setEditProfile
    );
  };

  useEffect(() => {
    if (profile.firstname) {
      setValidateData({ ...validateData, firstname: 0 });
    }
    if (profile.lastname) {
      setValidateData({ ...validateData, lastname: 0 });
    }
    if (profile.mobilenumber) {
      setValidateData({ ...validateData, mobilenumber: 0 });
    }
    if (profile.address) {
      setValidateData({ ...validateData, address: 0 });
    }
  }, [profile]);

  useEffect(() => {
    if (adminData) {
      setProfile({
        ...profile,
        firstname: adminData?.first_name,
        lastname: adminData?.last_name,
        email: adminData?.email,
        mobilenumber: adminData?.mobile,
        address: adminData?.address,
        profile_image: adminData?.profile_image,
      });
    }
  }, [adminData]);

  return (
    <div>
      <div className="adminprofile-div">
        {adminData?.first_name && editProfile === false ? (
          <Card>
            <div className="admin_address_details-profile-image">
              <div className="user-adminprofile-image-div">
                {adminData.profile_image ? (
                  <img
                    src={`${config.baseUrlApi}/assets/adminProfile/${adminData.profile_image}`}
                    alt="user_profile"
                  />
                ) : (
                  <img src={userprofile} />
                )}
              </div>
            </div>
            <div className="admin_address_details">
              <Flex>
                Name : {adminData?.first_name + " " + adminData?.last_name}{" "}
              </Flex>
              <Flex>Email Id : {adminData?.email} </Flex>
              <Flex>Mobile Number: {adminData?.mobile} </Flex>
              <Flex>Address : {adminData?.address} </Flex>
            </div>
            <div className="admin_address_details_edit_btn">
              <Button onClick={() => setEditProfile(true)}>
                <Flex align={"center"} gap={4}>
                  <Pencil size={"1rem"} />
                  Edit
                </Flex>
              </Button>
            </div>
          </Card>
        ) : (
          <Card className="adminprofile-div-card">
            <div className="adminprofile-div-card-userprofile">
              <div className="user-adminprofile-image-div">
                {profile.profile_image ? (
                  profile.profile_image?.name ? (
                    <img
                      src={URL.createObjectURL(profile.profile_image)}
                      alt="user_profile"
                    />
                  ) : (
                    <img
                      src={`${config.baseUrlApi}/assets/adminProfile/${adminData.profile_image}`}
                      alt="user_profile"
                    />
                  )
                ) : (
                  <img src={userprofile} />
                )}
              </div>
              <div
                onClick={handleInputOpen}
                className="user-adminprofile-image-div-upload"
              >
                <img src={cameraicon} />
              </div>
            </div>
            <input
              onChange={handleFileUpload}
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="adminprofile-div-card-form">
              <div className="adminprofile-div-card-form-group">
                <div className="adminprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.firstname === 1
                        ? "Please Enter First Name"
                        : ""
                    }`}
                    label="First Name"
                  >
                    <Input
                      value={profile.firstname}
                      placeholder="First Name"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          firstname: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
                <div className="adminprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.lastname === 1
                        ? "Please Enter Last Name"
                        : ""
                    }`}
                    label="Last Name"
                  >
                    <Input
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          lastname: e.target.value,
                        });
                      }}
                      value={profile.lastname}
                      placeholder="Last Name"
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="adminprofile-div-card-form-group">
                <div className="adminprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.email === 1 ? "Please Enter Email" : ""
                    }`}
                    label="Email"
                  >
                    <Input
                      value={profile.email}
                      disabled
                      placeholder="Email"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          email: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
                <div className="adminprofile-div-card-form-individual">
                  <Input.Wrapper
                    error={`${
                      validateData.mobilenumber === 1
                        ? "Please Enter Mobile Number"
                        : validateData.mobilenumber === 2
                        ? "Please Enter Valid Mobile Number"
                        : ""
                    }`}
                    label="Mobile Number"
                  >
                    <Input
                      value={profile.mobilenumber}
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          mobilenumber: e.target.value,
                        });
                      }}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="adminprofile-div-card-form-individual">
                <Input.Wrapper
                  error={`${
                    validateData.address === 1 ? "Please Enter Address" : ""
                  }`}
                >
                  <Textarea
                    value={profile.address}
                    label="Address"
                    radius="xs"
                    autosize
                    error={validateData.address === 1 ? true : false}
                    minRows={3}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        address: e.target.value,
                      });
                    }}
                  />
                </Input.Wrapper>
              </div>
              <div className="adminprofile-div-card-form-individual-btn">
                <Flex gap={5}>
                  <Button onClick={() => setEditProfile(false)}>Cancel</Button>
                  <button onClick={handleAdminDetails}>Save Changes</button>
                </Flex>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Profile;
