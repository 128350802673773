// import react packages
import React, { useState } from "react";

// import ShippingSettings css
import "./ShippingSettings.css";
import "../Users/Users.css";

// import mantine packages
import { Button, Card, Input, Select, Modal, MultiSelect } from "@mantine/core";

// import icons
import plus from "../../../assets/admin/table/dropdown/plus.png";
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import anglebottom from "../../../assets/admin/table/anglebottom.webp";

import { selectedListStates } from "../../../StateHandler/InitialState/Address/AddressState";

// import packages from react router dom
import { Link } from "react-router-dom";

const ShippingSettings = () => {
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [shippingModalClassOpen, setShippingModalClassOpen] = useState(false);
  const [shippingModalDeliveryTimeOpen, setShippingModalDeliveryTimeOpen] =
    useState(false);
  const [country, setCountry] = useState("");

  return (
    <div className="shipping-setting-div">
      <Card className="shipping-setting-card">
        <div className="shipping-setting-card-header">
          <h4>Shipping Zones</h4>
          <Link to="/add_shippingZone">
            <Button className="add-shipping-zone-btn">
              <img src={plus} width="12" alt="plus icon" />
              Add Shipping Zone
            </Button>
          </Link>
        </div>

        <div className="shipping-zone-display-table-div">
          <table className="shipping-zone-display-table ">
            <thead>
              <tr>
                <th>Zone Name</th>
                <th>Regions</th>
                <th>Shipping Methods</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tamil Nadu</td>
                <td>Tamil Nadu</td>
                <td>Flat Rate,Free Shipping</td>
                <td>
                  <button className="table-select-dropdown">
                    <img src={anglebottom} alt="anglebottom" width={15} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Tamil Nadu</td>
                <td>Tamil Nadu</td>
                <td>Flat Rate,Free Shipping</td>
                <td>
                  <button className="table-select-dropdown">
                    <img src={anglebottom} alt="anglebottom" width={15} />
                  </button>
                </td>
              </tr>
              <tr>
                <td>Tamil Nadu</td>
                <td>Tamil Nadu</td>
                <td>Flat Rate,Free Shipping</td>
                <td>
                  <button className="table-select-dropdown">
                    <img src={anglebottom} alt="anglebottom" width={15} />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default ShippingSettings;
