import { configureStore } from "@reduxjs/toolkit";
import { loginModalSlice, loginSlice } from "../Slice/Login/LoginSlice";
import { carouselSlice } from "../Slice/Home/Carousel/CarouselSlice";
import {
  allUserListSlice,
  userDataSlice,
} from "../Slice/UserSlice/UserSliceData";
import { productSlice } from "../Slice/Products/ProductSlice";
import { AdminDataSlice } from "../Slice/AdminData/AdminDataSlice";
import {
  allVendorsListSlice,
  vendorDataSlice,
} from "../Slice/VendorData/VendorDataSlice";
import { cartSlice } from "../Slice/CartSlice/CartSlice";
import { wishListSlice } from "../Slice/wishList/WishListSlice";
import { userOrdersSlice } from "../Slice/UserOrdersSlice/UserOrderSlice";
import { orderSlice } from "../Slice/Orders/OrdersSlice";
import { allAddressSlice } from "../Slice/Address/AddressSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginSlice.reducer,
    loginModalStatus: loginModalSlice.reducer,
    carouselDetails: carouselSlice.reducer,
    userData: userDataSlice.reducer,
    productList: productSlice.reducer,
    adminData: AdminDataSlice.reducer,
    vendorData: vendorDataSlice.reducer,
    cartData: cartSlice.reducer,
    wishListData: wishListSlice.reducer,
    userOrders: userOrdersSlice.reducer,
    orderList: orderSlice.reducer,
    allUsersList: allUserListSlice.reducer,
    allVendorsList: allVendorsListSlice.reducer,
    allAddressList: allAddressSlice.reducer,
  },
});
