import React, { useEffect, useState } from "react";
import {
  Center,
  Flex,
  Input,
  PasswordInput,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import "./Registration.css";
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import {
  addressCountries,
  selectedListStates,
} from "../../../StateHandler/InitialState/Address/AddressState";
import { handleVendorRegister } from "../../../controller/loginAuth/vendorAuth/vendorLogin";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "tabler-icons-react";

const Registration = () => {
  const navigate = useNavigate();
  const [registration, setRegistration] = useState({
    firstname: "",
    lastname: "",
    companyname: "",
    email: "",
    phonenumber: "",
    gstn: "",
    password: "",
    confirmpassword: "",
    address: "",
    country: "",
    state: "",
    zipcode: "",
  });

  const [validateData, setValidateData] = useState({
    firstname: 0,
    lastname: 0,
    companyname: 0,
    email: 0,
    phonenumber: 0,
    gstn: 0,
    password: 0,
    confirmpassword: 0,
    address: 0,
    country: 0,
    state: 0,
    zipcode: 0,
  });

  useEffect(() => {
    if (registration.firstname.trim()) {
      setValidateData({ ...validateData, firstname: 0 });
    }
    if (registration.lastname.trim()) {
      setValidateData({ ...validateData, lastname: 0 });
    }
    if (registration.companyname.trim()) {
      setValidateData({ ...validateData, companyname: 0 });
    }
    if (registration.email.trim()) {
      setValidateData({ ...validateData, email: 0 });
    }
    if (registration.phonenumber.trim()) {
      setValidateData({ ...validateData, phonenumber: 0 });
    }
    if (registration.gstn.trim()) {
      setValidateData({ ...validateData, gstn: 0 });
    }
    if (registration.password.trim()) {
      setValidateData({ ...validateData, password: 0 });
    }
    if (registration.confirmpassword.trim()) {
      setValidateData({ ...validateData, confirmpassword: 0 });
    }
    if (registration.address.trim()) {
      setValidateData({ ...validateData, address: 0 });
    }
    if (registration.country.trim()) {
      setValidateData({ ...validateData, country: 0 });
    }
    if (registration.state.trim()) {
      setValidateData({ ...validateData, state: 0 });
    }
    if (registration.zipcode.trim()) {
      setValidateData({ ...validateData, zipcode: 0 });
    }
  }, [registration]);

  // Handle Register Submit
  const handleRegisterSubmit = () => {
    handleVendorRegister(
      registration,
      setRegistration,
      validateData,
      setValidateData,
      navigate
    );
  };

  return (
    <div>
      <div className="registration-div">
        <div className="registration-div-container">
          <div className="registration-div-container-heading">
            <h2>Vendor Registration Form</h2>
          </div>
          <div className="registration-div-container-content">
            <div className="registration-div-container-content-form">
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                ${validateData.firstname === 1 ? "Please Enter First Name" : ""}
                `}
                  label="First Name"
                >
                  <Input
                    placeholder="First Name"
                    value={registration.firstname}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        firstname: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`
                  ${validateData.lastname === 1 ? "Please Enter Last Name" : ""}
                  `}
                  label="Last Name"
                >
                  <Input
                    placeholder="Last Name"
                    value={registration.lastname}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        lastname: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                  ${
                    validateData.companyname === 1
                      ? "Please Enter Company Name"
                      : ""
                  }
                  `}
                  label="Company Name"
                >
                  <Input
                    placeholder="Company Name"
                    value={registration.companyname}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        companyname: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`
                  ${
                    validateData.email === 1
                      ? "Please Enter Email"
                      : validateData.email === 2
                      ? "Please Enter Valid Email Address"
                      : ""
                  }
                  `}
                  label="Email"
                >
                  <Input
                    placeholder="Email"
                    value={registration.email}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        email: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                 ${
                   validateData.phonenumber === 1
                     ? "Please Enter Mobile Number"
                     : validateData.phonenumber === 2
                     ? "Please Enter Valid Mobile Number"
                     : ""
                 }
                 `}
                  label="Phone Number"
                >
                  <Input
                    placeholder="Phone Number"
                    value={registration.phonenumber}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        phonenumber: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`
                 ${
                   validateData.gstn === 1
                     ? "Please Enter GSTIN"
                     : validateData.gstn === 2
                     ? "Please Enter Valid GSTIN"
                     : ""
                 }
                 `}
                  label="GSTN"
                >
                  <Input
                    placeholder="GSTN"
                    value={registration.gstn}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        gstn: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                 ${
                   validateData.password === 1
                     ? "Please Enter Password"
                     : validateData.password === 2
                     ? "Please Enter Valid Password"
                     : ""
                 }
                 `}
                  label="Password"
                >
                  <PasswordInput
                    placeholder="Password"
                    radius="xs"
                    value={registration.password}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        password: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`
                 ${
                   validateData.confirmpassword === 1
                     ? "Please Enter Conform Password"
                     : validateData.confirmpassword === 2
                     ? "Password and Conform Password are not Same"
                     : ""
                 }
                 `}
                  label="Confirm Password"
                >
                  <PasswordInput
                    placeholder="Confirm Password"
                    radius="xs"
                    value={registration.confirmpassword}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        confirmpassword: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <Input.Wrapper
                error={`
                 ${validateData.address === 1 ? "Please Enter Address" : ""}
                 `}
              >
                <Textarea
                  placeholder="Address"
                  label="Address"
                  radius="xs"
                  autosize
                  minRows={3}
                  value={registration.address}
                  onChange={(e) =>
                    setRegistration({
                      ...registration,
                      address: e.target.value,
                    })
                  }
                />
              </Input.Wrapper>
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                 ${validateData.country === 1 ? "Please Select Country" : ""}
                 `}
                  label="Country"
                >
                  <Select
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    dropdownPosition="top"
                    placeholder="Pick one"
                    searchable
                    nothingFound="No options"
                    data={addressCountries?.map((data) => ({
                      value: data,
                      label: data,
                    }))}
                    value={registration.country}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        country: e,
                        state: "",
                      })
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  error={`
                ${validateData.state === 1 ? "Please Select State" : ""}
                `}
                  label="State"
                >
                  <Select
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    value={registration.state}
                    dropdownPosition="top"
                    placeholder="Pick one"
                    searchable
                    nothingFound="No options"
                    data={selectedListStates[
                      registration.country ? registration.country : "India"
                    ]?.map((data) => ({
                      value: data,
                      label: data,
                    }))}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        state: e,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="registration-div-container-content-form-individual">
                <Input.Wrapper
                  error={`
                 ${
                   validateData.zipcode === 1
                     ? "Please Enter Zip Code"
                     : validateData.zipcode === 2
                     ? "Please Enter Valid Zip Code"
                     : ""
                 }
                 `}
                  label="Zipcode"
                >
                  <Input
                    placeholder="Zipcode"
                    value={registration.zipcode}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        zipcode: e.target.value,
                      })
                    }
                  />
                </Input.Wrapper>
              </div>
              <div className="registration-div-container-content-form-button">
                <button onClick={handleRegisterSubmit}>Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Center pb={"xl"} className="go-back-home">
        <Text component={Link} to={"/"}>
          <Flex align={"center"} gap={'0.5rem'}>
            <ArrowLeft size={"1rem"} />
            Go back to homepage
          </Flex>
        </Text>
      </Center>
    </div>
  );
};

export default Registration;
