import React, { useMemo } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import { Text, Group, Pagination, Select } from "@mantine/core";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import "./UserOrders.css";
//import icons
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getUserOrder } from "../../../config/quries/Orders/OrdersQuery";
import { useDispatch, useSelector } from "react-redux";
import { setUserOrders } from "../../../StateHandler/Slice/UserOrdersSlice/UserOrderSlice";
const UserOrders = () => {
  // Redux Dispatch
  const dispatch = useDispatch();

  // User Order Data
  const userOrdersList = useSelector((state) => state.userOrders.value);

  // React Query Fetch API to Get User Orders
  useQuery(
    ["userOrders", sessionStorage.getItem("MogoUserAccessToken101")],
    getUserOrder,
    {
      onSuccess: (res) => {
        dispatch(setUserOrders(res.data.data.result));
      },
    }
  );
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "User Orders", href: "/your_orders" },
  ];
  // Props Column
  const COLUMN = [
    {
      Header: "Order",
      accessor: "_id",
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: "Amount",
      Cell: ({ row }) => {
        const orderQuantity = row.original.cart_products.map((value) =>
          Number(value.quantity)
        );
        const orderProductPrices = row.original.cart_products.map((value) =>
          Number(value.sale_price)
        );
        let returnValue = [];
        if (orderQuantity.length == orderProductPrices.length) {
          for (let i = 0; i < orderProductPrices.length; i++) {
            returnValue.push(Number(orderProductPrices[i] * orderQuantity[i]));
          }
        }
        let totalAmount = 0;
        for (let i = 0; i < returnValue.length; i++) {
          totalAmount += returnValue[i];
        }
        return totalAmount;
      },
    },
    {
      Header: "Payment",
      accessor: "payment",
      Cell: ({ row }) => {
        const value = row.original.payment.replaceAll("_", " ");
        return value;
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => row.original.order_status,
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        const date = row.original.created;
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Options",
      Cell: ({ row }) => {
        return (
          <div>
            <Text
              component={Link}
              to={`/user_order_individual/${row.original._id}`}
            >
              <button className="sales-button user-orders-button-style">
                Details
              </button>
            </Text>
          </div>
        );
      },
    },
  ];

  // React Table
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => userOrdersList, [userOrdersList]),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { pageIndex } = state;

  // Status Filter

  return (
    <div>
      <div className="user-orders-div">
        <div className="user-orders-div-container">
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="user-orders-div-container-heading">
            <h2>Orders</h2>
          </div>
          <div className="user-orders-div-container-content">
            <div className="payoutrequests-filter-container sales-container">
              <div className="payoutrequests-filter-container-div1">
                <Select
                  label="Show"
                  onChange={(e) => setPageSize(Number(e))}
                  rightSection={
                    <img src={arrowdown} width={10} alt="arrow down icon" />
                  }
                  defaultValue={"10"}
                  data={[
                    { value: "10", label: "10", key: "10" },
                    { value: "20", label: "20", key: "20" },
                    { value: "30", label: "30", key: "30" },
                    { value: "40", label: "40", key: "40" },
                    { value: pageCount * 10, label: "All", key: "All" },
                  ]}
                />
              </div>
            </div>
            <div className="table-style">
              <table {...getTableProps()} className="custum-table-style">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((header) => (
                        <th {...header.getHeaderProps()}>
                          {header.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        Nothing Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="react-table-pagination">
              <Pagination.Root
                total={pageOptions.length}
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={(e) => gotoPage(e - 1)}
              >
                <Group spacing={5} position="center">
                  <Pagination.First
                    disabled={!canPreviousPage}
                    onClick={() => gotoPage(0)}
                  />
                  <Pagination.Previous
                    disabled={!canPreviousPage}
                    onClick={() => {
                      previousPage();
                      gotoPage(pageIndex - 1);
                    }}
                  />
                  <Pagination.Items value={2} />
                  <Pagination.Next
                    disabled={!canNextPage}
                    onClick={() => {
                      nextPage();
                      gotoPage(pageIndex + 1);
                    }}
                  />
                  <Pagination.Last
                    disabled={!canNextPage}
                    onClick={() => gotoPage(pageCount - 1)}
                  />
                </Group>
              </Pagination.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOrders;
