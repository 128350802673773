import React, { useEffect } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";

// Font Awesome Icons
import "font-awesome/css/font-awesome.min.css";

// Tabler Icons
import { Basket, Plus } from "tabler-icons-react";

// Mantine Core
import { Accordion, Avatar, Image, Table } from "@mantine/core";

// Images
import image1 from "../../../assets/home/grid-category/baby_linen.webp";
import image2 from "../../../assets/home/grid-category/bed_linen.webp";
import { useSelector } from "react-redux";

// Server
import config from "../../../config/server/Servers";
import moment from "moment";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Redux Selectors
  const ordersList = useSelector((state) => state.orderList.value);
  const productList = useSelector((state) => state.productList.value);
  const allUsersList = useSelector((state) => state.allUsersList.value);
  const allVendorsList = useSelector((state) => state.allVendorsList.value);

  // Lastest Orders Filter
  const latestOrderFilterList = ordersList.slice().reverse().slice(0, 5);
  const LatestOrdersrows = latestOrderFilterList.map((LatestOrder, index) => {
    const date = moment(new Date(LatestOrder.created)).format("DD-MM-YYYY");
    const orderId = LatestOrder._id.split("").splice(0, 5);
    const totalCartProductPrice = LatestOrder.cart_products.map((value) =>
      Number(value.sale_price)
    );
    const totalQuantityPerProduct = LatestOrder.cart_products.map((value) =>
      Number(value.quantity)
    );
    const resultArray = [];
    if (totalCartProductPrice.length === totalQuantityPerProduct.length) {
      for (let i = 0; i < totalQuantityPerProduct.length; i++) {
        resultArray.push(totalQuantityPerProduct[i] * totalCartProductPrice[i]);
      }
    }
    let sum = 0;
    for (let i = 0; i < resultArray.length; i++) {
      sum += resultArray[i];
    }
    return (
      <tr key={index}>
        <td>#{orderId}</td>
        <td>₹ {sum}</td>
        <td>{LatestOrder.order_status}</td>
        <td>{date}</td>
        <td>
          <Link
            className="admin-dashboard-details-link"
            to={`/individualorder/${LatestOrder._id}`}
          >
            View
          </Link>
        </td>
      </tr>
    );
  });
  const LatestTransactions = [
    {
      Id: "01",
      Order: "#10005",
      PaymentAmount: "₹91",
      PaymentMethod: "	Bank Transfer",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
    {
      Id: "01",
      Order: "#10005",
      PaymentAmount: "₹91",
      PaymentMethod: "	Bank Transfer",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
    {
      Id: "01",
      Order: "#10005",
      PaymentAmount: "₹91",
      PaymentMethod: "	Bank Transfer",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
  ];
  const LatestTransactionsrows = LatestTransactions.map(
    (LatestTransaction, index) => (
      <tr key={index}>
        <td>{LatestTransaction.Id}</td>
        <td>{LatestTransaction.Order}</td>
        <td>{LatestTransaction.PaymentAmount}</td>
        <td>{LatestTransaction.PaymentMethod}</td>
        <td>{LatestTransaction.Status}</td>
        <td>{LatestTransaction.Date}</td>
      </tr>
    )
  );

  //   Filter Latest Products
  const filterProductList = productList.slice().reverse().slice(0, 3);
  const LatestProductsrows = filterProductList.map((LatestProduct, index) => {
    const productID = LatestProduct._id.split("").splice(0, 5);
    return (
      <tr key={index} className="LatestProducts-Table-tr-style">
        <td>#{productID}</td>
        <td className="LatestProducts-Table-td-style">
          <div className="LatestProducts-Table-td-style-image">
            <img
              src={`${config.baseUrlApi}/assets/productImages/${LatestProduct.product_image}`}
              alt=""
            />
          </div>
          <div className="LatestProducts-Table-td-style-title">
            <p>{LatestProduct.name}</p>
            <span>{LatestProduct.CategoryDetails[0].name}</span>
          </div>
        </td>
        <td>
          <Link
            className="admin-dashboard-details-link"
            to={`/productsdetails_individual/${LatestProduct._id}`}
          >
            Details
          </Link>
        </td>
      </tr>
    );
  });
  const LatestPendingProducts = [
    {
      Id: "01",
      Name: "Hygro 100% Cotton Single Duvet Cover-White",
      UploadedTime: "5 months ago",
      image: image1,
      Details: "Details",
      path: "/productsdetails_individual",
    },
    {
      Id: "01",
      Name: "Hygro 100% Cotton Single Duvet Cover-White",
      UploadedTime: "5 months ago",
      image: image1,
      Details: "Details",
      path: "/productsdetails_individual",
    },
  ];
  const LatestPendingProductsrows = LatestPendingProducts.map(
    (LatestPendingProduct, index) => (
      <tr key={index} className="LatestProducts-Table-tr-style">
        <td>{LatestPendingProduct.Id}</td>
        <td className="LatestProducts-Table-td-style">
          <div className="LatestProducts-Table-td-style-image">
            <img src={LatestPendingProduct.image} alt="" />
          </div>
          <div className="LatestProducts-Table-td-style-title">
            <p>{LatestPendingProduct.Name}</p>
            <span>{LatestPendingProduct.Name}</span>
          </div>
        </td>
        <td>
          <Link
            className="admin-dashboard-details-link"
            to={LatestPendingProduct.path}
          >
            {LatestPendingProduct.Details}{" "}
          </Link>
        </td>
      </tr>
    )
  );
  const LatestTransactionsFeatured = [
    {
      Id: "01",
      PaymentMethod: "Bank Transfer",
      PaymentAmount: "₹91",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
    {
      Id: "01",
      PaymentMethod: "Bank Transfer",
      PaymentAmount: "₹91",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
    {
      Id: "01",
      PaymentMethod: "Bank Transfer",
      PaymentAmount: "₹91",
      Status: "Processing",
      Date: "2023-07-28 / 12:44",
    },
  ];
  const LatestTransactionsFeaturedrows = LatestTransactionsFeatured.map(
    (LatestTransactionFeatured, index) => (
      <tr key={index}>
        <td>{LatestTransactionFeatured.Id}</td>
        <td>{LatestTransactionFeatured.PaymentMethod}</td>
        <td>{LatestTransactionFeatured.PaymentAmount}</td>
        <td>{LatestTransactionFeatured.Status}</td>
        <td>{LatestTransactionFeatured.Date}</td>
      </tr>
    )
  );
  const LatestReviews = [
    {
      Id: "01",
      Username: "User 1",
      Review: "This is a good product from best vendor",
    },
    {
      Id: "01",
      Username: "User 1",
      Review: "This is a good product from best vendor",
    },
    {
      Id: "01",
      Username: "User 1",
      Review: "This is a good product from best vendor",
    },
  ];
  const LatestReviewsrows = LatestReviews.map((LatestReview, index) => (
    <tr key={index}>
      <td>{LatestReview.Id}</td>
      <td>{LatestReview.Username}</td>
      <td>{LatestReview.Review}</td>
    </tr>
  ));
  const LatestComments = [
    {
      Id: "01",
      Username: "User 1",
      Comment: "This is a good product from best vendor",
      CommentDate: "5 months ago",
    },
    {
      Id: "01",
      Username: "User 1",
      Comment: "This is a good product from best vendor",
      CommentDate: "5 months ago",
    },
    {
      Id: "01",
      Username: "User 1",
      Comment: "This is a good product from best vendor",
      CommentDate: "5 months ago",
    },
  ];
  const LatestCommentsrows = LatestComments.map((LatestComment, index) => (
    <tr key={index}>
      <td>{LatestComment.Id}</td>
      <td>{LatestComment.Username}</td>
      <td>
        <div className="LatestProducts-Table-td-style-title">
          <p>{LatestComment.Comment}</p>
          <span>{LatestComment.CommentDate}</span>
        </div>
      </td>
    </tr>
  ));

  const latestMembersReturn = () => {
    const filterUserList = allUsersList.slice().reverse().slice(0, 5);
    return (
      <div>
        <div className="latestmembers-container">
          {filterUserList.map((Latestmember, index) => {
            const date = moment(new Date(Latestmember.created)).format(
              "DD-MM-YYYY"
            );
            return (
              <div key={index} className="latestmembers-content">
                {Latestmember.profile_image ? (
                  <div className="latestmembers-content-image">
                    <Image
                      size="xl"
                      src={`${config.baseUrlApi}/assets/userProfile/${Latestmember.profile_image}`}
                      alt="No Profile Image"
                    />
                  </div>
                ) : (
                  <Avatar
                    style={{ borderRadius: "50%" }}
                    size={"100px"}
                    src={null}
                    alt="no image here"
                  />
                )}
                <h4>
                  {Latestmember.first_name} {Latestmember.last_name}
                </h4>
                <p>{date}</p>
              </div>
            );
          })}
        </div>
        <div className="admin-dashboard-show-latest-show-more-main">
          <div className="admin-dashboard-show-latest-show-more">
            <Link to="/users">View All</Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="admin-dashboard">
        <div className="admin-dashboard-show-count-main-div">
          <div className="admin-dashboard-show-count-orders">
            <div className="admin-dashboard-show-count-orders-left">
              <h1>{ordersList.length}</h1>
              <p>Orders</p>
            </div>
            <div className="admin-dashboard-show-count-orders-right">
              <Link to="/orders">
                <i className="fa fa-shopping-cart"></i>
              </Link>
            </div>
          </div>
          <div className="admin-dashboard-show-count-products">
            <div className="admin-dashboard-show-count-products-left">
              <h1>{productList.length}</h1>
              <p>Products</p>
            </div>
            <div className="admin-dashboard-show-count-products-right">
              <Link to="/productsdetails">
                <Basket
                  size={38}
                  strokeWidth={2}
                  className="admin-dashboard-show-count-tabler-icons"
                />
              </Link>
            </div>
          </div>
          <div className="admin-dashboard-show-count-sales-amount">
            <div className="admin-dashboard-show-count-sales-amount-left">
              <h1>5</h1>
              <p>Sales Amount</p>
            </div>
            <div className="admin-dashboard-show-count-sales-amount-right">
              <Link to="/transactions">
                <i className="fa fa-money"></i>
              </Link>
            </div>
          </div>
          <div className="admin-dashboard-show-count-users">
            <div className="admin-dashboard-show-count-users-left">
              <h1>{allUsersList.length}</h1>
              <p>Users</p>
            </div>
            <div className="admin-dashboard-show-count-users-right">
              <Link to="/users">
                <i className="fa fa-users"></i>
              </Link>
            </div>
          </div>
          <div className="admin-dashboard-show-count-vendors">
            <div className="admin-dashboard-show-count-vendors-left">
              <h1>{allVendorsList.length}</h1>
              <p>Vendors</p>
            </div>
            <div className="admin-dashboard-show-count-vendors-right">
              <Link to="/users">
                <i className="fa fa-users"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="admin-dashboard-show-latest">
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Orders"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Orders">
              <Accordion.Control>Latest Orders</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Order</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestOrdersrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/orders">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Transactions"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Transactions">
              <Accordion.Control>Latest Transactions</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>Order</th>
                      <th>Payment Amount</th>
                      <th>Payment Method</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestTransactionsrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/transactions">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Products"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Products">
              <Accordion.Control>Latest Products</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestProductsrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/productsdetails">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Pending Products"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Pending Products">
              <Accordion.Control>Latest Pending Products</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestPendingProductsrows}
                  </tbody>
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Transactions (Featured Products)"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Transactions (Featured Products)">
              <Accordion.Control>
                Latest Transactions <span>(Featured Products)</span>
              </Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>Payment Method</th>
                      <th>Payment Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestTransactionsFeaturedrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/transactions">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Reviews"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Reviews">
              <Accordion.Control>Latest Reviews</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>UserName</th>
                      <th>Review</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestReviewsrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/reviews">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Comments"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Comments">
              <Accordion.Control>Latest Comments</Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover>
                  <thead className="admin-dashboard-show-latest-table-th">
                    <tr>
                      <th>Id</th>
                      <th>UserName</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-show-latest-table-td">
                    {LatestCommentsrows}
                  </tbody>
                </Table>
                <div className="admin-dashboard-show-latest-show-more-main">
                  <div className="admin-dashboard-show-latest-show-more">
                    <Link to="/pending_product_comments">View All</Link>
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <Accordion
            radius="lg"
            variant="separated"
            transitionDuration={1000}
            defaultValue="Latest Members"
            chevron={<Plus size="1rem" />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            <Accordion.Item value="Latest Members">
              <Accordion.Control>Latest Members</Accordion.Control>
              <Accordion.Panel>{latestMembersReturn()}</Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
