import { createSlice } from "@reduxjs/toolkit";
import {
  allVendorsList,
  vendorDataState,
} from "../../InitialState/VendorData/VendorData";

export const vendorDataSlice = createSlice({
  name: "vendorData",
  initialState: vendorDataState,
  reducers: {
    setVendorData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const allVendorsListSlice = createSlice({
  name: "Allvendors",
  initialState: allVendorsList,
  reducers: {
    setAllVendors: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setVendorData } = vendorDataSlice.actions;
export const { setAllVendors } = allVendorsListSlice.actions;
