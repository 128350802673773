//import react packages
import React, { useState, useEffect } from "react";

//import mantine packages
import {
  Checkbox,
  Container,
  Input,
  Rating,
  ScrollArea,
  Select,
  ThemeIcon,
} from "@mantine/core";

//import Breadcrumb component from components->Breadcrumb
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";

//import Category css file
import "./Category.css";

//import image for pagination
import angleleft from "../../../assets/pagination/angleleft.webp";
import angleright from "../../../assets/pagination/angleright.webp";

import { Link, useLocation, useNavigate } from "react-router-dom";

//import react pagination package
import ReactPaginate from "react-paginate";

//import icons
import wishlist from "../../../assets/header/wishlist.webp";
import arrowdown from "../../../assets/preheader/arrow-down.webp";

//import demo images
import NumericInput from "../../../components/UI/Input/NumericInput";

import { useDispatch, useSelector } from "react-redux";
import { listAllProduct } from "../../../config/quries/Products/ProductQuries";
import { setProductList } from "../../../StateHandler/Slice/Products/ProductSlice";
import { useQuery } from "react-query";
import config from "../../../config/server/Servers";
import {
  CircleCheck,
  Heart,
  InfoCircle,
  ShoppingCart,
} from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { setWishList } from "../../../StateHandler/Slice/wishList/WishListSlice";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";

const Category = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const value = {
    "/products": { title: "Products", href: "/products" },
    "/category/table_linen": {
      title: "Table Linen",
      href: "/category/table_linen",
    },
    "/category/kitchen_linen": {
      title: "Kitchen Linen",
      href: "/category/kitchen_linen",
    },
    "/category/bath_linen": {
      title: "Bath Linen",
      href: "/category/bath_linen",
    },
    "/category/bed_linen": { title: "Bed Linen", href: "/category/bed_linen" },
    "/category/home_linen": {
      title: "Living Linen",
      href: "/category/home_linen",
    },
    "/category/baby_linen": {
      title: "Baby Linen",
      href: "/category/baby_linen",
    },
    "/baby_linen/hoodies": { title: "Hoodies", href: "/baby_linen/hoodies" },
    "/baby_linen/blanket": { title: "Blanket", href: "/baby_linen/blanket" },
    "/baby_linen/booties": { title: "Booties", href: "/baby_linen/booties" },
    "/baby_linen/cap": { title: "Cap", href: "/baby_linen/cap" },
    "/baby_linen/head_band": {
      title: "Head Band",
      href: "/baby_linen/head_band",
    },
    "/baby_linen/wrap": { title: "Wrap", href: "/baby_linen/wrap" },
    "/baby_linen/wipes": { title: "Wipes", href: "/baby_linen/wipes" },
    "/baby_linen/gloves": { title: "Gloves", href: "/baby_linen/gloves" },
    "/baby_linen/bathrobe": { title: "Bathrobe", href: "/baby_linen/bathrobe" },
    "/baby_linen/playsuit": { title: "Playsuit", href: "/baby_linen/playsuit" },
    "/baby_linen/sleeping_bag": {
      title: "Sleeping Bag",
      href: "/baby_linen/sleeping_bag",
    },
    "/baby_linen/changing_pad": {
      title: "Changing Pad Cover",
      href: "/baby_linen/changing_pad",
    },
    "/baby_linen/duvet_&_pillow": {
      title: "Duvet & Pillow",
      href: "/baby_linen/duvet_&_pillow",
    },
    "/baby_linen/bib": { title: "Bib", href: "/baby_linen/bib" },
    "/baby_linen/soft_toys": {
      title: "Soft Toys",
      href: "/baby_linen/soft_toys",
    },
    "/baby_linen/feeding_bottle_cover": {
      title: "Feeding Bottle Cover",
      href: "/baby_linen/feeding_bottle_cover",
    },
    "/baby_linen/fitted_sheet": {
      title: "Fitted Sheet",
      href: "/baby_linen/fitted_sheet",
    },
    "/baby_linen/apron": { title: "Apron", href: "/baby_linen/apron" },
    "/baby_linen/bumper": { title: "Bumper", href: "/baby_linen/bumper" },
    "/home_linen/curtains": { title: "Curtains", href: "/home_linen/curtains" },
    "/home_linen/throws": { title: "Throws", href: "/home_linen/throws" },
    "/home_linen/mattress": { title: "Mattress", href: "/home_linen/mattress" },
    "/home_linen/window_panel": {
      title: "Window Panel",
      href: "/home_linen/window_panel",
    },
    "/home_linen/cushion": { title: "Cushion", href: "/home_linen/cushion" },
    "/home_linen/chair_pads": {
      title: "Chair Pads",
      href: "/home_linen/chair_pads",
    },
    "/home_linen/box_cushions": {
      title: "Box Cushions",
      href: "/home_linen/box_cushions",
    },
    "/home_linen/hammocks": { title: "Hammocks", href: "/home_linen/hammocks" },
    "/home_linen/chair_covers": {
      title: "Chair Covers",
      href: "/home_linen/chair_covers",
    },
    "/home_linen/door_stoppers": {
      title: "Door Stoppers",
      href: "/home_linen/door_stoppers",
    },
    "/bed_linen/bed_sheets": {
      title: "Bed Sheets",
      href: "/bed_linen/bed_sheets",
    },
    "/bed_linen/pillowcases": {
      title: "Pillowcases",
      href: "/bed_linen/pillowcases",
    },
    "/bed_linen/duvet_covers": {
      title: "Duvet Covers",
      href: "/bed_linen/duvet_covers",
    },
    "/bed_linen/bedspreads": {
      title: "Bedspreads",
      href: "/bed_linen/bedspreads",
    },
    "/bed_linen/blankets_and_throws": {
      title: "Blankets and Throws",
      href: "/bed_linen/blankets_and_throws",
    },
    "/bed_linen/cushions": { title: "Cushions", href: "/bed_linen/cushions" },
    "/bed_linen/thermal_blankets": {
      title: "Thermal Blankets",
      href: "/bed_linen/thermal_blankets",
    },
    "/bed_linen/quilts": { title: "Quilts", href: "/bed_linen/quilts" },
    "/bed_linen/bolsters": { title: "Bolsters", href: "/bed_linen/bolsters" },
    "/bed_linen/shams": { title: "Shams", href: "/bed_linen/shams" },
    "/bath_linen/bath_towels": {
      title: "Bath Towels",
      href: "/bath_linen/bath_towels",
    },
    "/bath_linen/hand_towels": {
      title: "Hand Towels",
      href: "/bath_linen/hand_towels",
    },
    "/bath_linen/face_towels": {
      title: "Face Towels",
      href: "/bath_linen/face_towels",
    },
    "/bath_linen/bath_mats": {
      title: "Bath Mats",
      href: "/bath_linen/bath_mats",
    },
    "/bath_linen/bathrobes": {
      title: "Bathrobes",
      href: "/bath_linen/bathrobes",
    },
    "/bath_linen/shower_curtains": {
      title: "Shower Curtains",
      href: "/bath_linen/shower_curtains",
    },
    "/bath_linen/towel_racks": {
      title: "Towel Racks",
      href: "/bath_linen/towel_racks",
    },
    "/bath_linen/towel_rings": {
      title: "Towel Rings",
      href: "/bath_linen/towel_rings",
    },
    "/bath_linen/towel_hooks": {
      title: "Towel Hooks",
      href: "/bath_linen/towel_hooks",
    },
    "/kitchen_linen/aprons": { title: "Aprons", href: "/kitchen_linen/aprons" },
    "/kitchen_linen/gloves": { title: "Gloves", href: "/kitchen_linen/gloves" },
    "/kitchen_linen/pot_holders": {
      title: "Pot Holders",
      href: "/kitchen_linen/pot_holders",
    },
    "/kitchen_linen/kitchen_towels": {
      title: "kitchen Towels",
      href: "/kitchen_linen/kitchen_towels",
    },
    "/kitchen_linen/dish_cloths": {
      title: "Dish Cloths",
      href: "/kitchen_linen/dish_cloths",
    },
    "/table_linen/table_cloth": {
      title: "Table Cloth",
      href: "/table_linen/table_cloth",
    },
    "/table_linen/place_mat": {
      title: "Place Mat",
      href: "/table_linen/place_mat",
    },
    "/table_linen/table_runner": {
      title: "Table Runner",
      href: "/table_linen/table_runner",
    },
    "/table_linen/table_napkins": {
      title: "Table Napkins",
      href: "/table_linen/table_napkins",
    },
    "/table_linen/napkins_rings": {
      title: "Napkins Rings",
      href: "/table_linen/napkins_rings",
    },
    "/table_linen/coasters": {
      title: "Coasters",
      href: "/table_linen/coasters",
    },
    "/table_linen/bread_baskets": {
      title: "Bread Baskets",
      href: "/table_linen/bread_baskets",
    },
  };

  const categoryArray = [
    {
      category: "Products",
      link: "/products",
      value: [
        { value: "Table Linen", link: "/category/table_linen" },
        { value: "Kitchen Linen", link: "/category/kitchen_linen" },
        { value: "Bath Linen", link: "/category/bath_linen" },
        { value: "Bed Linen", link: "/category/bed_linen" },
        { value: "Living Linen", link: "/category/home_linen" },
        { value: "Baby Linen", link: "/category/baby_linen" },
      ],
    },
    {
      category: "Table Linen",
      value: [
        { value: "Table Cloth", link: "/table_linen/table_cloth" },
        { value: "Place Mat", link: "/table_linen/place_mat" },
        { value: "Table Runner", link: "/table_linen/table_runner" },
        { value: "Table Napkins", link: "/table_linen/table_napkins" },
        { value: "Napkins Rings", link: "/table_linen/napkins_rings" },
        { value: "Coasters", link: "/table_linen/coasters" },
        { value: "Bread Baskets", link: "/table_linen/bread_baskets" },
      ],
    },
    {
      category: "Kitchen Linen",
      value: [
        { value: "Aprons", link: "/kitchen_linen/aprons" },
        { value: "Gloves", link: "/kitchen_linen/gloves" },
        { value: "Pot Holders", link: "/kitchen_linen/pot_holders" },
        { value: "kitchen Towels", link: "/kitchen_linen/kitchen_towels" },
        { value: "Dish Cloths", link: "/kitchen_linen/dish_cloths" },
      ],
    },
    {
      category: "Bath Linen",
      value: [
        { value: "Bath Towels", link: "/bath_linen/bath_towels" },
        { value: "Hand Towels", link: "/bath_linen/hand_towels" },
        { value: "Face Towels", link: "/bath_linen/face_towels" },
        { value: "Bath Mats", link: "/bath_linen/bath_mats" },
        { value: "Bathrobes", link: "/bath_linen/bathrobes" },
        { value: "Shower Curtains", link: "/bath_linen/shower_curtains" },
        { value: "Towel Racks", link: "/bath_linen/towel_racks" },
        { value: "Towel Rings", link: "/bath_linen/towel_rings" },
        { value: "Towel Hooks", link: "/bath_linen/towel_hooks" },
      ],
    },
    {
      category: "Bed Linen",
      value: [
        { value: "Bed Sheets", link: "/bed_linen/bed_sheets" },
        { value: "Pillowcases", link: "/bed_linen/pillowcases" },
        { value: "Duvet Covers", link: "/bed_linen/duvet_covers" },
        { value: "Bedspreads", link: "/bed_linen/bedspreads" },
        {
          value: "Blankets and Throws",
          link: "/bed_linen/blankets_and_throws",
        },
        { value: "Cushions", link: "/bed_linen/cushions" },
        { value: "Thermal Blankets", link: "/bed_linen/thermal_blankets" },
        { value: "Quilts", link: "/bed_linen/quilts" },
        { value: "Bolsters", link: "/bed_linen/bolsters" },
        { value: "Shams", link: "/bed_linen/shams" },
      ],
    },
    {
      category: "Living Linen",
      value: [
        { value: "Curtains", link: "/home_linen/curtains" },
        { value: "Throws", link: "/home_linen/throws" },
        { value: "Mattress", link: "/home_linen/mattress" },
        { value: "Window Panel", link: "/home_linen/window_panel" },
        { value: "Cushion", link: "/home_linen/cushion" },
        { value: "Chair Pads", link: "/home_linen/chair_pads" },
        { value: "Box Cushions", link: "/home_linen/box_cushions" },
        { value: "Hammocks", link: "/home_linen/hammocks" },
        { value: "Chair Covers", link: "/home_linen/chair_covers" },
        { value: "Door Stoppers", link: "/home_linen/door_stoppers" },
      ],
    },
    {
      category: "Baby Linen",
      value: [
        { value: "Hoodies", link: "/baby_linen/hoodies" },
        { value: "Blanket", link: "/baby_linen/blanket" },
        { value: "Booties", link: "/baby_linen/booties" },
        { value: "Cap", link: "/baby_linen/cap" },
        { value: "Head Band", link: "/baby_linen/head_band" },
        { value: "Wrap", link: "/baby_linen/wrap" },
        { value: "Wipes", link: "/baby_linen/wipes" },
        { value: "Gloves", link: "/baby_linen/gloves" },
        { value: "Bathrobe", link: "/baby_linen/bathrobe" },
        { value: "Playsuit", link: "/baby_linen/playsuit" },
        { value: "Sleeping Bag", link: "/baby_linen/sleeping_bag" },
        { value: "Changing Pad Cover", link: "/baby_linen/changing_pad" },
        { value: "Duvet & Pillow", link: "/baby_linen/duvet_&_pillow" },
        { value: "Bib", link: "/baby_linen/bib" },
        { value: "Soft Toys", link: "/baby_linen/soft_toys" },
        {
          value: "Feeding Bottle Cover",
          link: "/baby_linen/feeding_bottle_cover",
        },
        { value: "Fitted Sheet", link: "/baby_linen/fitted_sheet" },
        { value: "Apron", link: "/baby_linen/apron" },
        { value: "Bumper", link: "/baby_linen/bumper" },
      ],
    },
  ];

  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "Products", href: "/products" },
  ];

  const foundItem = categoryArray.find((category) => {
    return category.value.find((item) => item.link === location.pathname);
  });

  if (foundItem) {
    const categoryValue = foundItem.category;
    const categoryInfo = categoryArray[0].value.find(
      (item) => item.value === categoryValue
    );

    if (categoryInfo) {
      const { value, link } = categoryInfo;
      items.push({ title: value, href: link });
    }
  }

  if (location.pathname !== "/products") {
    items.push(value[location.pathname]);
  }
  const categoryZero = categoryArray[0];

  const foundItemsInCategoryZero = items.filter((item) => {
    return categoryZero.value.some(
      (categoryItem) => categoryItem.link === item.href
    );
  });

  const output = foundItemsInCategoryZero[0]?.title;

  const matchingCategory = categoryArray.find(
    (category) => category.category === output
  );

  const returnValue = items[items.length - 1]?.title;
  const ITEMS_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  //display brand array

  const brandArray = [
    {
      brandname: "Mogo",
    },
    {
      brandname: "Weboney",
    },
  ];
  const brandArray1 = [
    {
      brandname: "Brand 7",
    },
    {
      brandname: "Brand 8",
    },
    {
      brandname: "Brand 9",
    },
    {
      brandname: "Brand 10",
    },
    {
      brandname: "Brand 11",
    },
    {
      brandname: "Brand 12",
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [filterText1, setFilterText1] = useState("");
  const [filteredBrands, setFilteredBrands] = useState(brandArray);
  const [filteredBrands1, setFilteredBrands1] = useState(brandArray1);

  // Function to handle input change
  const handleInputChange = (event) => {
    const searchText = event.target.value;
    setFilterText(searchText);
    const filtered = brandArray.filter((brand) =>
      brand.brandname.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredBrands(filtered);
  };

  //sortby select array
  const sortByArray = [
    { value: "mostrating", label: "Most Recent" },
    { value: "lowestrating", label: "Lowest Price" },
    { value: "hightpricing", label: "Highest Price" },
    { value: "highestrating", label: "Highest Rating" },
  ];

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState();

  // State Handler
  const productList = useSelector((state) => state.productList.value);
  const dispatch = useDispatch();

  // const productList =
  useQuery("productList", listAllProduct, {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      dispatch(setProductList(res?.data?.data?.result));
    },
  });

  const [shuffledProducts, setShuffledProducts] = useState([]);
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return [...array];
  };
  useEffect(() => {
    const pathName = location.pathname.split("/");
    const category = pathName[1];
    const shuffled = shuffleArray(productList);
    if (location.pathname === "/products") {
      setShuffledProducts(shuffled);
    } else if (category === "category") {
      const categoryName = pathName[2].replaceAll("_", " ");
      const filterProducts = shuffled.filter(
        (productsFilter) =>
          productsFilter.CategoryDetails[0].name.toLowerCase() === categoryName
      );
      setShuffledProducts(filterProducts);
    } else if (category !== "category") {
      const categoryName = pathName[2].replaceAll("_", " ");
      const filterProducts = shuffled.filter(
        (productsFilter) =>
          productsFilter.subCategoryDetails[0].name.toLowerCase() ===
          categoryName
      );
      setShuffledProducts(filterProducts);
    }
  }, [productList, location.pathname]);

  const totalPages = Math.ceil(shuffledProducts.length / ITEMS_PER_PAGE);

  // Navigate to Product Page
  const hanldeNavigatePage = (
    categoryName,
    subCategoryName,
    productDetails
  ) => {
    if (categoryName && subCategoryName) {
      navigate(
        `/product/${categoryName}/${subCategoryName}/${productDetails._id}`
      );
    }
  };

  // Add to WishList
  const wishListSessionValue = JSON.parse(sessionStorage.getItem("wishList"));
  const handleAddWishListClick = (e) => {
    if (wishListSessionValue && wishListSessionValue.length > 0) {
      const filterWishlist = wishListSessionValue?.filter(
        (value) => value._id === e._id
      );
      if (filterWishlist && filterWishlist.length > 0) {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="blue">
              <InfoCircle color="blue" />
            </ThemeIcon>
          ),
          message: "Product Already Exists in Wishlist",
        });
      } else {
        const newWishList = [...wishListSessionValue, e];
        sessionStorage.setItem("wishList", JSON.stringify(newWishList));
        dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
        window.location.reload();
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
        //       <CircleCheck color="green" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Added to Wishlist",
        // });
      }
    } else {
      sessionStorage.setItem("wishList", JSON.stringify([e]));
      dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
      window.location.reload();
      // showNotification({
      //   icon: (
      //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
      //       <CircleCheck color="green" />
      //     </ThemeIcon>
      //   ),
      //   message: "Product Added to Wishlist",
      // });
    }
  };

  //Add to Cart
  const cartSessionValue = JSON.parse(sessionStorage.getItem("cartProducts"));
  const handleAddCartClick = (e) => {
    if (cartSessionValue) {
      const filterCart = cartSessionValue.filter(
        (value) => value._id === e._id
      );
      window.location.reload();
      if (filterCart && filterCart.length > 0) {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="blue">
              <InfoCircle color="blue" />
            </ThemeIcon>
          ),
          message: "Product Already Exists in Cart",
        });
      } else {
        const cartProdutwithQuantity = { ...e, quantity: 1 };
        const newCart = [...cartSessionValue, cartProdutwithQuantity];
        sessionStorage.setItem("cartProducts", JSON.stringify(newCart));
        dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
        window.location.reload();
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
        //       <CircleCheck color="green" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Added to Cart",
        // });
      }
    } else {
      const cartProdutwithQuantity = { ...e, quantity: 1 };
      sessionStorage.setItem(
        "cartProducts",
        JSON.stringify([cartProdutwithQuantity])
      );
      dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
      window.location.reload();
      // showNotification({
      //   icon: (
      //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
      //       <CircleCheck color="green" />
      //     </ThemeIcon>
      //   ),
      //   message: "Product Added to Cart",
      // });
    }
  };

  useEffect(() => {
    dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
  }, []);

  //filter rating code starts
  const [rating, setRating] = useState([]);

  const ratingArray = [
    {
      label: "4★ & above",
      value: "4+",
    },
    {
      label: "3★ & above",
      value: "3+",
    },
  ];

  const handleCheckboxChange = (value) => {
    if (rating.includes(value)) {
      setRating(rating.filter((item) => item !== value));
    } else {
      setRating([...rating, value]);
    }
  };
  //filter rating code ends

  //filter discount code starts
  const [discount, setDiscount] = useState([]);
  const dicountArray = [
    {
      label: "50% or more",
      value: "50%+",
    },
    {
      label: "40% or more",
      value: "40%+",
    },
    {
      label: "30% or more",
      value: "30%+",
    },
    {
      label: "20% or more",
      value: "20%+",
    },
    {
      label: "10% or more",
      value: "10%+",
    },
  ];
  const handleCheckboxDiscountChange = (value) => {
    if (discount.includes(value)) {
      setDiscount(discount.filter((item) => item !== value));
    } else {
      setDiscount([...discount, value]);
    }
  };
  //filter discount code ends

  return (
    <div>
      <div className="category-div">
        <Container size={"82rem"} className="category-div-container">
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="category-div-container-heading-sort-by">
            <h1>{returnValue}</h1>
            <div className="category-div-container-main-product-sortby">
              <div className="category-div-container-main-product-sortby-form">
                <div className="category-div-container-main-product-sortby-form-label">
                  <p>Sort By:</p>
                </div>
                <div className="category-div-container-main-product-sortby-form-select">
                  <Select
                    defaultValue={sortByArray[0].value}
                    rightSection={
                      <img src={arrowdown} width="10" alt="arrow down" />
                    }
                    data={sortByArray}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="category-div-container-main">
            <div className="category-div-container-main-filter">
              <div className="category-div-container-main-filter-main">
                <ScrollArea
                  h={225}
                  offsetScrollbars
                  scrollbarSize={6}
                  scrollHideDelay={500}
                >
                  <div
                    className={
                      returnValue !== "Products"
                        ? "category-div-container-main-filter-main-category"
                        : "category-div-container-main-filter-main-category-product"
                    }
                  >
                    <p className="category-div-container-main-filter-main-category-head">
                      Category
                    </p>
                    <div className="category-div-container-main-filter-main-category-content">
                      {location.pathname === "/products" ? (
                        <>
                          {categoryArray.map((category, index) => {
                            if (category.category === returnValue) {
                              return (
                                <div key={index}>
                                  {category.value.map(
                                    (categoryval, innerIndex) => {
                                      return (
                                        <Link
                                          key={innerIndex}
                                          className="category-div-container-main-filter-main-category-content-link"
                                          to={categoryval.link}
                                        >
                                          <p
                                            className={`${
                                              returnValue === categoryval.value
                                                ? "category-div-container-main-filter-main-category-content-link-active"
                                                : ""
                                            }`}
                                            key={innerIndex}
                                          >
                                            {categoryval.value}
                                          </p>
                                        </Link>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </>
                      ) : (
                        <>
                          {matchingCategory &&
                            matchingCategory.value.length > 0 && (
                              <div>
                                {matchingCategory.value.map(
                                  (categoryval, innerIndex) => (
                                    <Link
                                      key={innerIndex}
                                      className="category-div-container-main-filter-main-category-content-link"
                                      to={categoryval.link}
                                    >
                                      <p
                                        className={`${
                                          returnValue === categoryval.value
                                            ? "category-div-container-main-filter-main-category-content-link-active"
                                            : ""
                                        }`}
                                        key={innerIndex}
                                      >
                                        {categoryval.value}
                                      </p>
                                    </Link>
                                  )
                                )}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </ScrollArea>
                <hr />
                {returnValue !== "Products" ? (
                  <>
                    <div className="category-div-container-main-filter-main-brand">
                      <p className="category-div-container-main-filter-main-category-head">
                        Brand
                      </p>
                      <div className="category-div-container-main-filter-main-brand-form-display">
                        <div className="category-div-container-main-filter-main-brand-form-display-form">
                          <Input
                            value={filterText}
                            onChange={handleInputChange}
                            placeholder="Search Brand"
                          />
                        </div>
                        <ScrollArea
                          h={"auto"}
                          mah={225}
                          offsetScrollbars
                          scrollbarSize={6}
                          scrollHideDelay={500}
                        >
                          <div className="category-div-container-main-filter-main-brand-form-display-display">
                            {filteredBrands.map((brand, index) => (
                              <Checkbox key={index} label={brand.brandname} />
                            ))}
                          </div>
                        </ScrollArea>
                      </div>
                    </div>
                    <hr />
                    <div className="category-div-container-main-filter-main-price">
                      <p className="category-div-container-main-filter-main-category-head">
                        Price
                      </p>
                      <div className="category-div-container-main-filter-main-price-form">
                        <div className="category-div-container-main-filter-main-price-form-min-input">
                          <NumericInput
                            value={minValue}
                            onChange={setMinValue}
                            label="Min"
                          />
                        </div>
                        <div className="category-div-container-main-filter-main-price-form-max-input">
                          <NumericInput
                            value={maxValue}
                            onChange={setMaxValue}
                            label="Max"
                          />
                        </div>
                        <div className="category-div-container-main-filter-main-price-form-button">
                          <button>
                            <img
                              src={angleright}
                              width="50"
                              alt="angle right icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="category-div-container-main-filter-main-rating">
                      <p className="category-div-container-main-filter-main-category-head">
                        Rating
                      </p>
                      <div className="category-div-container-main-filter-main-rating-content">
                        {ratingArray.map((ratingArr, index) => (
                          <Checkbox
                            key={index}
                            value={ratingArr.value}
                            label={ratingArr.label}
                            onChange={() =>
                              handleCheckboxChange(ratingArr.value)
                            }
                            checked={rating.includes(ratingArr.value)}
                          />
                        ))}
                      </div>
                    </div>
                    <hr />
                    <div className="category-div-container-main-filter-main-discount">
                      <p className="category-div-container-main-filter-main-category-head">
                        Discount
                      </p>
                      <div className="category-div-container-main-filter-main-discount-content">
                        {dicountArray.map((discountArr, index) => (
                          <Checkbox
                            key={index}
                            value={discountArr.value}
                            label={discountArr.label}
                            onChange={() =>
                              handleCheckboxDiscountChange(discountArr.value)
                            }
                            checked={discount.includes(discountArr.value)}
                          />
                        ))}
                      </div>
                    </div>
                    <hr />
                  </>
                ) : (
                  <>
                    <div className="category-div-container-main-filter-main-price">
                      <p className="category-div-container-main-filter-main-category-head">
                        Price
                      </p>
                      <div className="category-div-container-main-filter-main-price-form">
                        <div className="category-div-container-main-filter-main-price-form-min-input">
                          <NumericInput
                            value={minValue}
                            onChange={setMinValue}
                            label="Min"
                          />
                        </div>
                        <div className="category-div-container-main-filter-main-price-form-max-input">
                          <NumericInput
                            value={maxValue}
                            onChange={setMaxValue}
                            label="Max"
                          />
                        </div>
                        <div className="category-div-container-main-filter-main-price-form-button">
                          <button>
                            <img
                              src={angleright}
                              width="50"
                              alt="angle right icon"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="category-div-container-main-filter-main-rating">
                      <p className="category-div-container-main-filter-main-category-head">
                        Rating
                      </p>
                      <div className="category-div-container-main-filter-main-rating-content">
                        <Checkbox
                          value="4+"
                          label="4★ & above"
                          onChange={() => handleCheckboxChange("4+")}
                          checked={rating.includes("4+")}
                        />
                        <Checkbox
                          value="3+"
                          label="3★ & above"
                          onChange={() => handleCheckboxChange("3+")}
                          checked={rating.includes("3+")}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="category-div-container-main-filter-main-discount">
                      <p className="category-div-container-main-filter-main-category-head">
                        Discount
                      </p>
                      <div className="category-div-container-main-filter-main-discount-content">
                        <Checkbox
                          value="50%+"
                          label="50% or more"
                          onChange={() => handleCheckboxDiscountChange("50%+")}
                          checked={discount.includes("50%+")}
                        />
                        <Checkbox
                          value="40%+"
                          label="40% or more"
                          onChange={() => handleCheckboxDiscountChange("40%+")}
                          checked={discount.includes("40%+")}
                        />
                        <Checkbox
                          value="30%+"
                          label="30% or more"
                          onChange={() => handleCheckboxDiscountChange("30%+")}
                          checked={discount.includes("30%+")}
                        />
                        <Checkbox
                          value="20%+"
                          label="20% or more"
                          onChange={() => handleCheckboxDiscountChange("20%+")}
                          checked={discount.includes("20%+")}
                        />
                        <Checkbox
                          value="10%+"
                          label="10% or more"
                          onChange={() => handleCheckboxDiscountChange("10%+")}
                          checked={discount.includes("10%+")}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </div>
            </div>
            <div className="category-div-container-main-product">
              <div className="category-div-container-main-product-product_display">
                {shuffledProducts
                  // .filter(
                  //   (value) =>
                  //     value.sale_price >= minValue &&
                  //     value.sale_price <= maxValue
                  // )
                  .map((productDetails, index) => {
                    const categoryName =
                      productDetails.CategoryDetails[0].name.replaceAll(
                        " ",
                        "_"
                      );
                    const subCategoryName =
                      productDetails.subCategoryDetails[0].name.replaceAll(
                        " ",
                        "_"
                      );
                    return (
                      <div
                        key={index}
                        className="category-div-container-main-product-product_display-individual"
                      >
                        <div className="category-div-container-main-product-product_display-individual-image">
                          <img
                            onClick={() =>
                              hanldeNavigatePage(
                                categoryName,
                                subCategoryName,
                                productDetails
                              )
                            }
                            src={`${config.baseUrlApi}/assets/productImages/${productDetails.product_image}`}
                            alt="first product"
                          />
                          <img
                            onClick={() =>
                              hanldeNavigatePage(
                                categoryName,
                                subCategoryName,
                                productDetails
                              )
                            }
                            src={`${config.baseUrlApi}/assets/productImages/${productDetails.product_gallery_image[1]}`}
                            id="category_second_image"
                            alt="second product"
                          />
                          {productDetails.featured !== "" ? (
                            <div className="category-div-container-main-product-product_display-individual-image-featured">
                              <p>{`${
                                productDetails.featured
                                  ? productDetails.featured
                                  : "34%"
                              }`}</p>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="category-div-container-main-product-product_display-individual-image-cart-wishlist">
                            <div className="category-div-container-main-product-product_display-individual-image-cart-wishlist-cart">
                              <ShoppingCart
                                onClick={() =>
                                  handleAddCartClick(productDetails)
                                }
                                strokeWidth={1}
                              />
                            </div>
                            <div className="category-div-container-main-product-product_display-individual-image-cart-wishlist-wishlist">
                              <Heart
                                onClick={() =>
                                  handleAddWishListClick(productDetails)
                                }
                                strokeWidth={1}
                              />
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                        <div className="category-div-container-main-product-product_display-individual-content">
                          <div className="category-div-container-main-product-product_display-individual-heading">
                            <p>{productDetails.name}</p>
                          </div>
                          <div className="category-div-container-main-product-product_display-individual-shop-name">
                            <p>Mogo</p>
                          </div>
                          <div className="category-div-container-main-product-product_display-individual-rating-heart">
                            <div className="category-div-container-main-product-product_display-individual-rating-heart-rating">
                              <Rating value={5} readOnly />
                            </div>
                            <div className="category-div-container-main-product-product_display-individual-rating-heart-heart">
                              <div>
                                <img src={wishlist} width={14} alt="" />
                              </div>
                              {/* <div>{productDetails.like}</div> */}
                            </div>
                          </div>
                          <div className="category-div-container-main-product-product_display-individual-price">
                            {productDetails.oldprice !== "" ? (
                              <p className="oldprice">
                                ₹
                                {`${
                                  productDetails.actual_price
                                    ? productDetails.actual_price
                                    : 299
                                }`}
                              </p>
                            ) : (
                              ""
                            )}
                            <p>
                              {" "}
                              ₹
                              {`${
                                productDetails.sale_price
                                  ? productDetails.sale_price
                                  : 199
                              }`}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={
                    <img
                      src={angleleft}
                      width="20"
                      alt="pagination left icon"
                    />
                  }
                  nextLabel={
                    <img
                      src={angleright}
                      width="20"
                      alt="pagination right icon"
                    />
                  }
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  forcePage={currentPage}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousClassName={currentPage === 0 ? "disabled" : ""}
                  nextClassName={
                    currentPage === totalPages - 1 ? "disabled" : ""
                  }
                  disabledClassName={"disabled"}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Category;
