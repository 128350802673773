import axios from "axios";
import config from "../../server/Servers";

const token = sessionStorage.getItem("MogoUserAccessToken102");
const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export const listAddressByUserID = ({ queryKey }) => {
  const [_, id] = queryKey;
  return axios.get(
    `${config.baseUrlApi}/address/useraddress/${id}`,
    commonHeader
  );
};

export const CreateUserAddress = (payload) => {
  return axios.post(
    `${config.baseUrlApi}/address/create`,
    payload,
    commonHeader
  );
};

export const deleteUserAddress = (id) => {
  return axios.delete(
    `${config.baseUrlApi}/address/delete/${id}`,
    commonHeader
  );
};

export const updateUserAddress = (payload) => {
  const { e, body } = payload;
  return axios.put(
    `${config.baseUrlApi}/address/update/${e}`,
    body,
    commonHeader
  );
};

export const getUserAddressByID = ({ queryKey }) => {
  return axios.get(
    `${config.baseUrlApi}/address/getuseraddress/${queryKey[1]}`,
    commonHeader
  );
};

export const listAllAddresses = () => {
  return axios.get(
    `
    ${config.baseUrlApi}/address
    `,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(
          "MogoAdminAccessToken102"
        )}`,
      },
    }
  );
};
