import { createSlice } from "@reduxjs/toolkit";
import {
  loginModalState,
  loginState,
} from "../../InitialState/Login/LoginState";

export const loginSlice = createSlice({
  name: "loginStatus",
  initialState: loginState,
  reducers: {
    setLoginStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const loginModalSlice = createSlice({
  name: "LoginModal",
  initialState: loginModalState,
  reducers: {
    setLoginModalOpen: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLoginStatus } = loginSlice.actions;
export const { setLoginModalOpen } = loginModalSlice.actions;
