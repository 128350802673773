import axios from "axios";
import config from "../../server/Servers";

const token = sessionStorage.getItem("MogoUserAccessToken102");
const adminToken = sessionStorage.getItem("MogoAdminAccessToken102");
const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export const createOrders = (body) => {
  return axios.post(`${config.baseUrlApi}/orders/create`, body, commonHeader);
};

export const getUserOrder = ({ queryKey }) => {
  const { _, id } = queryKey;
  return axios.get(`${config.baseUrlApi}/orders/getuserorder/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOrderByID = ({ queryKey }) => {
  const { _, id } = queryKey;
  return axios.get(`${config.baseUrlApi}/orders/getorderbyid/${queryKey[1]}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const allOrders = () => {
  return axios.get(`${config.baseUrlApi}/orders`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    },
  });
};

export const getOrderByIDByAdmin = ({ queryKey }) => {
  return axios.get(
    `${config.baseUrlApi}/orders/getorderbyidbyadmin/${queryKey[1]}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(
          "MogoAdminAccessToken102"
        )}`,
      },
    }
  );
};
