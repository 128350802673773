import React, { useMemo, useState } from "react";
import { Button, Card, Group, Input, Modal, Pagination, Select, Text } from "@mantine/core";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import plus from "../../../../assets/admin/table/dropdown/plus.png";
import arrowdown from "../../../../assets/preheader/arrow-down.webp";
import "./WithdrawalHistory.css";
import ReactDatePicker from "react-datepicker";
const WithdrawalHistory = () => {

  const accountType = [
    { value: "Savings", label: "Savings" },
    { value: "Current", label: "Current" },
  ];

  const [bankDetails, setBankDetails] = useState({
    accountholder: "",
    accounttype: "",
  });

  const COLUMN = [
    {
      Header: "S.No",
      accessor: "sno",
    },
    {
      Header: "Account",
      accessor: "Account",
    },
    {
      Header: "Withdrawl Amount",
      accessor: "Withdrawl_Amount",
    },
    {
      Header: "Status",
      accessor: "Status",
    },
    {
      Header: "Date",
      accessor: "Date",
    },
  ];

  const tableData = [
    {
      sno: 1,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "8/31/2023",
    },
    {
      sno: 2,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "9/24/2023",
    },
    {
      sno: 3,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Payment: "Payment Received",
      Status: "Completed",
      Date: "5/20/2023",
    },
    {
      sno: 4,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Payment: "Payment Received",
      Status: "Completed",
      Date: "9/30/2023",
    },
    {
      sno: 5,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "2/14/2023",
    },
    {
      sno: 6,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "11/16/2022",
    },
    {
      sno: 7,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "9/25/2023",
    },
    {
      sno: 8,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "4/20/2023",
    },
    {
      sno: 9,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "4/8/2023",
    },
    {
      sno: 10,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "4/26/2023",
    },
    {
      sno: 11,
      Account: "Bank",
      Withdrawl_Amount: "₹2000",
      Status: "Completed",
      Date: "8/14/2023",
    },
  ];

  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => tableData, []),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Status Filter
  const [selectedStatus, setSelectedStatus] = useState("All");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const handleDateChange = (update) => {
    if (Array.isArray(update) && update.length === 2) {
      const [newStartDate, newEndDate] = update;

      if (newStartDate && newEndDate) {
        const formattedStartDate = newStartDate.toLocaleDateString("en-US");
        const formattedEndDate = newEndDate.toLocaleDateString("en-US");
        const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setFormattedDateRange(formattedDateRange);
      } else {
        setFormattedDateRange("");
      }
    } else {
      setSelectedDate(update);
    }
  };

  return (
    <div>
      <div className="bank-details-div">
        <Card className="min-height-90vh bank-details-div-card">
          <div className="bank-details-div-card-heading">
            <h2>Withdrawal History</h2>
          </div>
          <div className="bank-details-div-card-content">
            <div>
              <div className="payoutrequests-filter-container sales-container">
                <div className="payoutrequests-filter-container-div1">
                  <Select
                    label="Show"
                    onChange={(e) => setPageSize(Number(e))}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"10"}
                    data={[
                      { value: "10", label: "10", key: "10" },
                      { value: "20", label: "20", key: "20" },
                      { value: "30", label: "30", key: "30" },
                      { value: "40", label: "40", key: "40" },
                      { value: pageCount * 10, label: "All", key: "All" },
                    ]}
                  />
                </div>
                <div className="payoutrequests-filter-container-div4">
                  <Input.Wrapper label="Search" maw={320} mx="auto">
                    <Input
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Filter"
                    />
                  </Input.Wrapper>
                </div>
                <div className="payoutrequests-filter-container-div5">
                  <Input.Wrapper label="Date" maw={320} mx="auto">
                    <ReactDatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                        handleDateChange(update);
                      }}
                      isClearable={true}
                      maxDate={currentDate}
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="table-style">
                <table {...getTableProps()} className="custum-table-style">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((header) => (
                          <th {...header.getHeaderProps()}>
                            {header.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page
                        .filter(
                          (row) =>
                            selectedStatus === "All" ||
                            row.values.Payment === selectedStatus
                        )
                        .map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          Nothing Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="react-table-pagination">
                <Pagination.Root
                  total={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  value={pageIndex + 1}
                  onChange={(e) => gotoPage(e - 1)}
                >
                  <Group spacing={5} position="center">
                    <Pagination.First
                      disabled={!canPreviousPage}
                      onClick={() => gotoPage(0)}
                    />
                    <Pagination.Previous
                      disabled={!canPreviousPage}
                      onClick={() => {
                        previousPage();
                        gotoPage(pageIndex - 1);
                      }}
                    />
                    <Pagination.Items value={2} />
                    <Pagination.Next
                      disabled={!canNextPage}
                      onClick={() => {
                        nextPage();
                        gotoPage(pageIndex + 1);
                      }}
                    />
                    <Pagination.Last
                      disabled={!canNextPage}
                      onClick={() => gotoPage(pageCount - 1)}
                    />
                  </Group>
                </Pagination.Root>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default WithdrawalHistory;
