import { Card } from "@mantine/core";
import React from "react";
import "./UserIndividual.css";
const UserIndividual = () => {
  return (
    <div>
      <div className="userindividual-div">
        <Card className="userindividual-div-card min-height-90vh">
          <div className="userindividual-div-card-heading">
            <h4>View User Details</h4>
          </div>
          <div className="userindividual-div-card-content">
            <div className="userindividual-div-card-content-user-image">
              <div className="userindividual-div-card-content-user-image-container">
                <img alt="user" />
              </div>
            </div>
            <div className="userindividual-div-card-content-details">
              <ul className="userindividual-div-card-content-details-heading">
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email</li>
                <li>Mobile Number</li>
                <li>Full Address</li>
                <li>Last Login</li>
                <li>Status</li>
              </ul>
              <ul className="userindividual-div-card-content-details-content">
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email</li>
                <li>Mobile Number</li>
                <li>Full Address</li>
                <li>Last Login</li>
                <li>Status</li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UserIndividual;
