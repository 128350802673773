//import react package
import React, { useEffect, useState } from "react";

//import manitine packages
import { Avatar, Button, Center, Checkbox, Container, Flex, Group, Input, Menu, Modal, PasswordInput, Select, Text, ThemeIcon } from "@mantine/core";

//import react router dom packages
import { Link } from "react-router-dom";

//import Preheader css file
import "./Preheader.css";

//import images for preheader
import map from "../../../assets/preheader/map.webp";
import { ChevronDown, CircleCheck, MapPin, X } from "tabler-icons-react";

import { handleLoginControl, handleRegisterControl } from "../../../controller/loginAuth/userLogin/userLoginAuth";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { findUserByid } from "../../../config/quries/users/usersQuery";
import { setUserData } from "../../../StateHandler/Slice/UserSlice/UserSliceData";
import config from "../../../config/server/Servers";
import { useMediaQuery } from "@mantine/hooks";
import { setLoginModalOpen } from "../../../StateHandler/Slice/Login/LoginSlice";
import axios from "axios";
import { showNotification } from "@mantine/notifications";

const Preheader = () => {
  const [ForgotPassword, setForgotPassword] = useState({
    email: "",
    otp: "",
    newPassword: "",
    conformPassword: "",
    emailSend: false,
    otpReceived: false,
    button: false,
    user_id: "",
  });
  const [ForgotPasswordValidation, setForgotPasswordValidation] = useState({
    email: 0,
    otp: 0,
    newPassword: 0,
    conformPassword: 0,
  });

  const loginModalStatus = useSelector((state) => state.loginModalStatus.value);
  const dispatch = useDispatch();
  //currency array
  const currencyArray = [
    {
      value: "inr",
      label: "INR (₹)",
    },
    {
      value: "usd",
      label: "USD ($)",
    },
    {
      value: "eur",
      label: "EUR (€)",
    },
  ];

  //usestate for open location modal
  const [modalOpen, setModalOpen] = useState(false);
  //usestate for open register modal
  const [registermodalOpen, setRegisterModalOpen] = useState(false);
  //usestate for open login modal
  // const [loginmodalOpen, setLoginModalOpen] = useState(loginModalStatus);

  //usestate for open login modal
  const [forgotmodalOpen, setForgotModalOpen] = useState(false);
  const [locationField, setLocationField] = useState("");

  // Langage
  const languageState = [
    {
      value: "india",
      label: "English",
    },
    {
      value: "tamil",
      label: "Tamil",
    },
    {
      value: "hindi",
      label: "Hindi",
    },
  ];

  const [valueIcon, setValueIcon] = useState("india");

  const filterData = languageState.filter((language) => language.value === valueIcon);

  //Language Select Items
  const SelectItem = React.forwardRef(({ icon, label, value, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  ));

  // Register User Values
  const [userRegisterValue, setUserRegisterValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    number: "",
    conform_password: "",
  });

  const [userLogin, setuserLogin] = useState({
    email: "",
    password: "",
  });

  // Validate Login User
  const [userLoginValidation, setUserLoginValidation] = useState({
    email: 0,
    password: 0,
  });

  // Validation For Register Values
  const [validateUserRegisterValue, setValidateUserRegisterValue] = useState({
    first_name: 0,
    last_name: 0,
    email: 0,
    password: 0,
    conform_password: 0,
    check_box: "",
    number: 0,
  });

  // Handle Event
  const handleRegisterClick = () => {
    handleRegisterControl(userRegisterValue, setUserRegisterValue, validateUserRegisterValue, setValidateUserRegisterValue, setRegisterModalOpen);
  };

  // Validating Input Fields
  useEffect(() => {
    if (userRegisterValue.first_name.trim()) {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        first_name: 0,
      });
    }
    if (userRegisterValue.last_name.trim()) {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        last_name: 0,
      });
    }
    if (userRegisterValue.email.trim()) {
      setValidateUserRegisterValue({ ...validateUserRegisterValue, email: 0 });
    }
    if (userRegisterValue.password.trim()) {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        password: 0,
      });
    }
    if (userRegisterValue.conform_password.trim()) {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        conform_password: 0,
      });
    }
    if (userRegisterValue.number.trim()) {
      setValidateUserRegisterValue({
        ...validateUserRegisterValue,
        number: 0,
      });
    }
  }, [userRegisterValue]);

  const userData = useSelector((state) => state.userData.value);
  const queryClient = useQueryClient();

  // Fetching User By ID
  useQuery(["userData", sessionStorage.getItem("MogoUserAccessToken101")], findUserByid, {
    refetchOnWindowFocus: false,
    enabled: !!sessionStorage.getItem("MogoUserAccessToken101"),
    onSuccess: (res) => {
      dispatch(setUserData(res?.data?.data));
    },
  });

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(setUserData(""));
    window.location.reload();
  };

  const handleLogin = () => {
    handleLoginControl(userLogin, setuserLogin, userLoginValidation, setUserLoginValidation, dispatch, queryClient);
  };

  // Handle Your Account
  const handleYourAccount = (value) => {
    dispatch(setLoginModalOpen(value));
  };

  const preheader450MediaQuery = useMediaQuery("(max-width:550px)");

  useEffect(() => {
    if (userLogin.email) {
      setUserLoginValidation({
        ...userLoginValidation,
        email: 0,
      });
    }
    if (userLogin.password) {
      setUserLoginValidation({
        ...userLoginValidation,
        password: 0,
      });
    }
  }, [userLogin]);

  useEffect(() => {
    setUserLoginValidation({
      ...userLoginValidation,
      email: 0,
      password: 0,
    });
    setValidateUserRegisterValue({
      ...validateUserRegisterValue,
      first_name: 0,
      last_name: 0,
      email: 0,
      password: 0,
      conform_password: 0,
      check_box: "",
      number: 0,
    });
  }, [loginModalStatus]);

  // handle Send OTP
  const handleChange = () => {
    const body = {
      email: ForgotPassword.email,
    };
    if (ForgotPassword.emailSend) {
      if (ForgotPassword.otp) {
        setForgotPassword((previous) => ({
          ...previous,
          otpReceived: true,
        }));
      } else {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Please Enter OTP",
        });
      }
    } else {
      if (ForgotPassword.email.trim()) {
        if (ForgotPassword.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          setForgotPassword((previous) => ({
            ...previous,
            emailSend: true,
          }));
          axios
            .post(`${config.baseUrlApi}/users/resetpassword`, body)
            .then((re) => {
              if (re.data.data.result === "Email Not Found") {
                setForgotPassword((previous) => ({
                  ...previous,
                  emailSend: false,
                }));
                setForgotPasswordValidation((previous) => ({
                  ...previous,
                  email: 3,
                }));
                showNotification({
                  icon: (
                    <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                      <X color="red" />
                    </ThemeIcon>
                  ),
                  message: "Email doesn't Exists",
                });
              }
              if (re.data.data.result === "OTP Send Successfully") {
                setForgotPassword((previous) => ({
                  ...previous,
                  button: true,
                }));
                setForgotPassword((previous) => ({
                  ...previous,
                  emailSend: true,
                }));
              }
            })
            .catch((err) => {});
        } else {
          setForgotPasswordValidation((previous) => ({
            ...previous,
            email: 2,
          }));
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            message: "Please Enter Valid Email",
          });
        }
      } else {
        setForgotPasswordValidation((previous) => ({
          ...previous,
          email: 1,
        }));
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Please Enter Email",
        });
      }
    }
  };

  // verify OTP
  const handleVerifyOTP = () => {
    const body = {
      otp: ForgotPassword.otp,
    };
    axios
      .post(`${config.baseUrlApi}/users/verifyotp`, body)
      .then((res) => {
        setForgotPassword((previous) => ({
          ...previous,
          user_id: res?.data?.data?.result?._id,
        }));
      })
      .catch((err) => {
        setForgotPasswordValidation((previous) => ({
          ...previous,
          otp: 1,
        }));
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Invalid OTP",
        });
      });
  };

  // useEffect
  useEffect(() => {
    if (ForgotPassword.emailSend) {
      setForgotPassword((previous) => ({
        ...previous,
        button: false,
      }));
    }
    if (ForgotPassword.email.trim()) {
      setForgotPasswordValidation((previous) => ({
        ...previous,
        email: 0,
      }));
    }
  }, [ForgotPassword]);

  useEffect(() => {
    setForgotPassword((previous) => ({
      ...previous,
      email: "",
      otp: "",
      newPassword: "",
      conformPassword: "",
      emailSend: false,
      otpReceived: false,
      button: false,
    }));
    setForgotPasswordValidation((previous) => ({
      ...previous,
      email: 0,
      otp: 0,
      newPassword: 0,
      conformPassword: 0,
    }));
  }, [forgotmodalOpen]);
  const handleResetPassword = () => {
    const body = {
      password: ForgotPassword.newPassword,
    };
    if (ForgotPassword.newPassword.trim()) {
      if (ForgotPassword.conformPassword.trim()) {
        if (ForgotPassword.newPassword.trim() === ForgotPassword.conformPassword.trim()) {
          axios
            .put(`${config.baseUrlApi}/users/passwordreset/${ForgotPassword.user_id}`, body)
            .then((res) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                    <CircleCheck color="green" />
                  </ThemeIcon>
                ),
                message: "Password Changed Successfully",
              });
              window.location.reload();
            })
            .catch((err) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Unable to update Password",
              });
            });
        } else {
          setForgotPasswordValidation((previous) => ({
            ...previous,
            conformPassword: 2,
          }));
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            message: "New Password and Confirm Password Must be Same",
          });
        }
      } else {
        setForgotPasswordValidation((previous) => ({
          ...previous,
          conformPassword: 1,
        }));
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Please Enter Conform Password",
        });
      }
    } else {
      setForgotPasswordValidation((previous) => ({
        ...previous,
        conformPassword: 1,
      }));
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Please Enter New Password",
      });
    }
  };

  return (
    <div>
      <div className="preheader">
        <Container size={"82rem"} className="preheader-container">
          <div className="preheader-container-contact-sell">
            <div className="preheader-container-contact-sell-contact">
              <Text component={Link} to="/contact">
                <p>Contact</p>
              </Text>
            </div>
            {/* <div className="preheader-container-contact-sell-sell">
              <Text component={Link} to="/sellonmogo">
                <p>Sell on Mogo</p>
              </Text>
            </div> */}
          </div>
          <div className="preheader-container-location-currency-user">
            <div onClick={() => setModalOpen(true)} className="preheader-container-location-currency-user-location">
              {/* <div className="preheader-container-location-currency-user-location-image">
                <img src={map} alt="Loaction" />
              </div> */}
              <div className="preheader-container-location-currency-user-location-text">
                <MapPin strokeWidth={1} size={preheader450MediaQuery ? "0.8rem" : "1.1rem"} />

                <p>Location</p>
              </div>
            </div>
            <Select
              zIndex={1234568}
              defaultValue="india"
              itemComponent={SelectItem}
              value={languageState.label}
              className="lang-select"
              rightSection={
                <ChevronDown
                  color="white"
                  // color='rgb(168, 147, 135)'
                  strokeWidth={1}
                  size={preheader450MediaQuery ? "0.8rem" : "1.2rem"}
                />
              }
              onChange={(e) => setValueIcon(e)}
              icon={filterData[0].icon}
              data={languageState}
              transitionProps={{
                transition: "pop-top-left",
                duration: 80,
                timingFunction: "ease",
              }}
            />
            <div className="preheader-container-location-currency-user-currency">
              <Select zIndex={1234568} defaultValue={currencyArray[0].value} rightSection={<ChevronDown color="white" strokeWidth={1} size={preheader450MediaQuery ? "0.8rem" : "1.2rem"} />} data={currencyArray} />
            </div>
            <Menu zIndex={1234568}>
              <Menu.Target>
                <Flex style={{ cursor: "pointer" }} align={"center"} gap={"0.4rem"}>
                  <Avatar size={"1.5rem"} radius="lg" src={userData.profile_image ? `${config.baseUrlApi}/assets/userProfile/${userData.profile_image}` : ""} />
                  <p>{userData.first_name ? userData.first_name : " Login"}</p>
                  <ChevronDown
                    color="white"
                    //  color='rgb(168, 147, 135)'
                    strokeWidth={1}
                    size={preheader450MediaQuery ? "0.8rem" : "1.2rem"}
                  />
                </Flex>
              </Menu.Target>
              <Menu.Dropdown>
                <div className="preheader-container-location-currency-user-user-dropdown">
                  {userData.first_name ? (
                    <p>
                      <Center color="black" p="lg">
                        {userData.first_name} {userData.last_name}
                      </Center>
                    </p>
                  ) : (
                    <div className="preheader-container-location-currency-user-user-dropdown-signin">
                      <Menu.Item className="preheader-container-location-currency-user-user-dropdown-signin-button" onClick={() => handleYourAccount(true)}>
                        Signin
                      </Menu.Item>
                      <p onClick={() => setRegisterModalOpen(true)}>
                        <Menu.Item p={0} className="preheader-container-location-currency-user-user-dropdown-signin-p">
                          New Customer ? &nbsp;
                          <span>Start here</span>
                        </Menu.Item>
                      </p>
                    </div>
                  )}
                  <hr />
                  <div className="preheader-container-location-currency-user-user-dropdown-extra">
                    <p>
                      {sessionStorage.getItem("MogoUserAccessToken101") ? (
                        <Menu.Item component={Link} to={"/your_account"} className="navbar-link user-dropdown-link">
                          Your Account
                        </Menu.Item>
                      ) : (
                        <Menu.Item onClick={() => handleYourAccount(true)} className="navbar-link user-dropdown-link">
                          Your Account
                        </Menu.Item>
                      )}
                    </p>
                    <p>
                      <Menu.Item component={Link} to={"/ui_quote"} className="navbar-link user-dropdown-link">
                        Quote Requests
                      </Menu.Item>
                    </p>
                    <p>
                      {sessionStorage.getItem("MogoUserAccessToken101") ? (
                        <Menu.Item component={Link} to={"/your_orders"} className="navbar-link user-dropdown-link">
                          Your Orders
                        </Menu.Item>
                      ) : (
                        <Menu.Item onClick={() => handleYourAccount(true)} className="navbar-link user-dropdown-link">
                          Your Orders
                        </Menu.Item>
                      )}
                    </p>
                    {/* <p>
                      <Menu.Item
                        component={Link}
                        to={"/vendor"}
                        className="navbar-link user-dropdown-link"
                      >
                        Seller Account
                      </Menu.Item>
                    </p> */}
                    {/* <p>
                      <Link to="/vendor_registration" className="navbar-link">
                        <Menu.Item
                          component="span"
                          className="navbar-link user-dropdown-link"
                        >
                          Become Seller
                        </Menu.Item>
                      </Link>
                    </p> */}
                    {userData.first_name && (
                      <p onClick={handleLogout}>
                        <Menu.Item className="navbar-link user-dropdown-link">Log out</Menu.Item>
                      </p>
                    )}
                  </div>
                </div>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Container>
      </div>

      {/* model starts */}
      <Modal
        zIndex={1234569}
        size="md"
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="preheader-location-modal"
        centered
      >
        <div className="preheader-location-modal-header">
          <h1>Select Location</h1>
        </div>
        <div className="preheader-location-modal-body">
          <div className="preheader-location-modal-body-head">
            <p>Mogo allows you to shop from anywhere in the world.</p>
          </div>
          <div className="preheader-location-modal-body-content">
            <Input icon={<img src={map} alt="map-marker-icon" width="15" />} placeholder="Enter Location" value={locationField} onChange={(e) => setLocationField(e.target.value)} />
            <button>Update Location</button>
          </div>
        </div>
      </Modal>
      {/* modal end */}
      {/* Register model starts */}
      <Modal
        zIndex={1234569}
        size="md"
        centered
        opened={registermodalOpen}
        onClose={() => setRegisterModalOpen(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="preheader-register-modal"
      >
        <div className="preheader-register-modal-header">
          <h1>Register</h1>
        </div>
        <div className="preheader-register-modal-body">
          <div className="preheader-register-modal-body-content">
            <Flex justify={"space-between"}>
              <Input.Wrapper label="First Name" error={`${validateUserRegisterValue.first_name === 1 ? "Please Enter First Name" : ""}`}>
                <Input
                  value={userRegisterValue.first_name}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      first_name: e.target.value,
                    })
                  }
                  placeholder="First Name"
                />
              </Input.Wrapper>
              <Input.Wrapper label="Last Name" error={`${validateUserRegisterValue.last_name === 1 ? "Please Enter Last Name" : ""}`}>
                <Input
                  value={userRegisterValue.last_name}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      last_name: e.target.value,
                    })
                  }
                  placeholder="Last Name"
                />
              </Input.Wrapper>
            </Flex>
            <Flex justify={"space-between"}>
              <Input.Wrapper label="Email " error={`${validateUserRegisterValue.email === 1 ? "Please Enter Email" : validateUserRegisterValue.email === 2 ? "Please Enter Valid Email Address" : validateUserRegisterValue.email === 3 ? "Email Already Exists" : ""}`}>
                <Input
                  value={userRegisterValue.email}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      email: e.target.value,
                    })
                  }
                  placeholder="Email Address"
                />
              </Input.Wrapper>
              <Input.Wrapper label="Number " error={`${validateUserRegisterValue.number === 1 ? "Please Enter Number" : validateUserRegisterValue.number === 2 ? "Please Enter Valid Number" : validateUserRegisterValue.number === 3 ? "Mobile number already Exists" : ""}`}>
                <Input
                  value={userRegisterValue.number}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      number: e.target.value,
                    })
                  }
                  placeholder="Mobile Number"
                />
              </Input.Wrapper>
            </Flex>
            <Flex justify={"space-between"}>
              <Input.Wrapper label="Password " error={`${validateUserRegisterValue.password === 1 ? "Please Enter Password" : ""}`}>
                <PasswordInput
                  w={"100%"}
                  value={userRegisterValue.password}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      password: e.target.value,
                    })
                  }
                  placeholder="Password"
                  className="preheader-register-model-password-show-hide-btn"
                />
              </Input.Wrapper>
              <Input.Wrapper label="Conform Password " error={`${validateUserRegisterValue.conform_password === 1 ? "Please Enter Conform Password" : validateUserRegisterValue.conform_password === 2 ? `Conform password doesn't match with password ` : ""}`}>
                <PasswordInput
                  value={userRegisterValue.conform_password}
                  onChange={(e) =>
                    setUserRegisterValue({
                      ...userRegisterValue,
                      conform_password: e.target.value,
                    })
                  }
                  placeholder="Confirm Password"
                  className="preheader-register-model-password-show-hide-btn"
                />
              </Input.Wrapper>
            </Flex>
            <Checkbox
              onChange={(e) =>
                setValidateUserRegisterValue({
                  ...validateUserRegisterValue,
                  check_box: e.currentTarget.checked,
                })
              }
              className="preheader-register-model-checkbox"
              label={
                <>
                  I have read and agree to the{" "}
                  <Link onClick={() => setRegisterModalOpen(false)} to="/terms-and-conditions" className="preheader-register-model-tearms-and-conditions-link">
                    Terms & Conditions
                  </Link>
                </>
              }
            />
            <Button disabled={!validateUserRegisterValue.check_box} onClick={handleRegisterClick}>
              Register
            </Button>
            <p className="preheader-register-model-goto-login">
              Have an account?{" "}
              <span
                onClick={() => {
                  setRegisterModalOpen(false);
                  setLoginModalOpen(true);
                }}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </Modal>
      {/*Register modal end */}
      {/* Login model starts */}
      <Modal
        zIndex={1234569}
        size="md"
        centered
        opened={loginModalStatus}
        onClose={() => handleYourAccount(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="preheader-login-modal"
      >
        <div className="preheader-login-modal-header">
          <h1>Login</h1>
        </div>
        <div className="preheader-login-modal-body">
          <div className="preheader-login-modal-body-content">
            <Input.Wrapper label="Email Id" error={`${userLoginValidation.email === 1 ? "Please Enter Email id" : userLoginValidation.email === 2 ? "Please Enter Valid Email id" : userLoginValidation.email === 3 ? "Email not found" : ""}`}>
              <Input placeholder="Email Address" value={userLogin.email} onChange={(e) => setuserLogin({ ...userLogin, email: e.target.value })} />
            </Input.Wrapper>
            <Input.Wrapper label="Password" error={`${userLoginValidation.password === 1 ? "Please Enter Password" : userLoginValidation.password === 2 ? "Incorrect Password" : ""}`}>
              <PasswordInput placeholder="Password" className="preheader-login-model-password-show-hide-btn" value={userLogin.password} onChange={(e) => setuserLogin({ ...userLogin, password: e.target.value })} />
            </Input.Wrapper>
            <p
              className="preheader-login-model-goto-forgot-password"
              onClick={() => {
                setForgotModalOpen(true);
                handleYourAccount(false);
              }}
            >
              Forgot Password?
            </p>
            <button onClick={handleLogin}>Login</button>
            <p className="preheader-login-model-goto-login">
              Don't have an account?{" "}
              <span
                onClick={() => {
                  setRegisterModalOpen(true);
                  handleYourAccount(false);
                }}
              >
                Register
              </span>
            </p>
          </div>
        </div>
      </Modal>
      {/*Login modal end */}
      {/* Forgot model starts */}
      <Modal
        zIndex={1212123}
        size="md"
        centered
        opened={forgotmodalOpen}
        onClose={() => setForgotModalOpen(false)}
        title=""
        transitionProps={{
          transition: "fade",
          duration: 350,
          timingFunction: "linear",
        }}
        className="preheader-login-modal"
      >
        <div className="preheader-login-modal-header">
          <h1>Reset Password</h1>
        </div>
        <div className="preheader-login-modal-body">
          {ForgotPassword.user_id ? (
            <div className="preheader-login-modal-body-content-newpassword">
              <Input.Wrapper error={`${ForgotPasswordValidation.newPassword === 1 ? "Please Enter New Password" : ForgotPasswordValidation.newPassword === 2 ? "Password Must Contain" : ""}`} label="New Password">
                <PasswordInput
                  value={ForgotPassword.newPassword}
                  onChange={(e) =>
                    setForgotPassword((previous) => ({
                      ...previous,
                      newPassword: e.target.value,
                    }))
                  }
                  placeholder="New Password"
                />
              </Input.Wrapper>
              <Input.Wrapper error={`${ForgotPasswordValidation.conformPassword === 1 ? "Please Enter Conform Password" : ForgotPasswordValidation.conformPassword === 2 ? "Password and Conform Password must be Same" : ""}`} label="Conform Password">
                <PasswordInput
                  value={ForgotPassword.conformPassword}
                  onChange={(e) =>
                    setForgotPassword((previous) => ({
                      ...previous,
                      conformPassword: e.target.value,
                    }))
                  }
                  placeholder="Conform Password"
                />
              </Input.Wrapper>
              <button className="preheader-login-modal-body-content-newpassword-btn" onClick={handleResetPassword}>
                Submit
              </button>
            </div>
          ) : (
            <div className="preheader-login-modal-body-content">
              <p className="preheader-forgot-model-sub-heading">Enter your email address</p>
              <Input.Wrapper error={`${ForgotPasswordValidation.email === 1 ? "Please Enter Email" : ForgotPasswordValidation.email === 2 ? "Please Enter Valid Password" : ForgotPasswordValidation.email === 3 ? "Email doesn't Exists" : ""}`} label="Email">
                <Input
                  disabled={ForgotPassword.emailSend}
                  onChange={(e) =>
                    setForgotPassword((previous) => ({
                      ...previous,
                      email: e.target.value,
                    }))
                  }
                  placeholder="Email Address"
                />
              </Input.Wrapper>
              {ForgotPassword.emailSend ? (
                <Input.Wrapper error={`${ForgotPasswordValidation.otp === 1 ? "Please Enter OTP" : ForgotPasswordValidation.otp === 2 ? "Incorrect OTP" : ForgotPasswordValidation.otp === 3 ? "OTP doesn't Exists" : ""}`} label="OTP">
                  <Input
                    onChange={(e) =>
                      setForgotPassword((previous) => ({
                        ...previous,
                        otp: e.target.value,
                      }))
                    }
                    placeholder="Please Enter OTP"
                  />
                </Input.Wrapper>
              ) : (
                ""
              )}
              <button disabled={ForgotPassword.button} onClick={ForgotPassword.emailSend ? handleVerifyOTP : handleChange}>
                Submit
              </button>
            </div>
          )}
        </div>
      </Modal>
      {/*Forgot modal end */}
    </div>
  );
};

export default Preheader;
