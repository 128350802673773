//import react packages
import React, { useEffect, useState } from "react";

//import mantine hooks
import { useMediaQuery } from "@mantine/hooks";

//import react-router-dom packages
import { Link, useLocation, useNavigate } from "react-router-dom";

//import mantine packages
import {
  Avatar,
  Menu as MenuMantine,
  ScrollArea,
  Tooltip,
} from "@mantine/core";

//import react-pro-slider packages
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

//import font-awesome packages
import "font-awesome/css/font-awesome.min.css";

//import AdminRouters css
import "./AdminRouters.css";

//import dashboard from pages->admin->dashboard
import Dashboard from "./pages/Admin/Dashboard/Dashboard";

//import Individual from pages->admin->Orders->Individual
import Individual from "./pages/Admin/Orders/Orders/Individual/Individual";

//import Orders from pages->admin->Orders
import Orders from "./pages/Admin/Orders/Orders/Orders";

//import Orders from pages->admin->Orders->transactions
import Transactions from "./pages/Admin/Orders/Transactions/Transactions";

//import Earnings from pages->admin->Earnings->Earnings
import Earnings from "./pages/Admin/Earnings/Earnings/Earnings";

//import SellerBalances from pages->admin->Earnings->SellerBalances
import SellerBalances from "./pages/Admin/Earnings/SellerBalances/SellerBalances";

//import AddPayout from pages->admin->Payout->AddPayout
import AddPayout from "./pages/Admin/Payouts/AddPayout/AddPayout";

//import PayoutRequests from pages->admin->Payout->PayoutRequests
import PayoutRequests from "./pages/Admin/Payouts/PayoutRequests/PayoutRequests";

//import RefundRequests from pages->admin->RefundRequests
import RefundRequests from "./pages/Admin/RefundRequests/RefundRequests";

//import IndividualRefund from pages->admin->RefundRequests->IndividualRefund
import IndividualRefund from "./pages/Admin/RefundRequests/IndividualRefund/IndividualRefund";

//import AddProduct from pages->admin->product->addproduct
import AddProduct from "./pages/Admin/Product/AddProduct/AddProduct";

//import Products from pages->admin->product->products
import Products from "./pages/Admin/Product/Products/Products";

//import ProductIndividual from pages->admin->product->ProductIndividual
import ProductIndividual from "./pages/Admin/Product/ProductIndividual/ProductIndividual";

//import SpecialProducts from pages->admin->product->SpecialProducts
import SpecialProducts from "./pages/Admin/Product/SpecialProducts/SpecialProducts";

//import PendingProducts from pages->admin->product->PendingProducts
import PendingProducts from "./pages/Admin/Product/PendingProducts/PendingProducts";

//import HiddenProducts from pages->admin->product->HiddenProducts
import HiddenProducts from "./pages/Admin/Product/HiddenProducts/HiddenProducts";

//import ExpiredProducts from pages->admin->product->ExpiredProducts
import ExpiredProducts from "./pages/Admin/Product/ExpiredProducts/ExpiredProducts";

//import SoldProducts from pages->admin->product->SoldProducts
import SoldProducts from "./pages/Admin/Product/SoldProducts/SoldProducts";

import Drafts from "./pages/Admin/Product/Drafts/Drafts";

//import DeletedProducts from pages->admin->product->DeletedProducts
import DeletedProducts from "./pages/Admin/Product/DeletedProducts/DeletedProducts";

//import Category from pages->admin->Category
import Category from "./pages/Admin/Category/Category";

//import FeaturedProducts from pages->admin->FeaturedProducts
import FeaturedProducts from "./pages/Admin/FeaturedProducts/FeaturedProducts";

//import QuoteRequests from pages->admin->QuoteRequests
import QuoteRequests from "./pages/Admin/QuoteRequests/QuoteRequests";

//import ProductComments from pages->admin->ApprovedProductComments
import ApprovedProductComments from "./pages/Admin/Comments/ProductComments/ApprovedProductComments";

//import ProductComments from pages->admin->PendingProductComments
import PendingProductComments from "./pages/Admin/Comments/ProductComments/PendingProductComments";

//import ProductComments from pages->admin->ApprovedBlogComments
import ApprovedBlogComments from "./pages/Admin/Comments/BlogComments/ApprovedBlogComments";

//import QuoteRequests from pages->admin->PendingBlogComments
import PendingBlogComments from "./pages/Admin/Comments/BlogComments/PendingBlogComments";

//import GeneralSettings from pages->admin->GeneralSettings
import GeneralSettings from "./pages/Admin/GeneralSettings/GeneralSettings";

//import ShippingSettings  from pages->admin->ShippingSettings
import ShippingSettings from "./pages/Admin/ShippingSettings/ShippingSettings";

import AdminProfile from "./pages/Admin/AdminProfile/Profile/Profile";
import AdminChangePassword from "./pages/Admin/AdminProfile/ChangePassword/ChangePassword";
import NewsLetter from "./pages/Admin/NewsLetter/NewsLetter";

//import Blogs from pages->admin->Blogs
import Blogs from "./pages/Admin/Blogs/Blogs";

//import images
import Logo from "../src/assets/BrandLogo/brnad_logo.png";
import Logo_favicon from "../src/assets/Mogo-Logo-Favicon.png";

// State Handler
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "./StateHandler/Slice/Login/LoginSlice";
import { useQuery } from "react-query";
import { getAdminByID } from "./config/quries/AdminLogin/Quries";
import Location from "./pages/Admin/Location/Location";
import Users from "./pages/Admin/Users/Users";
import Reviews from "./pages/Admin/Reviews/Reviews";
import { setAdminData } from "./StateHandler/Slice/AdminData/AdminDataSlice";

import config from "./config/server/Servers";
import { Bell } from "tabler-icons-react";
import { allOrders } from "./config/quries/Orders/OrdersQuery";
import { setOrders } from "./StateHandler/Slice/Orders/OrdersSlice";
import { listAllProduct } from "./config/quries/Products/ProductQuries";
import { setProductList } from "./StateHandler/Slice/Products/ProductSlice";
import { getAllUsers } from "./config/quries/users/usersQuery";
import { setAllUsers } from "./StateHandler/Slice/UserSlice/UserSliceData";
import { getAllVendor } from "./config/quries/vendorLogin/vendorQuries";
import { setAllVendors } from "./StateHandler/Slice/VendorData/VendorDataSlice";
import { listAllAddresses } from "./config/quries/Address/userAddress";
import { setAllAddress } from "./StateHandler/Slice/Address/AddressSlice";
import EditProduct from "./pages/Admin/Product/EditProduct/EditProducts";
import UserIndividual from "./pages/Admin/Users/UserIndividual/UserIndividual";
import AddShippingZone from "./pages/Admin/ShippingSettings/Add Shipping Zone/AddShippingZone";

const AdminRouters = () => {
  // Dispatch
  const dispatch = useDispatch();

  window.scrollTo(0, 0);
  const adminsidenavmediaquery = useMediaQuery("(max-width:768px)");
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (adminsidenavmediaquery) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [adminsidenavmediaquery]);
  window.scrollTo(0, 0);
  const location = useLocation();
  const [loactionPath, setLocationPath] = useState();
  const bodyContent = {
    "/admin_dashboard": <Dashboard />,
    "/individualorder/:id": <Individual />,
    "/orders": <Orders />,
    "/transactions": <Transactions />,
    "/earnings": <Earnings />,
    "/sellerbalances": <SellerBalances />,
    "/addpayout": <AddPayout />,
    "/payoutrequests": <PayoutRequests />,
    "/refundrequests": <RefundRequests />,
    "/individualrefund": <IndividualRefund />,
    "/addproduct": <AddProduct />,
    "/editproduct/:product_id": <EditProduct />,
    "/productsdetails": <Products />,
    "/productsdetails_individual/product_id": <ProductIndividual />,
    "/special_products": <SpecialProducts />,
    "/pending_products": <PendingProducts />,
    "/hidden_products": <HiddenProducts />,
    "/expired_products": <ExpiredProducts />,
    "/sold_products": <SoldProducts />,
    "/drafts_products": <Drafts />,
    "/deleted_products": <DeletedProducts />,
    "/categorylist": <Category />,
    "/featuredproducts": <FeaturedProducts />,
    "/quoterequests": <QuoteRequests />,
    "/blogs": <Blogs />,
    "/location": <Location />,
    "/users": <Users />,
    "/reviews": <Reviews />,
    "/pending_product_comments": <PendingProductComments />,
    "/approved_product_comments": <ApprovedProductComments />,
    "/pending_blog_comments": <PendingBlogComments />,
    "/approved_blog_comments": <ApprovedBlogComments />,
    "/general_settings": <GeneralSettings />,
    "/shipping_settings": <ShippingSettings />,
    "/add_shippingZone": <AddShippingZone />,
    "/admin_profile": <AdminProfile />,
    "/admin_password": <AdminChangePassword />,
    "/newsletter": <NewsLetter />,
    "/view_user_details": <UserIndividual />,
  };
  useEffect(() => {
    const paramsId = location.pathname.split("/");
    if (paramsId.length > 2) {
      if (paramsId[1] === "productsdetails_individual") {
        setLocationPath("/productsdetails_individual/product_id");
      } else if (paramsId[1] === "editproduct") {
        setLocationPath("/editproduct/:product_id");
      }
    } else {
      setLocationPath(`/${paramsId[1]}`);
    }
  }, [location]);
  const individualOrder = location.pathname.split("/");
  console.log(location.pathname);
  const renderBodyData =
    individualOrder[1] === "individualorder" ? (
      <Individual />
    ) : (
      bodyContent[loactionPath] || null
    );
  // Login Status Checking
  const loginStatus = useSelector((state) => state?.loginStatus?.value);
  const adminData = useSelector((state) => state?.adminData?.value);
  // Navigate to Push
  const navigate = useNavigate();
  const adminId = sessionStorage.getItem("MogoAdminAccessToken101");
  useQuery(["adminData", adminId], getAdminByID, {
    refetchOnWindowFocus: false,
    enabled: !!adminId,
    onSuccess: (res) => {
      dispatch(setAdminData(res.data.data));
    },
  });
  // Login status Checking
  useEffect(() => {
    if (
      sessionStorage.getItem("MogoAdminAccessToken102") &&
      sessionStorage.getItem("MogoAdminAccessToken101")
    ) {
      dispatch(setLoginStatus(true));
    } else {
      window.location.reload(navigate("/admin"));
    }
  }, [
    sessionStorage.getItem("MogoAdminAccessToken102"),
    sessionStorage.getItem("MogoAdminAccessToken101"),
  ]);

  const handleLogout = () => {
    window.location.reload(navigate("/admin"));
    sessionStorage.clear();
  };

  // Orders API Fetching
  useQuery("ordersList", allOrders, {
    onSuccess: (res) => {
      dispatch(setOrders(res.data.data.result));
    },
  });

  // Products API FetChing
  useQuery("productsList", listAllProduct, {
    onSuccess: (res) => {
      dispatch(setProductList(res.data.data.result));
    },
  });

  // All Users Fetching API
  useQuery("allUsers", getAllUsers, {
    onSuccess: (res) => {
      dispatch(setAllUsers(res.data.data.result));
    },
  });

  // All Vendors Fetching API
  useQuery("allVendors", getAllVendor, {
    onSuccess: (res) => {
      dispatch(setAllVendors(res.data.data.result));
    },
  });

  // All Address Fetching API
  useQuery("allAddress", listAllAddresses, {
    onSuccess: (res) => {
      dispatch(setAllAddress(res.data.data.result));
    },
  });

  return (
    <div>
      <div className="admin-sidenav">
        <Sidebar
          collapsed={collapsed}
          style={{ zIndex: "9999" }}
          transitionDuration={1000}
        >
          <div className="admin-sidebar-logo">
            <Link to="/admin_dashboard">
              <div
                className={
                  collapsed === false
                    ? `admin-sidebar-logo-img`
                    : `admin-sidebar-logo-img-favicon`
                }
              >
                {collapsed === false ? (
                  <img src={Logo} alt="" />
                ) : (
                  <img className="Logo_favicon" src={Logo_favicon} alt="" />
                )}
              </div>
            </Link>
          </div>
          <Menu
            className="admin-sidenav-menu-style"
            style={{ position: "relative", color: "#959cac" }}
          >
            <Tooltip
              label="Home"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/admin_dashboard">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-home" />}
                >
                  Home
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Users"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/users">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-users" />}
                >
                  Users
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Orders"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu
                label="Orders"
                icon={<i className="fa fa-shopping-cart" />}
              >
                <Link to="/orders">
                  <MenuItem component="span"> Orders</MenuItem>
                </Link>
                <Link to="/transactions">
                  <MenuItem component="span"> Transactions</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Earnings"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu label="Earnings" icon={<i className="fa fa-money" />}>
                <Link to="/earnings">
                  <MenuItem component="span"> Earnings</MenuItem>
                </Link>
                <Link to="/sellerbalances">
                  <MenuItem component="span"> Seller Balances</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Payouts"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu
                label="Payouts"
                icon={<i className="fa fa-credit-card" />}
              >
                <Link to="/addpayout">
                  <MenuItem component="span"> Payment History</MenuItem>
                </Link>
                <Link to="/payoutrequests">
                  <MenuItem component="span"> Payout Requests</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Refund Requests"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/refundrequests">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-flag" />}
                >
                  Refund Requests
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Products"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu
                label="Products"
                icon={<i className="fa fa-shopping-basket" />}
              >
                <Link to="/productsdetails">
                  <MenuItem component="span"> Products</MenuItem>
                </Link>
                <Link to="/addproduct">
                  <MenuItem component="span"> Add Product</MenuItem>
                </Link>
                <Link to="/special_products">
                  <MenuItem component="span"> Special Products</MenuItem>
                </Link>
                <Link to="/pending_products">
                  <MenuItem component="span"> Pending Products</MenuItem>
                </Link>
                <Link to="/sold_products">
                  <MenuItem component="span"> Sold Products</MenuItem>
                </Link>
                <Link to="/drafts_products">
                  <MenuItem component="span"> Drafts</MenuItem>
                </Link>
                <Link to="/deleted_products">
                  <MenuItem component="span"> Deleted Products</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Featured Products"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/featuredproducts">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-usd" />}
                >
                  Featured Products
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Quote Requests"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/quoterequests">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-tag" />}
                >
                  Quote Requests
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Categories"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/categorylist">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-folder-open" />}
                >
                  Categories
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Blog"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/blogs">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-file-text" />}
                >
                  Blogs
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Reviews"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/reviews">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-star" />}
                >
                  Reviews
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Comments"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu label="Comments" icon={<i className="fa fa-comments" />}>
                <Link to="/pending_product_comments">
                  <MenuItem component="span"> Product Comments</MenuItem>
                </Link>
                <Link to="/pending_blog_comments">
                  <MenuItem component="span"> Blog Comments</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Newsletter"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/newsletter">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-envelope-o" />}
                >
                  Newsletter
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="General Settings"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/general_settings">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-cog" />}
                >
                  General Settings
                </MenuItem>
              </Link>
            </Tooltip>
            <Tooltip
              label="Payment Settings"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <SubMenu
                label="Payment Settings"
                icon={<i className="fa fa-credit-card-alt" />}
              >
                <Link to="">
                  <MenuItem component="span"> Payment Settings</MenuItem>
                </Link>
                <Link to="">
                  <MenuItem component="span"> Currency Settings</MenuItem>
                </Link>
              </SubMenu>
            </Tooltip>
            <Tooltip
              label="Shipping Settings"
              color="teal"
              position="bottom-end"
              withArrow
              disabled={collapsed !== true ? true : false}
            >
              <Link to="/shipping_settings">
                <MenuItem
                  component="span"
                  active
                  icon={<i className="fa fa-truck" />}
                >
                  Shipping Settings
                </MenuItem>
              </Link>
            </Tooltip>
          </Menu>
        </Sidebar>
        <ScrollArea w={"100%"}>
          <main className="admin-sidenav-main-admin-content">
            <div className="admin-sidenav-topbar-color">
              <div>
                <button
                  className="admin-sidenav-toggle-style-button"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <i className="fa fa-bars"></i>
                </button>
              </div>
              <div className="admin-user-profile-dropdown">
                <div className="admin-notification-icon">
                  <MenuMantine shadow="md" width={275}>
                    <MenuMantine.Target>
                      <Bell className="notification-icon" />
                    </MenuMantine.Target>
                    <MenuMantine.Dropdown>items</MenuMantine.Dropdown>
                  </MenuMantine>
                </div>
                <MenuMantine shadow="md" width={200}>
                  <MenuMantine.Target>
                    <div className="admin-user-profile-dropdown-button-style">
                      <div className="admin-user-profile-dropdown-button-img">
                        {adminData.profile_image ? (
                          <Avatar
                            src={`${config.baseUrlApi}/assets/adminProfile/${adminData.profile_image}`}
                            radius="xl"
                          />
                        ) : (
                          <Avatar radius="xl" />
                        )}
                      </div>
                      <div className="admin-user-profile-dropdown-button-content">
                        <p>
                          {adminData.first_name ? (
                            <p className="poppins-font-family">
                              {adminData.first_name + " " + adminData.last_name}
                            </p>
                          ) : (
                            "Hello There"
                          )}
                        </p>
                      </div>
                    </div>
                  </MenuMantine.Target>

                  <MenuMantine.Dropdown>
                    <Link to="/admin_profile">
                      <MenuMantine.Item
                        component="span"
                        icon={<i className="fa fa-cog" />}
                      >
                        Update Profile
                      </MenuMantine.Item>
                    </Link>
                    <Link to="/admin_password">
                      <MenuMantine.Item
                        component="span"
                        icon={<i className="fa fa-user" />}
                      >
                        Change Password
                      </MenuMantine.Item>
                      <MenuMantine.Divider />
                    </Link>
                    <MenuMantine.Item
                      onClick={handleLogout}
                      icon={<i className="fa fa-sign-out" />}
                    >
                      Logout
                    </MenuMantine.Item>
                  </MenuMantine.Dropdown>
                </MenuMantine>
              </div>
            </div>
            <div className="admin-all-pages-content">
              <div>{renderBodyData}</div>
            </div>
          </main>
        </ScrollArea>
      </div>
    </div>
  );
};

export default AdminRouters;
