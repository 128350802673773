import React, { useMemo, useState } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import { Card, Text, Group, Input, Pagination, Select } from "@mantine/core";

import "./ActiveSales.css";

//import icons
import arrowdown from "../../../../assets/preheader/arrow-down.webp";
import info from "../../../../assets/admin/table/dropdown/info.png";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";

const ActiveSales = () => {
  // Props Column
  const COLUMN = [
    {
      Header: "Order ID",
      accessor: "Sale",
    },
    {
      Header: "Product Name",
      accessor: "Product_Name",
    },
    {
      Header: "Category",
      accessor: "Category",
    },
    {
      Header: "Sub Category",
      accessor: "Sub_Category",
    },
    {
      Header: "Payment",
      accessor: "Payment",
    },
    {
      Header: "Status",
      accessor: "Status",
    },
    {
      Header: "Date",
      accessor: "Date",
    },
    {
      Header: "Options",
      accessor: () => (
        <div>
          <Text component={Link} to="/vendor_individual_order">
            <button className="sales-button">View</button>
          </Text>
        </div>
      ),
    },
  ];

  const tableData = [
    {
      Sale: 3938,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "8/31/2023",
    },
    {
      Sale: 6470,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "9/24/2023",
    },
    {
      Sale: 3342,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "5/20/2023",
    },
    {
      Sale: 6370,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "9/30/2023",
    },
    {
      Sale: 4621,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "2/14/2023",
    },
    {
      Sale: 8283,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "11/16/2022",
    },
    {
      Sale: 1265,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "9/25/2023",
    },
    {
      Sale: 1042,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "4/20/2023",
    },
    {
      Sale: 6648,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "4/8/2023",
    },
    {
      Sale: 3012,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "4/15/2023",
    },
    {
      Sale: 6581,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "11/15/2022",
    },
    {
      Sale: 2661,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "4/26/2023",
    },
    {
      Sale: 5805,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "2/18/2023",
    },
    {
      Sale: 1977,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Awaiting Payment",
      Status: "Processing",
      Date: "8/14/2023",
    },
    {
      Sale: 1632,
      Product_Name: "Towel",
      Category: "Bath Linen",
      Sub_Category: "Bath Towel",
      Payment: "Payment Received",
      Status: "Processing",
      Date: "4/17/2023",
    },
  ];

  // React Table
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => tableData, []),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Status Filter
  const [selectedStatus, setSelectedStatus] = useState("All");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const handleDateChange = (update) => {
    if (Array.isArray(update) && update.length === 2) {
      const [newStartDate, newEndDate] = update;

      if (newStartDate && newEndDate) {
        const formattedStartDate = newStartDate.toLocaleDateString("en-US");
        const formattedEndDate = newEndDate.toLocaleDateString("en-US");
        const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setFormattedDateRange(formattedDateRange);
      } else {
        setFormattedDateRange("");
      }
    } else {
      setSelectedDate(update);
    }
  };

  return (
    <div>
      <div className="activesales-div">
        <Card className="activesales-div-card">
          <div className="activesales-div-card-heading">
            <h4>Active Sales</h4>
          </div>
          <div className="activesales-div-card-content">
            <div className="payoutrequests-filter-container sales-container">
              <div className="payoutrequests-filter-container-div1">
                <Select
                  label="Show"
                  onChange={(e) => setPageSize(Number(e))}
                  rightSection={
                    <img src={arrowdown} width={10} alt="arrow down icon" />
                  }
                  defaultValue={"10"}
                  data={[
                    { value: "10", label: "10", key: "10" },
                    { value: "20", label: "20", key: "20" },
                    { value: "30", label: "30", key: "30" },
                    { value: "40", label: "40", key: "40" },
                    { value: pageCount * 10, label: "All", key: "All" },
                  ]}
                />
              </div>
              <div className="payoutrequests-filter-container-div2">
                <Select
                  label="Payment Status"
                  onChange={(e) => setSelectedStatus(e)}
                  rightSection={
                    <img src={arrowdown} width={10} alt="arrow down icon" />
                  }
                  defaultValue={"All"}
                  data={[
                    { value: "All", label: "All" },
                    { value: "Awaiting Payment", label: "Awaiting Payment" },
                    { value: "Payment Received", label: "Payment Received" },
                  ]}
                />
              </div>
              <div className="payoutrequests-filter-container-div4">
                <Input.Wrapper label="Search" maw={320} mx="auto">
                  <Input
                    value={globalFilter || ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search Filter"
                  />
                </Input.Wrapper>
              </div>
              <div className="payoutrequests-filter-container-div5">
                <Input.Wrapper label="Date" maw={320} mx="auto">
                  <ReactDatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                      handleDateChange(update);
                    }}
                    isClearable={true}
                    maxDate={currentDate}
                  />
                </Input.Wrapper>
              </div>
            </div>
            <div className="table-style">
              <table {...getTableProps()} className="custum-table-style">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((header) => (
                        <th {...header.getHeaderProps()}>
                          {header.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.length > 0 ? (
                    page
                      .filter(
                        (row) =>
                          selectedStatus === "All" ||
                          row.values.Payment === selectedStatus
                      )
                      .map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        Nothing Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="react-table-pagination">
              <Pagination.Root
                total={pageOptions.length}
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={(e) => gotoPage(e - 1)}
              >
                <Group spacing={5} position="center">
                  <Pagination.First
                    disabled={!canPreviousPage}
                    onClick={() => gotoPage(0)}
                  />
                  <Pagination.Previous
                    disabled={!canPreviousPage}
                    onClick={() => {
                      previousPage();
                      gotoPage(pageIndex - 1);
                    }}
                  />
                  <Pagination.Items value={2} />
                  <Pagination.Next
                    disabled={!canNextPage}
                    onClick={() => {
                      nextPage();
                      gotoPage(pageIndex + 1);
                    }}
                  />
                  <Pagination.Last
                    disabled={!canNextPage}
                    onClick={() => gotoPage(pageCount - 1)}
                  />
                </Group>
              </Pagination.Root>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ActiveSales;
