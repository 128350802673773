import { createSlice } from "@reduxjs/toolkit";
import { cartState } from "../../InitialState/Cart/CartState";

export const cartSlice = createSlice({
  initialState: cartState,
  name: "cartSlice",
  reducers: {
    setCart: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCart } = cartSlice.actions;
