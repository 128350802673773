import { createSlice } from "@reduxjs/toolkit";
import { allAddressState } from "../../InitialState/Address/AddressState";

export const allAddressSlice = createSlice({
  name: "All_Address",
  initialState: allAddressState,
  reducers: {
    setAllAddress: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setAllAddress } = allAddressSlice.actions;