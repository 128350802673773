//import react packages
import React, { useMemo, useState } from "react";

// React Table
import { useTable, usePagination, useGlobalFilter } from "react-table";

//import mantine packages
import {
  Card,
  Menu,
  Text,
  Group,
  Input,
  Pagination,
  Select,
  Modal,
  Flex,
  ThemeIcon,
} from "@mantine/core";

//import mantine models
import { modals } from "@mantine/modals";

//import react router dom packages
import { Link } from "react-router-dom";

//import FeaturedProducts css
import "./FeaturedProducts.css";

import NumericInput from "../../../components/UI/Input/NumericInput";

//import icons
import anglebottom from "../../../assets/admin/table/anglebottom.webp";
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import { useQuery, useQueryClient } from "react-query";
import {
  deleteProductById,
  listAllProduct,
} from "../../../config/quries/Products/ProductQuries";

// Tabler Icons
import {
  CircleCheck,
  CircleMinus,
  CirclePlus,
  Edit,
  InfoCircle,
  Trash,
  X,
} from "tabler-icons-react";

// Axios
import axios from "axios";

// Redux
import { showNotification } from "@mantine/notifications";
import { useDispatch } from "react-redux";
import { categoryListAPI } from "../../../config/quries/Category/CategoryQueries";
import { subCategoryListAPI } from "../../../config/quries/SubCategory/SubCategoryQuries";
import config from "../../../config/server/Servers";
import moment from "moment";

const FeaturedProducts = () => {
  const [featuredProductsList, setFeaturedProductsList] = useState([]);
  const [featuredModalOpen, setFeaturedModalOpen] = useState(false);
  const [maxValue, setMaxValue] = useState("1");
  const [categoryList, setCategoryList] = useState({
    category: "",
    subCategory: "",
  });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  //  updateFeatureProduct
  const handleUpdateFeatureProduct = (id, status) => {
    axios
      .put(
        `${config.baseUrlApi}/products/updatebyid/${id}`,
        {
          featured: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem(
              "MogoAdminAccessToken102"
            )}`,
          },
        }
      )
      .then(() => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "Product Added to Featured Products",
        });
        queryClient.invalidateQueries("productAPI");
      })
      .catch(() => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "User Access Not Allowed",
        });
      });
  };
  //  updateSpecialProduct
  const handleUpdateSpecialProduct = (id, status) => {
    axios
      .put(
        `${config.baseUrlApi}/products/updatebyid/${id}`,
        {
          special: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem(
              "MogoAdminAccessToken102"
            )}`,
          },
        }
      )
      .then(() => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "Product Added to Featured Products",
        });
        queryClient.invalidateQueries("productAPI");
      })
      .catch(() => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "User Access Not Allowed",
        });
      });
  };

  const openDeleteModal = (id) =>
    modals.openConfirmModal({
      zIndex: "12345678",
      centered: true,
      title: "Delete your profile",
      children: (
        <Text size="sm">
          Are you sure you want to delete Product? This action is destructive
          and you will never get back the data.
        </Text>
      ),
      labels: { confirm: "Delete account", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onConfirm: () =>
        deleteProductById(id)
          .then(() => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <CircleCheck color="green" />
                </ThemeIcon>
              ),
              message: "Product Deleted Successfully",
            });
            queryClient.invalidateQueries("productAPI");
          })
          .catch(() => {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                  <X color="red" />
                </ThemeIcon>
              ),
              message: "User Not Allowed to Delete Product",
            });
          }),
    });

  // Props Column
  const COLUMN = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Product",
      accessor: "name",
      textAlign: "center",
    },
    {
      Header: "SKU",
      accessor: "SKU",
    },
    {
      Header: "Category",
      accessor: (row) => row.CategoryDetails[0].name,
    },
    {
      Header: "Sub Category",
      accessor: (row) => row.subCategoryDetails[0].name,
    },
    {
      Header: "User",
      accessor: (row) =>
        row.admin_id
          ? row.adminDetails[0].name
            ? row.adminDetails[0].name
            : "Admin"
          : row.vendor_id
          ? row.vendorDetails[0].name
            ? row.vendorDetails[0].name
            : "Admin"
          : "",
    },
    {
      Header: "Stock",
      accessor: "stock_status",
    },
    {
      Header: "Date",
      accessor: "created",
      Cell: ({ row }) => {
        const date = row.original.created;
        const formattedDate = moment(new Date(date)).format("DD-MM-YYYY");
        return <p>{formattedDate}</p>;
      },
    },
    {
      Header: "Options",
      accessor: "_id",
      Cell: ({ row }) => {
        return (
          <>
            <Menu classNames="table-selectbox">
              <Menu.Target>
                <button className="table-select-dropdown">
                  <img src={anglebottom} alt="anglebottom" width={15} />
                </button>
              </Menu.Target>
              <Menu.Dropdown>
                <Text
                  component={Link}
                  to={`/productsdetails_individual/${row.original._id}`}
                >
                  <Menu.Item>
                    <Flex align={"center"} gap={"0.2rem"}>
                      <InfoCircle strokeWidth={1} />
                      <p>View Details</p>
                    </Flex>
                  </Menu.Item>
                </Text>
                <Menu.Item>
                  {row.original.featured ? (
                    <Flex
                      onClick={() =>
                        handleUpdateFeatureProduct(row.original._id, false)
                      }
                      align={"center"}
                      gap={"0.2rem"}
                    >
                      <CircleMinus strokeWidth={1} />
                      <p>Remove from Featured</p>
                    </Flex>
                  ) : (
                    <Flex
                      onClick={() =>
                        handleUpdateFeatureProduct(row.original._id, true)
                      }
                      align={"center"}
                      gap={"0.2rem"}
                    >
                      <CirclePlus strokeWidth={1} />
                      <p>Add to Featured</p>
                    </Flex>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {row.original.special ? (
                    <Flex
                      onClick={() =>
                        handleUpdateSpecialProduct(row.original._id, false)
                      }
                      align={"center"}
                      gap={"0.2rem"}
                    >
                      <CircleMinus strokeWidth={1} />
                      Remove From Special
                    </Flex>
                  ) : (
                    <Flex
                      onClick={() =>
                        handleUpdateSpecialProduct(row.original._id, true)
                      }
                      align={"center"}
                      gap={"0.2rem"}
                    >
                      <CirclePlus strokeWidth={1} />
                      <p>Add to Special</p>
                    </Flex>
                  )}
                </Menu.Item>
                <Text component={Link} to={`/editproduct/${row.original._id}`}>
                  <Menu.Item>
                    <Flex align={"center"} gap={"0.2rem"}>
                      <Edit strokeWidth={1} />
                      <p>Edit</p>
                    </Flex>
                  </Menu.Item>
                </Text>
                <Menu.Item onClick={() => openDeleteModal(row.original._id)}>
                  <Flex align={"center"} gap={"0.2rem"}>
                    <Trash strokeWidth={1} />
                    <p>Delete Permanently</p>
                  </Flex>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        );
      },
    },
  ];

  // React Table
  const {
    getTableBodyProps,
    getTableProps,
    page,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => COLUMN, []),
      data: useMemo(() => featuredProductsList, [featuredProductsList]),
    },
    useGlobalFilter,
    usePagination
  );

  // Global Filter
  const { globalFilter, pageIndex } = state;

  // Product API Data
  useQuery("productAPI", listAllProduct, {
    onSuccess: (res) => {
      const featureDataFiltered = res.data.data.result.filter(
        (value) => value.featured === true
      );
      setFeaturedProductsList(featureDataFiltered);
    },
  });

  // Category List
  useQuery("categoryList", categoryListAPI, {
    onSuccess: (res) => {
      const categoriesFromAPI = res.data.data.result.map((value) => value.name);
      const modifiedCategoryList = ["All", ...categoriesFromAPI];
      setCategoryList((prevCategoryList) => ({
        ...prevCategoryList,
        category: modifiedCategoryList,
      }));
    },
  });

  useQuery("subCategoryList", subCategoryListAPI, {
    onSuccess: (res) => {
      const categoriesFromAPI = res.data.data.result.map((value) => value.name);
      const modifiedCategoryList = ["All", ...categoriesFromAPI];
      setCategoryList((prevCategoryList) => ({
        ...prevCategoryList,
        subCategory: modifiedCategoryList,
      }));
    },
  });
  return (
    <div>
      <div className="featuredproducts-div">
        <Card className="min-height-90vh">
          <div className="featuredproducts-div-heading pb-15">
            <h2>Featured Products</h2>
          </div>
          <div className="featuredproducts-div-table">
            <div>
              <div className="featuredproducts-filter-container">
                <div className="featuredproducts-filter-container-div1">
                  <Select
                    label="Show"
                    onChange={(e) => setPageSize(Number(e))}
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"10"}
                    data={[
                      { value: "10", label: "10", key: "10" },
                      { value: "20", label: "20", key: "20" },
                      { value: "30", label: "30", key: "30" },
                      { value: "40", label: "40", key: "40" },
                      { value: pageCount * 10, label: "All", key: "All" },
                    ]}
                  />
                </div>
                <div className="featuredproducts-filter-container-div2">
                  <Select
                    label="Category"
                    onChange={(e) =>
                      e === "All" ? setGlobalFilter("") : setGlobalFilter(e)
                    }
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"All"}
                    data={
                      Array.isArray(categoryList?.category)
                        ? categoryList?.category?.map((data) => ({
                            value: data,
                            label: data,
                          }))
                        : [
                            { value: "All", label: "All" },
                            { value: "Backpacks", label: "Backpacks" },
                            { value: "Scarfs", label: "Scarfs" },
                            { value: "Boots", label: "Boots" },
                          ]
                    }
                  />
                </div>
                <div className="featuredproducts-filter-container-div3">
                  <Select
                    label="Sub Category"
                    onChange={(e) =>
                      e === "All" ? setGlobalFilter("") : setGlobalFilter(e)
                    }
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"All"}
                    data={
                      Array.isArray(categoryList?.subCategory)
                        ? categoryList?.subCategory?.map((data) => ({
                            value: data,
                            label: data,
                          }))
                        : [
                            { value: "All", label: "All" },
                            { value: "Backpacks", label: "Backpacks" },
                            { value: "Scarfs", label: "Scarfs" },
                            { value: "Boots", label: "Boots" },
                          ]
                    }
                  />
                </div>
                <div className="featuredproducts-filter-container-div5">
                  <Select
                    label="Stock"
                    onChange={(e) =>
                      e === "All" ? setGlobalFilter("") : setGlobalFilter(e)
                    }
                    rightSection={
                      <img src={arrowdown} width={10} alt="arrow down icon" />
                    }
                    defaultValue={"All"}
                    data={[
                      { value: "All", label: "All" },
                      { value: "In Stock", label: "In Stock" },
                      { value: "Out of Stock", label: "Out of Stock" },
                    ]}
                  />
                </div>
                <div className="featuredproducts-filter-container-div4">
                  <Input.Wrapper label="Search" maw={320} mx="auto">
                    <Input
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Filter"
                    />
                  </Input.Wrapper>
                </div>
              </div>
              <div className="table-style">
                <table {...getTableProps()} className="custum-table-style">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((header) => (
                          <th {...header.getHeaderProps()}>
                            {header.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          Nothing Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="react-table-pagination">
                <Pagination.Root
                  total={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  value={pageIndex + 1}
                  onChange={(e) => gotoPage(e - 1)}
                >
                  <Group spacing={5} position="center">
                    <Pagination.First
                      disabled={!canPreviousPage}
                      onClick={() => gotoPage(0)}
                    />
                    <Pagination.Previous
                      disabled={!canPreviousPage}
                      onClick={() => {
                        previousPage();
                        gotoPage(pageIndex - 1);
                      }}
                    />
                    <Pagination.Items value={2} />
                    <Pagination.Next
                      disabled={!canNextPage}
                      onClick={() => {
                        nextPage();
                        gotoPage(pageIndex + 1);
                      }}
                    />
                    <Pagination.Last
                      disabled={!canNextPage}
                      onClick={() => gotoPage(pageCount - 1)}
                    />
                  </Group>
                </Pagination.Root>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FeaturedProducts;
