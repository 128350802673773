import { showNotification } from "@mantine/notifications";
import {
  addNewAdmin,
  changePassword,
  updateAdminWithoutImage,
} from "../../../config/quries/AdminLogin/Quries";
import { ThemeIcon } from "@mantine/core";
import { CircleCheck, X } from "tabler-icons-react";
import axios from "axios";
import config from "../../../config/server/Servers";

export const adminDetailsControl = async (
  profile,
  setProfile,
  validateData,
  setValidateData,
  queryClient,
  setEditProfile
) => {
  const { firstname, lastname, mobilenumber, address, profile_image } = profile;
  const token = sessionStorage.getItem("MogoAdminAccessToken102");
  const id = sessionStorage.getItem("MogoAdminAccessToken101");
  if (firstname.trim()) {
    if (lastname.trim()) {
      if (mobilenumber) {
        if (mobilenumber.match(/^[0-9]{10}$/)) {
          if (address.trim()) {
            if (profile_image) {
              const formData = new FormData();
              formData.append("first_name", firstname);
              formData.append("last_name", lastname);
              formData.append("mobile", mobilenumber);
              formData.append("address", address);
              formData.append("file", profile_image);
              await axios
                .put(`${config.baseUrlApi}/admin/withprofile/${id}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "MogoAdminAccessToken102"
                    )}`,
                  },
                })
                .then(() => {
                  queryClient.invalidateQueries("adminData");
                  setEditProfile(false);
                  showNotification({
                    icon: (
                      <ThemeIcon
                        variant="light"
                        radius="xl"
                        size="xl"
                        color="green"
                      >
                        <CircleCheck color="green" />
                      </ThemeIcon>
                    ),
                    message: "Profile Updated Successfully",
                  });
                })
                .catch(() => {
                  showNotification({
                    icon: (
                      <ThemeIcon
                        variant="light"
                        radius="xl"
                        size="xl"
                        color="red"
                      >
                        <X color="red" />
                      </ThemeIcon>
                    ),
                    message: "Admin Access Not Allowed",
                  });
                });
            } else {
              const body = {
                first_name: firstname,
                last_name: lastname,
                mobile: mobilenumber,
                address: address,
              };
              const payload = {
                body,
                id,
              };
              await updateAdminWithoutImage(payload)
                .then((res) => {
                  queryClient.invalidateQueries("adminData");
                  setEditProfile(false);
                  showNotification({
                    icon: (
                      <ThemeIcon
                        variant="light"
                        radius="xl"
                        size="xl"
                        color="green"
                      >
                        <CircleCheck color="green" />
                      </ThemeIcon>
                    ),
                    message: "Profile Updated Successfully",
                  });
                })
                .catch((err) => {
                  showNotification({
                    icon: (
                      <ThemeIcon
                        variant="light"
                        radius="xl"
                        size="xl"
                        color="red"
                      >
                        <X color="red" />
                      </ThemeIcon>
                    ),
                    message: "Admin Access Not Allowed",
                  });
                });
            }
          } else {
            setValidateData({ ...validateData, address: 1 });
          }
        } else {
          setValidateData({ ...validateData, mobilenumber: 2 });
        }
      } else {
        setValidateData({ ...validateData, mobilenumber: 1 });
      }
    } else {
      setValidateData({ ...validateData, lastname: 1 });
    }
  } else {
    setValidateData({ ...validateData, firstname: 1 });
  }
};

export const handleChangePasswordAPI = async (
  password,
  setPassword,
  validatePassword,
  setValidatePassword
) => {
  const { oldpassword, newpassword, confirmpassword } = password;
  const body = {
    password: oldpassword.trim(),
    new_password: newpassword.trim(),
  };
  const id = sessionStorage.getItem("MogoAdminAccessToken101");
  const payload = {
    body,
    id,
  };
  if (oldpassword.trim()) {
    if (newpassword.trim()) {
      if (confirmpassword.trim()) {
        if (newpassword.trim() === confirmpassword.trim()) {
          await changePassword(payload)
            .then((res) => {
              if (res.data?.data.result === "Invalid Old Password") {
                setValidatePassword({ ...validatePassword, oldpassword: 2 });
                showNotification({
                  icon: (
                    <ThemeIcon
                      variant="light"
                      radius="xl"
                      size="xl"
                      color="red"
                    >
                      <X color="red" />
                    </ThemeIcon>
                  ),
                  message: "Invalid Old Password",
                });
              } else {
                setPassword({
                  ...password,
                  oldpassword: "",
                  newpassword: "",
                  confirmpassword: "",
                });
                showNotification({
                  icon: (
                    <ThemeIcon
                      variant="light"
                      radius="xl"
                      size="xl"
                      color="green"
                    >
                      <CircleCheck color="green" />
                    </ThemeIcon>
                  ),
                  message: "Password Changed Successfully",
                });
                window.location.reload();
              }
            })
            .catch((err) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Admin Access not Allowed to Change Password",
              });
            });
        } else {
          setValidatePassword({ ...validatePassword, confirmpassword: 2 });
        }
      } else {
        setValidatePassword({ ...validatePassword, confirmpassword: 1 });
      }
    } else {
      setValidatePassword({ ...validatePassword, newpassword: 1 });
    }
  } else {
    setValidatePassword({ ...validatePassword, oldpassword: 1 });
  }
};

export const handleAddAdminControl = async (
  addAdmin,
  setAddAdmin,
  validateData,
  setValidateData,
  queryClient,
  setUsersModalOpen
) => {
  const { first_name, last_name, email, password, conformPassword } = addAdmin;
  const payload = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
  };
  if (first_name.trim()) {
    if (last_name.trim()) {
      if (email.trim()) {
        if (email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
          if (password.trim()) {
            if (conformPassword.trim()) {
              if (password.trim() === conformPassword.trim()) {
                await addNewAdmin(payload)
                  .then(() => {
                    showNotification({
                      icon: (
                        <ThemeIcon
                          variant="light"
                          radius="xl"
                          size="xl"
                          color="green"
                        >
                          <X color="green" />
                        </ThemeIcon>
                      ),
                      message: "New Admin Created",
                    });
                    setAddAdmin({
                      ...addAdmin,
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      conformPassword: "",
                    });
                    queryClient.invalidateQueries("AllAdminList");
                    setUsersModalOpen(false);
                  })
                  .catch(() => {
                    showNotification({
                      icon: (
                        <ThemeIcon
                          variant="light"
                          radius="xl"
                          size="xl"
                          color="red"
                        >
                          <X color="red" />
                        </ThemeIcon>
                      ),
                      message: "Error Occured While Creating Admin",
                    });
                  });
              } else {
                setValidateData({ ...validateData, conformPassword: 2 });
              }
            } else {
              setValidateData({ ...validateData, conformPassword: 1 });
            }
          } else {
            setValidateData({ ...validateData, password: 1 });
          }
        } else {
          setValidateData({ ...validateData, email: 2 });
        }
      } else {
        setValidateData({ ...validateData, email: 1 });
      }
    } else {
      setValidateData({ ...validateData, last_name: 1 });
    }
  } else {
    setValidateData({ ...validateData, first_name: 1 });
  }
};
