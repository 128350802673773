import { createSlice } from "@reduxjs/toolkit";
import { userOrderState } from "../../InitialState/UserOrders/UserOrdersState";

export const userOrdersSlice = createSlice({
  name: "userOrders",
  initialState: userOrderState,
  reducers: {
    setUserOrders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserOrders } = userOrdersSlice.actions;
