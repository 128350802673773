import { showNotification } from "@mantine/notifications";
import { createOrders } from "../../config/quries/Orders/OrdersQuery";
import { ThemeIcon } from "@mantine/core";
import { CircleCheck, X } from "tabler-icons-react";
import { setCart } from "../../StateHandler/Slice/CartSlice/CartSlice";

export const handleOrderPlaceControl = async (data, navigate, dispatch) => {
  await createOrders(data)
    .then((res) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Order Placed Successfully",
      });
      sessionStorage.setItem("orderID", res.data.data.result.insertedId);
      navigate("/orderplaced");
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Placing Order",
      });
    });
};
