//import react packages
import React, { useState, useEffect } from "react";

//import Individual css
import "./Individual.css";

//import react-router-dom packages
import { Link, useParams } from "react-router-dom";

// import mantine packages
import {
  Modal,
  Card,
  Badge,
  Table,
  Menu as MenuMantine,
  Select,
  Button,
  Rating,
  Avatar,
} from "@mantine/core";

// Font Awesome
import "font-awesome/css/font-awesome.min.css";
import {
  getOrderByIDByAdmin,
} from "../../../../../config/quries/Orders/OrdersQuery";
import { getUserAddressByID } from "../../../../../config/quries/Address/userAddress";
import { useQuery } from "react-query";

// Server
import config from "../../../../../config/server/Servers";

// HTML
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import moment from "moment";

const Individual = () => {
  // Params Id
  const paramsId = useParams();

  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setratingModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [individualOrderData, setIndividualOrderData] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [totalPrice, setTotalPrice] = useState();

  // User Data
  const allUserData = useSelector((state) => state.allUsersList.value);
  // Address Data
  const allAddressData = useSelector((state) => state.allAddressList.value);

  // Total PRice
  useEffect(() => {
    const quantity = individualOrderData?.cart_products.map((value) =>
      Number(value.quantity)
    );
    const price = individualOrderData?.cart_products.map((value) =>
      Number(value.sale_price)
    );
    const totalValue = [];
    if (price?.length === quantity?.length) {
      for (var i = 0; i < price?.length; i++) {
        totalValue.push(price[i] * quantity[i]);
      }
    }
    let totalPriceRate = 0;
    for (var i = 0; i < totalValue?.length; i++) {
      totalPriceRate += totalValue[i];
    }
    setTotalPrice(totalPriceRate);
  }, [individualOrderData]);

  // Individual Order Data
  useQuery(["userIndividualOrder", paramsId.id], getOrderByIDByAdmin, {
    onSuccess: (res) => {
      const result = res.data.data.result;
      setIndividualOrderData(result);
    },
  });

  //   Shipping Address
  useQuery(
    ["shippingAddress", individualOrderData?.address_id],
    getUserAddressByID,
    {
      onSuccess: (res) => {
        setShippingAddress(res?.data?.data);
      },
    }
  );

  const createdDate = moment(new Date(individualOrderData?.created)).format(
    "DD-MM-YYYY"
  );
  const modifiedDate = moment(new Date(individualOrderData?.modified)).format(
    "DD-MM-YYYY"
  );
  const ProductDetailsRows = individualOrderData?.cart_products.map(
    (ProductDetail, index) => {
      return (
        <tr key={index}>
          <td>Mogo#{ProductDetail._id}</td>
          <td className="LatestProducts-Table-td-style">
            <div>
              <div className="LatestProducts-Table-td-style-image">
                <img
                  src={`${config.baseUrlApi}/assets/productImages/${ProductDetail.product_image}`}
                  alt=""
                />
              </div>
              <div className="LatestProducts-Table-td-style-title">
                <p> {ReactHtmlParser(ProductDetail?.short_description)}</p>
                <span>
                  {ProductDetail?.adminDetails.length > 0 ? "Mogo" : "Vendor"}
                </span>
              </div>
            </div>
          </td>
          <td>₹ {ProductDetail.sale_price}</td>
          <td>{ProductDetail.quantity}</td>
          <td>{ProductDetail.shipping ? ProductDetail.shipping : "Free"}</td>
          <td>₹ {ProductDetail.quantity * ProductDetail.sale_price}</td>
          <td>{individualOrderData?.order_status}</td>
          <td>{modifiedDate}</td>
          {individualOrderData?.order_status === "Completed" ? (
            <td>
              <Rating
                onClick={() => {
                  setratingModalOpen(true);
                }}
                value={ProductDetail.Rating}
              />
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    }
  );

  // Filter Current User
  const userById = allUserData.filter(
    (value) => value._id === individualOrderData?.user_id
  );
  // Filter Current Address
  const addressByUserId = allAddressData.filter(
    (value) => value._id === individualOrderData?.address_id
  );
  return (
    <div>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        className="card-order-style"
      >
        <div className="card-order-title">
          <h3>Order Details</h3>
          <Link className="individual-view-invoice-btn">
            <i className="fa fa-file-text-o"></i>&nbsp;&nbsp;&nbsp;View Invoice
          </Link>
        </div>
        <div className="card-order-full-details">
          <div className="card-order-full-details-left-main">
            <div className="card-order-full-details-left-head">
              <h4>Order</h4>
            </div>
            <div className="card-order-full-details-left-content">
              <div className="card-order-full-details-left-left">
                <ul>
                  <li>Status</li>
                  <li>Order Id</li>
                  <li>Payment Method</li>
                  <li>Currency</li>
                  <li>Payment Status</li>
                  <li>Updated</li>
                  <li>Date</li>
                </ul>
              </div>
              <div className="card-order-full-details-left-right">
                <ul>
                  <li>
                    <Badge color="gray" radius="sm">
                      {individualOrderData?.order_status}
                    </Badge>
                  </li>
                  <li> Mogo#{individualOrderData?._id}</li>
                  {/* <li>10005</li> */}
                  <li>{individualOrderData?.payment.replaceAll("_", " ")}</li>
                  <li>INR</li>
                  <li>Awaiting Payment</li>
                  <li>{modifiedDate}</li>
                  <li>{createdDate}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-order-full-details-right-main">
            <div className="card-order-full-details-right-head">
              <h4>Buyer</h4>
            </div>
            <div className="card-order-full-details-right-content-main">
              <div className="card-order-full-details-right-left-image">
                {userById[0]?.profile_image ? (
                  <img
                    src={`
                       ${`${config.baseUrlApi}/assets/userProfile/${userById[0]?.profile_image}`}
                        `}
                    alt="User_Profile"
                  />
                ) : (
                  <Avatar size={"xl"} src={null} alt="no image here" />
                )}
              </div>
              <div className="card-order-full-details-right-content">
                <div className="card-order-full-details-right-left">
                  <ul>
                    <li>Buyer</li>
                    <li>Phone Number</li>
                    <li>Email</li>
                  </ul>
                </div>
                <div className="card-order-full-details-right-right">
                  <ul>
                    <li>
                      {userById[0]?.first_name} {userById[0]?.last_name}
                    </li>
                    <li>
                      {userById[0]?.number ? userById[0]?.number : " ---"}
                    </li>
                    <li>{userById[0]?.email}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-order-full-details padding-05">
          <div className="card-order-full-details-left-main">
            <div className="card-order-full-details-left-head">
              <h4>Billing Address</h4>
            </div>
            <div className="card-order-full-details-left-content">
              <div className="card-order-full-details-left-left">
                <ul>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Phone Number</li>
                  <li>Address</li>
                  <li>Country</li>
                  <li>State</li>
                  <li>City</li>
                  <li>Zip Code</li>
                </ul>
              </div>
              <div className="card-order-full-details-left-right">
                <ul>
                  <li>{addressByUserId[0]?.first_name}</li>
                  <li>{addressByUserId[0]?.last_name}</li>
                  <li>{addressByUserId[0]?.email}</li>
                  <li>{addressByUserId[0]?.number}</li>
                  <li>{addressByUserId[0]?.address}</li>
                  <li>{addressByUserId[0]?.country}</li>
                  <li>{addressByUserId[0]?.state}</li>
                  <li>{addressByUserId[0]?.city}</li>
                  <li>{addressByUserId[0]?.zip_code}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-order-full-details-right-main">
            <div className="card-order-full-details-right-head">
              <h4>Shipping Address</h4>
            </div>
            <div className="card-order-full-details-right-content-main">
              <div className="card-order-full-details-right-content">
                <div className="card-order-full-details-left-left">
                  <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Email</li>
                    <li>Phone Number</li>
                    <li>Address</li>
                    <li>Country</li>
                    <li>State</li>
                    <li>City</li>
                    <li>Zip Code</li>
                  </ul>
                </div>
                <div className="card-order-full-details-left-right">
                  <ul>
                    <li>{addressByUserId[0]?.first_name}</li>
                    <li>{addressByUserId[0]?.last_name}</li>
                    <li>{addressByUserId[0]?.email}</li>
                    <li>{addressByUserId[0]?.number}</li>
                    <li>{addressByUserId[0]?.address}</li>
                    <li>{addressByUserId[0]?.country}</li>
                    <li>{addressByUserId[0]?.state}</li>
                    <li>{addressByUserId[0]?.city}</li>
                    <li>{addressByUserId[0]?.zip_code}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        className="card-order-style margin-card-05"
      >
        <div className="card-order-title">
          <h3>Products</h3>
        </div>
        <div className="card-order-full-details">
          <Table
            verticalSpacing="sm"
            highlightOnHover
            withBorder
            withColumnBorders
          >
            <thead>
              <tr>
                <th>Product Id</th>
                <th>Product</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Shipping Cost</th>
                <th>Total</th>
                <th>Status</th>
                <th>Updated</th>
                {individualOrderData?.order_status === "Completed" ? (
                  <th>Options</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>{ProductDetailsRows}</tbody>
          </Table>
        </div>
      </Card>

      <div className="temp">
        <div></div>
        <div></div>
        <Card shadow="sm" padding="lg" className="Individual-display-amount">
          <div className="Individual-display-amount-main">
            <div className="Individual-display-amount-left">
              <b>Subtotal</b>
              <b>Shipping</b>
            </div>
            <div className="Individual-display-amount-right">
              <p>₹ {totalPrice}</p>
              <p>Free</p>
            </div>
          </div>
          <hr className="Individual-display-amount-right-hr" />
          <div className="Individual-display-amount-main">
            <div className="Individual-display-amount-left">
              <b>Total</b>
            </div>
            <div className="Individual-display-amount-right">
              <p>₹ {totalPrice}</p>
            </div>
          </div>
        </Card>
      </div>

      <Modal
        zIndex={12121}
        size={"lg"}
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Update Order Status"
      >
        <Select
          label="Status"
          dropdownPosition="bottom"
          className="individual-selectbox-style"
          data={[
            { value: "Awaiting Payment", label: "Awaiting Payment" },
            { value: "Payment Received", label: "Payment Received" },
            { value: "Processing", label: "Processing" },
            { value: "Shipped", label: "Shipped" },
            { value: "Refund Approved", label: "Refund Approved" },
          ]}
        />
        <div className="model-btn-style">
          <Button color="teal">Save Changes</Button>
          <Button color="red" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Individual;
