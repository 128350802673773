import { Avatar, Input, Rating, Text, Textarea } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import list from "../../../assets/admin/payout/list.png";
import "./UserOrdersIndividual.css";
import image2 from "../../../assets/home/grid-category/bed_linen.webp";
import {
  Modal,
  Card,
  Badge,
  Table,
  Menu as MenuMantine,
  Select,
  Button,
} from "@mantine/core";
import { useQuery } from "react-query";
import { getOrderByID } from "../../../config/quries/Orders/OrdersQuery";
import { useSelector } from "react-redux";
import { getUserAddressByID } from "../../../config/quries/Address/userAddress";

// Server
import config from "../../../config/server/Servers";

// HTML
import ReactHtmlParser from "react-html-parser";

const UserOrdersIndividual = () => {
  // Params Id
  const paramsId = useParams();

  // User Data
  const userData = useSelector((state) => state.userData.value);

  const [ratingModalOpen, setratingModalOpen] = useState(false);
  const [individualOrderData, setIndividualOrderData] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [totalPrice, setTotalPrice] = useState();
  // Individual Order Data
  useQuery(["userIndividualOrder", paramsId.id], getOrderByID, {
    onSuccess: (res) => {
      const result = res.data.data.result;
      setIndividualOrderData(result);
    },
  });

  //   Shipping Address
  useQuery(
    ["shippingAddress", individualOrderData?.address_id],
    getUserAddressByID,
    {
      onSuccess: (res) => {
        setShippingAddress(res?.data?.data);
      },
    }
  );
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "User Orders", href: "/your_orders" },
    { title: "Order 001", href: "/your_orders_individual" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createdDate = new Date(individualOrderData?.created).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );

  const modifiedDate = new Date(
    individualOrderData?.modified
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const ProductDetailsRows = individualOrderData?.cart_products.map(
    (ProductDetail, index) => {
      return (
        <tr key={index}>
          <td>Mogo#{ProductDetail._id}</td>
          <td className="LatestProducts-Table-td-style">
            <div className="product-image-and-content-display">
              <div className="user-Table-td-style-image">
                <img
                  src={`${config.baseUrlApi}/assets/productImages/${ProductDetail.product_image}`}
                  alt=""
                />
              </div>
              <div className="LatestProducts-Table-td-style-title">
                <p> {ReactHtmlParser(ProductDetail?.short_description)}</p>
                <span>
                  {ProductDetail?.adminDetails.length > 0 ? "Mogo" : "Vendor"}
                </span>
              </div>
            </div>
          </td>
          <td>₹ {ProductDetail.sale_price}</td>
          <td>{ProductDetail.quantity}</td>
          <td>{ProductDetail.shipping ? ProductDetail.shipping : "Free"}</td>
          <td>₹ {ProductDetail.quantity * ProductDetail.sale_price}</td>
          <td>{individualOrderData?.order_status}</td>
          <td>{modifiedDate}</td>
          {individualOrderData?.order_status === "Completed" ? (
            <td>
              <Rating
                onClick={() => {
                  setratingModalOpen(true);
                }}
                value={ProductDetail.Rating}
              />
            </td>
          ) : (
            ""
          )}
        </tr>
      );
    }
  );

  // Totoal Quantity and Price
  useEffect(() => {
    if (individualOrderData?.cart_products) {
      const totalCartProductPrice = individualOrderData?.cart_products?.map(
        (value) => Number(value.sale_price)
      );
      const totalQuantityPerProduct = individualOrderData?.cart_products?.map(
        (value) => Number(value.quantity)
      );
      const resultArray = [];
      if (totalCartProductPrice.length === totalQuantityPerProduct.length) {
        for (let i = 0; i < totalQuantityPerProduct.length; i++) {
          resultArray.push(
            totalQuantityPerProduct[i] * totalCartProductPrice[i]
          );
        }
      }
      let sum = 0;
      for (let i = 0; i < resultArray.length; i++) {
        sum += resultArray[i];
        setTotalPrice(sum);
      }
    }
  }, [individualOrderData?.cart_products]);

  return (
    <div>
      <div className="user-orders-individual-div">
        <div
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          className="user-orders-individual-div-container"
        >
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="user-orders-individual-div-container-heading">
            <h3>Order Details</h3>
            <Text component={Link} to="/your_orders">
              <button className="sales-button user-orders-button-style">
                <img src={list} width={12} alt="list icon" /> Orders
              </button>
            </Text>
          </div>
          <div className="card-order-full-details">
            <div className="card-order-full-details-left-main">
              <div className="card-order-full-details-left-head">
                <h4>Order</h4>
              </div>
              <div className="card-order-full-details-left-content">
                <div className="card-order-full-details-left-left">
                  <ul>
                    <li>Status</li>
                    <li>Order Id</li>
                    <li>Payment Method</li>
                    <li>Currency</li>
                    <li>Payment Status</li>
                    <li>Updated</li>
                    <li>Date</li>
                  </ul>
                </div>
                <div className="card-order-full-details-left-right">
                  <ul>
                    <li>
                      <Badge color="gray" radius="sm">
                        {individualOrderData?.order_status}
                      </Badge>
                    </li>
                    <li> Mogo#{individualOrderData?._id}</li>
                    {/* <li>10005</li> */}
                    <li>{individualOrderData?.payment.replaceAll("_", " ")}</li>
                    <li>INR</li>
                    <li>Awaiting Payment</li>
                    <li>{modifiedDate}</li>
                    <li>{createdDate}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-order-full-details-right-main">
              <div className="card-order-full-details-right-head">
                <h4>Buyer</h4>
              </div>
              <div className="card-order-full-details-right-content-main">
                <div className="card-order-full-details-right-left-image">
                  {userData?.profile_image ? (
                    <img
                      src={`
                       ${`${config.baseUrlApi}/assets/userProfile/${userData.profile_image}`}
                        `}
                      alt="User_Profile"
                    />
                  ) : (
                    <Avatar size={"xl"} src={null} alt="no image here" />
                  )}
                </div>
                <div className="card-order-full-details-right-content">
                  <div className="card-order-full-details-right-left">
                    <ul>
                      <li>Buyer</li>
                      <li>Phone Number</li>
                      <li>Email</li>
                    </ul>
                  </div>
                  <div className="card-order-full-details-right-right">
                    <ul>
                      <li>
                        {userData?.first_name} {userData?.last_name}
                      </li>
                      <li>{userData?.number ? userData?.number : " ---"}</li>
                      <li>{userData.email}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-order-full-details padding-05">
            <div className="card-order-full-details-left-main">
              <div className="card-order-full-details-left-head">
                <h4>Billing Address</h4>
              </div>
              <div className="card-order-full-details-left-content">
                <div className="card-order-full-details-left-left">
                  <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Email</li>
                    <li>Phone Number</li>
                    <li>Address</li>
                    <li>Country</li>
                    <li>State</li>
                    <li>City</li>
                    <li>Zip Code</li>
                  </ul>
                </div>
                <div className="card-order-full-details-left-right">
                  <ul>
                    <li>{shippingAddress?.first_name}</li>
                    <li>{shippingAddress?.last_name}</li>
                    <li>{shippingAddress?.email}</li>
                    <li>{shippingAddress?.number}</li>
                    <li>{shippingAddress?.address}</li>
                    <li>{shippingAddress?.country}</li>
                    <li>{shippingAddress?.state}</li>
                    <li>{shippingAddress?.city}</li>
                    <li>{shippingAddress?.zip_code}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-order-full-details-right-main">
              <div className="card-order-full-details-right-head">
                <h4>Shipping Address</h4>
              </div>
              <div className="card-order-full-details-right-content-main">
                <div className="card-order-full-details-right-content">
                  <div className="card-order-full-details-left-left">
                    <ul>
                      <li>First Name</li>
                      <li>Last Name</li>
                      <li>Email</li>
                      <li>Phone Number</li>
                      <li>Address</li>
                      <li>Country</li>
                      <li>State</li>
                      <li>City</li>
                      <li>Zip Code</li>
                    </ul>
                  </div>
                  <div className="card-order-full-details-left-right">
                    <ul>
                      <li>{shippingAddress?.first_name}</li>
                      <li>{shippingAddress?.last_name}</li>
                      <li>{shippingAddress?.email}</li>
                      <li>{shippingAddress?.number}</li>
                      <li>{shippingAddress?.address}</li>
                      <li>{shippingAddress?.country}</li>
                      <li>{shippingAddress?.state}</li>
                      <li>{shippingAddress?.city}</li>
                      <li>{shippingAddress?.zip_code}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          className="user-orders-individual-div-container"
        >
          <div className="card-order-title">
            <h3>Products</h3>
          </div>
          <div className="card-order-full-details">
            <Table
              verticalSpacing="sm"
              highlightOnHover
              withBorder
              withColumnBorders
            >
              <thead>
                <tr>
                  <th>Product Id</th>
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Shipping Cost</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Updated</th>
                  {individualOrderData?.order_status === "Completed" ? (
                    <th>Options</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>{ProductDetailsRows}</tbody>
            </Table>
          </div>
          <div className="temp">
            <div></div>
            <div></div>
            <Card
              shadow="sm"
              padding="lg"
              className="Individual-display-amount"
            >
              <div className="Individual-display-amount-main">
                <div className="Individual-display-amount-left">
                  <b>Subtotal</b>
                  <b>Shipping</b>
                </div>
                <div className="Individual-display-amount-right">
                  <p>₹ {totalPrice}</p>
                  <p>Free</p>
                </div>
              </div>
              <hr className="Individual-display-amount-right-hr" />
              <div className="Individual-display-amount-main">
                <div className="Individual-display-amount-left">
                  <b>Total</b>
                </div>
                <div className="Individual-display-amount-right">
                  <p>₹ {totalPrice}</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Modal
          zIndex={1212111}
          centered
          size="xl"
          opened={ratingModalOpen}
          onClose={() => setratingModalOpen(false)}
          title=""
          transitionProps={{
            transition: "fade",
            duration: 350,
            timingFunction: "linear",
          }}
          className="sellerbalance-edit-modal"
        >
          <div className="sellerbalance-edit-modal-header">
            <h4>Rate This Product</h4>
          </div>
          <div className="sellerbalance-edit-modal-body">
            <div className="sellerbalance-edit-modal-body-content">
              <div className="user-rating-div">
                <p>Rating</p>
                <Rating value={0} />
              </div>
              <div className="user-profile-form-input">
                <Textarea placeholder="Write a Review ...." minRows={4} />
              </div>
              <div className="sellerbalance-edit-modal-body-content-button ht-20 user-review-model-button">
                <button
                  className="user-review-model-button-close"
                  onClick={() => setratingModalOpen(false)}
                >
                  Close
                </button>
                <button>Submit</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default UserOrdersIndividual;
