import React from "react";
import { Container } from "@mantine/core";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import "./CancelPolicy.css";
const CancelPolicy = () => {
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "Cancellation Policy", href: "/cencel_policy" },
  ];

  return (
    <div>
      <div className="terms-and-conditions-div">
        <Container className="terms-and-conditions-div-container" size={"82rem"}>
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="terms-and-conditions-div-container-heading">
            <h1>Order Cancellation Policy</h1>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>Cancellation Policy</h1>
            <p>The customer can choose to cancel an order any time before it's dispatched. The order cannot be canceled once it’s out for delivery. However, the customer may choose to reject it at the doorstep.</p>
            <p>The time window for cancellation varies based on different categories and the order cannot be canceled once the specified time has passed.</p>
            <p>In some cases, the customer may not be allowed to cancel the order for free, post the specified time and a cancellation fee will be charged. The details about the time window mentioned on the product page or order confirmation page will be considered final.</p>
            <p>In case of any cancellation from the due to unforeseen circumstances, a full refund will be initiated for prepaid orders.</p>
            <p>TheMogo reserves the right to accept the cancellation of any order. TheMogo also reserves the right to waive off or modify the time window or cancellation fee from time to time.</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CancelPolicy;
