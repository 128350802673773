import { createSlice } from "@reduxjs/toolkit";
import { wishListState } from "../../InitialState/wishList/WishListState";

export const wishListSlice = createSlice({
  name: "wishListSlice",
  initialState: wishListState,
  reducers: {
    setWishList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setWishList } = wishListSlice.actions;
