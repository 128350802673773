import { showNotification } from "@mantine/notifications";
import { ThemeIcon } from "@mantine/core";
import { CircleCheck, X } from "tabler-icons-react";
import {
  changePassword,
  loginVendor,
  registerVendor,
  updateVendorWithoutImage,
} from "../../../config/quries/vendorLogin/vendorQuries";
import axios from "axios";
import config from "../../../config/server/Servers";

export const hanldeLoginvendor = async (
  loginData,
  dispatch,
  setValidateData,
  validateData,
  naviagate
) => {
  const { email, password } = loginData;
  const payload = {
    email: email.trim(),
    password: password.trim(),
  };
  if (email.trim()) {
    if (email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      if (password.trim()) {
        await loginVendor(payload)
          .then((result) => {
            sessionStorage.setItem(
              "MogovendorAccessToken101",
              result.data?.data?._id
            );
            sessionStorage.setItem(
              "MogovendorAccessToken102",
              result.data?.data?.accessToken
            );
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                  <CircleCheck color="green" />
                </ThemeIcon>
              ),
              message: "Login Successfull",
            });
            window.location.reload(naviagate("/vendor_dashboard"));
          })
          .catch((error) => {
            if (error.response?.data?.error == "Invalid Password!") {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Invalid Password",
              });
              setValidateData({ ...validateData, password: 2 });
            } else if (error?.response?.status == 500) {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "User Not Found",
              });
              setValidateData({ ...validateData, email: 2 });
            }
          });
      } else {
        setValidateData({ ...validateData, password: 1 });
      }
    } else {
      setValidateData({ ...validateData, email: 2 });
    }
  } else {
    setValidateData({ ...validateData, email: 1 });
  }
};

export const vendorDetailsControl = async (
  profile,
  setProfile,
  validateData,
  setValidateData,
  queryClient,
  setEditProfile
) => {
  const {
    firstname,
    lastname,
    mobilenumber,
    address,
    profile_image,
    company_name,
    GST_IN,
    country,
    state,
    zip_code,
  } = profile;
  const token = sessionStorage.getItem("MogovendorAccessToken102");
  const id = sessionStorage.getItem("MogovendorAccessToken101");
  if (firstname.trim()) {
    if (lastname.trim()) {
      if (mobilenumber) {
        if (mobilenumber.match(/^[0-9]{10}$/)) {
          if (company_name) {
            if (GST_IN) {
              if (
                GST_IN.match(
                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z0-9A-Z]{1}[0-9A-Z]{1}$/
                )
              ) {
                if (address.trim()) {
                  if (country.trim()) {
                    if (state.trim()) {
                      if (zip_code.trim()) {
                        if (zip_code.match(/^[1-9][0-9]{5}$/)) {
                          if (profile_image) {
                            const formData = new FormData();
                            formData.append("first_name", firstname);
                            formData.append("last_name", lastname);
                            formData.append("mobile", mobilenumber);
                            formData.append("address", address);
                            formData.append("company_name", company_name);
                            formData.append("GSTIN", GST_IN);
                            formData.append("country", country);
                            formData.append("state", state);
                            formData.append("zip_code", zip_code);
                            formData.append("file", profile_image);
                            await axios
                              .put(
                                `${config.baseUrlApi}/vendor/withprofile/${id}`,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${sessionStorage.getItem(
                                      "MogovendorAccessToken102"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                queryClient.invalidateQueries("vendorData");
                                setEditProfile(false);
                                showNotification({
                                  icon: (
                                    <ThemeIcon
                                      variant="light"
                                      radius="xl"
                                      size="xl"
                                      color="green"
                                    >
                                      <CircleCheck color="green" />
                                    </ThemeIcon>
                                  ),
                                  message: "Profile Updated Successfully",
                                });
                              })
                              .catch(() => {
                                showNotification({
                                  icon: (
                                    <ThemeIcon
                                      variant="light"
                                      radius="xl"
                                      size="xl"
                                      color="red"
                                    >
                                      <X color="red" />
                                    </ThemeIcon>
                                  ),
                                  message: "vendor Access Not Allowed",
                                });
                              });
                          } else {
                            const body = {
                              first_name: firstname,
                              last_name: lastname,
                              mobile: mobilenumber,
                              address: address,
                              company_name: company_name,
                              GSTIN: GST_IN,
                              country: country,
                              state: state,
                              zip_code: zip_code,
                            };
                            const payload = {
                              body,
                              id,
                            };
                            await updateVendorWithoutImage(payload)
                              .then((res) => {
                                queryClient.invalidateQueries("vendorData");
                                setEditProfile(false);
                                showNotification({
                                  icon: (
                                    <ThemeIcon
                                      variant="light"
                                      radius="xl"
                                      size="xl"
                                      color="green"
                                    >
                                      <CircleCheck color="green" />
                                    </ThemeIcon>
                                  ),
                                  message: "Profile Updated Successfully",
                                });
                              })
                              .catch((err) => {
                                showNotification({
                                  icon: (
                                    <ThemeIcon
                                      variant="light"
                                      radius="xl"
                                      size="xl"
                                      color="red"
                                    >
                                      <X color="red" />
                                    </ThemeIcon>
                                  ),
                                  message: "vendor Access Not Allowed",
                                });
                              });
                          }
                        } else {
                          setValidateData({ ...validateData, zip_code: 2 });
                        }
                      } else {
                        setValidateData({ ...validateData, zip_code: 1 });
                      }
                    } else {
                      setValidateData({ ...validateData, state: 1 });
                    }
                  } else {
                    setValidateData({ ...validateData, country: 1 });
                  }
                } else {
                  setValidateData({ ...validateData, address: 1 });
                }
              } else {
                setValidateData({ ...validateData, GST_IN: 2 });
              }
            } else {
              setValidateData({ ...validateData, GST_IN: 1 });
            }
          } else {
            setValidateData({ ...validateData, company_name: 1 });
          }
        } else {
          setValidateData({ ...validateData, mobilenumber: 2 });
        }
      } else {
        setValidateData({ ...validateData, mobilenumber: 1 });
      }
    } else {
      setValidateData({ ...validateData, lastname: 1 });
    }
  } else {
    setValidateData({ ...validateData, firstname: 1 });
  }
};

export const handleChangePasswordAPI = async (
  password,
  setPassword,
  validatePassword,
  setValidatePassword
) => {
  const { oldpassword, newpassword, confirmpassword } = password;
  const body = {
    password: oldpassword.trim(),
    new_password: newpassword.trim(),
  };
  const id = sessionStorage.getItem("MogovendorAccessToken101");
  const payload = {
    body,
    id,
  };
  if (oldpassword.trim()) {
    if (newpassword.trim()) {
      if (confirmpassword.trim()) {
        if (newpassword.trim() === confirmpassword.trim()) {
          await changePassword(payload)
            .then((res) => {
              if (res.data?.data.result === "Invalid Old Password") {
                setValidatePassword({ ...validatePassword, oldpassword: 2 });
                showNotification({
                  icon: (
                    <ThemeIcon
                      variant="light"
                      radius="xl"
                      size="xl"
                      color="red"
                    >
                      <X color="red" />
                    </ThemeIcon>
                  ),
                  message: "Invalid Old Password",
                });
              } else {
                setPassword({
                  ...password,
                  oldpassword,
                  newpassword,
                  confirmpassword,
                });
                showNotification({
                  icon: (
                    <ThemeIcon
                      variant="light"
                      radius="xl"
                      size="xl"
                      color="green"
                    >
                      <CircleCheck color="green" />
                    </ThemeIcon>
                  ),
                  message: "Password Changed Successfully",
                });
                window.location.reload();
              }
            })
            .catch((err) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Vendor Access not Allowed to Change Password",
              });
            });
        } else {
          setValidatePassword({ ...validatePassword, confirmpassword: 2 });
        }
      } else {
        setValidatePassword({ ...validatePassword, confirmpassword: 1 });
      }
    } else {
      setValidatePassword({ ...validatePassword, newpassword: 1 });
    }
  } else {
    setValidatePassword({ ...validatePassword, oldpassword: 1 });
  }
};

export const handleVendorRegister = async (
  registration,
  setRegistration,
  validateData,
  setValidateData,
  navigate
) => {
  const {
    firstname,
    lastname,
    companyname,
    email,
    phonenumber,
    gstn,
    password,
    confirmpassword,
    address,
    country,
    state,
    zipcode,
  } = registration;
  if (firstname.trim()) {
    if (lastname.trim()) {
      if (companyname.trim()) {
        if (email.trim()) {
          if (email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            if (phonenumber.trim()) {
              if (phonenumber.match(/^[6-9]\d{9}$/)) {
                if (gstn.trim()) {
                  if (
                    gstn.match(
                      /^[0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9]{1}$/
                    )
                  ) {
                    if (password.trim()) {
                      if (confirmpassword.trim()) {
                        if (password.trim() === confirmpassword.trim()) {
                          if (address.trim()) {
                            if (country.trim()) {
                              if (state.trim()) {
                                if (zipcode.trim()) {
                                  if (zipcode.match(/^[1-9][0-9]{5}$/)) {
                                    const payload = {
                                      first_name: firstname,
                                      last_name: lastname,
                                      company_name: companyname,
                                      email: email,
                                      mobile_number: phonenumber,
                                      password: password,
                                      GSTIN: gstn,
                                      address: address,
                                      country: country,
                                      state: state,
                                      zip_code: zipcode,
                                    };
                                    await registerVendor(payload)
                                      .then(() => {
                                        setRegistration({
                                          ...registration,
                                          firstname: "",
                                          lastname: "",
                                          companyname: "",
                                          email: "",
                                          phonenumber: "",
                                          gstn: "",
                                          password: "",
                                          confirmpassword: "",
                                          address: "",
                                          country: "",
                                          state: "",
                                          zipcode: "",
                                        });
                                        showNotification({
                                          icon: (
                                            <ThemeIcon
                                              variant="light"
                                              radius="xl"
                                              size="xl"
                                              color="green"
                                            >
                                              <CircleCheck color="green" />
                                            </ThemeIcon>
                                          ),
                                          message:
                                            "Vendor Created Successfully",
                                        });
                                        navigate("/vendor");
                                      })
                                      .catch(() => {
                                        showNotification({
                                          icon: (
                                            <ThemeIcon
                                              variant="light"
                                              radius="xl"
                                              size="xl"
                                              color="red"
                                            >
                                              <X color="red" />
                                            </ThemeIcon>
                                          ),
                                          message: "Error Creating Vendor",
                                        });
                                        navigate("/");
                                      });
                                  } else {
                                    showNotification({
                                      icon: (
                                        <ThemeIcon
                                          variant="light"
                                          radius="xl"
                                          size="xl"
                                          color="red"
                                        >
                                          <X color="red" />
                                        </ThemeIcon>
                                      ),
                                      message: "Please Enter Valid Zip Code",
                                    });
                                    setValidateData({
                                      ...validateData,
                                      zipcode: 2,
                                    });
                                  }
                                } else {
                                  showNotification({
                                    icon: (
                                      <ThemeIcon
                                        variant="light"
                                        radius="xl"
                                        size="xl"
                                        color="red"
                                      >
                                        <X color="red" />
                                      </ThemeIcon>
                                    ),
                                    message: "Zip Code is Compulsory",
                                  });
                                  setValidateData({
                                    ...validateData,
                                    zipcode: 1,
                                  });
                                }
                              } else {
                                showNotification({
                                  icon: (
                                    <ThemeIcon
                                      variant="light"
                                      radius="xl"
                                      size="xl"
                                      color="red"
                                    >
                                      <X color="red" />
                                    </ThemeIcon>
                                  ),
                                  message: "State is Compulsory",
                                });
                                setValidateData({
                                  ...validateData,
                                  state: 1,
                                });
                              }
                            } else {
                              showNotification({
                                icon: (
                                  <ThemeIcon
                                    variant="light"
                                    radius="xl"
                                    size="xl"
                                    color="red"
                                  >
                                    <X color="red" />
                                  </ThemeIcon>
                                ),
                                message: "Country is Compulsory",
                              });
                              setValidateData({
                                ...validateData,
                                country: 1,
                              });
                            }
                          } else {
                            showNotification({
                              icon: (
                                <ThemeIcon
                                  variant="light"
                                  radius="xl"
                                  size="xl"
                                  color="red"
                                >
                                  <X color="red" />
                                </ThemeIcon>
                              ),
                              message: "Address is Compulsory",
                            });
                            setValidateData({
                              ...validateData,
                              address: 1,
                            });
                          }
                        } else {
                          showNotification({
                            icon: (
                              <ThemeIcon
                                variant="light"
                                radius="xl"
                                size="xl"
                                color="red"
                              >
                                <X color="red" />
                              </ThemeIcon>
                            ),
                            message:
                              "Password and Conform Password Must Be Same",
                          });
                          setValidateData({
                            ...validateData,
                            confirmpassword: 2,
                          });
                        }
                      } else {
                        showNotification({
                          icon: (
                            <ThemeIcon
                              variant="light"
                              radius="xl"
                              size="xl"
                              color="red"
                            >
                              <X color="red" />
                            </ThemeIcon>
                          ),
                          message: "Please Enter Conform Password",
                        });
                        setValidateData({
                          ...validateData,
                          confirmpassword: 1,
                        });
                      }
                    } else {
                      showNotification({
                        icon: (
                          <ThemeIcon
                            variant="light"
                            radius="xl"
                            size="xl"
                            color="red"
                          >
                            <X color="red" />
                          </ThemeIcon>
                        ),
                        message: "Please Enter Password",
                      });
                      setValidateData({ ...validateData, password: 1 });
                    }
                  } else {
                    showNotification({
                      icon: (
                        <ThemeIcon
                          variant="light"
                          radius="xl"
                          size="xl"
                          color="red"
                        >
                          <X color="red" />
                        </ThemeIcon>
                      ),
                      message: "Please Enter Valid GSTIN",
                    });
                    setValidateData({ ...validateData, gstn: 2 });
                  }
                } else {
                  showNotification({
                    icon: (
                      <ThemeIcon
                        variant="light"
                        radius="xl"
                        size="xl"
                        color="red"
                      >
                        <X color="red" />
                      </ThemeIcon>
                    ),
                    message: "GSTIN is Compulsory",
                  });
                  setValidateData({ ...validateData, gstn: 1 });
                }
              } else {
                showNotification({
                  icon: (
                    <ThemeIcon
                      variant="light"
                      radius="xl"
                      size="xl"
                      color="red"
                    >
                      <X color="red" />
                    </ThemeIcon>
                  ),
                  message: "Please Enter Mobile Number",
                });
                setValidateData({ ...validateData, phonenumber: 2 });
              }
            } else {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Please Enter Mobile Number",
              });
              setValidateData({ ...validateData, phonenumber: 1 });
            }
          } else {
            showNotification({
              icon: (
                <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                  <X color="red" />
                </ThemeIcon>
              ),
              message: "Please Enter Valid Email Address",
            });
            setValidateData({ ...validateData, email: 2 });
          }
        } else {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            message: "Email Address is Compulsory",
          });
          setValidateData({ ...validateData, email: 1 });
        }
      } else {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Company Name is Compulsory",
        });
        setValidateData({ ...validateData, companyname: 1 });
      }
    } else {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Last Name is Compulsory",
      });
      setValidateData({ ...validateData, lastname: 1 });
    }
  } else {
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="red">
          <X color="red" />
        </ThemeIcon>
      ),
      message: "First Name is Compulsory",
    });
    setValidateData({ ...validateData, firstname: 1 });
  }
};
