import axios from "axios";
import config from "../../server/Servers";

// Common Header
const token = sessionStorage.getItem("MogoAdminAccessToken102");
const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

// Login Admin
export const loginAdmin = (payload) => {
  return axios.post(`${config.baseUrlApi}/admin/adminlogin`, payload);
};

export const getAdminByID = ({ queryKey }) => {
  const [_, id] = queryKey;
  return axios.get(`${config.baseUrlApi}/admin/${id}`, commonHeader);
};

export const updateAdminWithoutImage = (payload) => {
  const { body, id } = payload;
  return axios.put(
    `${config.baseUrlApi}/admin/withoutimage/${id}`,
    body,
    commonHeader
  );
};

export const changePassword = (payload) => {
  const { id, body } = payload;
  return axios.post(
    `${config.baseUrlApi}/admin/changepassword/${id}`,
    body,
    commonHeader
  );
};

export const listAllAdmin = () => {
  return axios.get(`${config.baseUrlApi}/admin`, commonHeader);
};

export const addNewAdmin = (payload) => {
  return axios.post(
    `${config.baseUrlApi}/admin/adminregister`,
    payload,
    commonHeader
  );
};
