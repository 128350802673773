import axios from "axios";
import config from "../../server/Servers";

// Common Header
const token = sessionStorage.getItem("MogovendorAccessToken102");
const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

// Vendor Login
export const loginVendor = (payload) => {
  return axios.post(`${config.baseUrlApi}/vendor/vendorlogin`, payload);
};

export const getVendorByid = () => {
  const id = sessionStorage.getItem("MogovendorAccessToken101");
  return axios.get(`${config.baseUrlApi}/vendor/${id}`, commonHeader);
};

export const updateVendorWithoutImage = (payload) => {
  const { body, id } = payload;
  return axios.put(
    `${config.baseUrlApi}/vendor/withoutimage/${id}`,
    body,
    commonHeader
  );
};

export const changePassword = (payload) => {
  const { id, body } = payload;
  return axios.post(
    `${config.baseUrlApi}/vendor/changepassword/${id}`,
    body,
    commonHeader
  );
};

export const getAllVendor = () => {
  return axios.get(`${config.baseUrlApi}/vendor`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem(
        "MogoAdminAccessToken102"
      )}`,
    },
  });
};

export const registerVendor = (payload) => {
  return axios.post(`${config.baseUrlApi}/vendor/vendorregister`, payload);
};
