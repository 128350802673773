//import react packages
import React, { useEffect, useState } from "react";

//import mantine packages
import {
  Card,
  Center,
  Container,
  Flex,
  Rating,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";

//import react-router-dom packages
import { Link, useNavigate } from "react-router-dom";

//import HomeProductsList css
import "./WishList.css";

//import images
import bath_linen from "../../../assets/home/grid-category/bath_linen.webp";
import kitchen_linen from "../../../assets/home/grid-category/kitchen_linen.webp";

import wishlist from "../../../assets/header/wishlist.webp";
import cart from "../../../assets/header/cart.webp";

import arrowdown from "../../../assets/preheader/arrow-down.webp";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { listAllProduct } from "../../../config/quries/Products/ProductQuries";
import { setProductList } from "../../../StateHandler/Slice/Products/ProductSlice";

import config from "../../../config/server/Servers";
import { categoryById } from "../../../config/quries/Category/CategoryQueries";
import { subCategorById } from "../../../config/quries/SubCategory/SubCategoryQuries";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";
import { setWishList } from "../../../StateHandler/Slice/wishList/WishListSlice";
import { showNotification } from "@mantine/notifications";
import {
  CircleCheck,
  Heart,
  InfoCircle,
  ShoppingCart,
} from "tabler-icons-react";

const WishList = () => {
  const productList = useSelector((state) => state.wishListData.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  // handle Add to cart

  // Category
  useQuery(["categoryByid", categoryId], categoryById, {
    refetchOnWindowFocus: false,
    enabled: !!categoryId,
    onSuccess: (res) => {
      const filter = res.data?.data?.result?.name.replace(" ", "_");
      setCategoryName(filter);
    },
  });

  useQuery(["subcategoryByid", subCategoryId], subCategorById, {
    refetchOnWindowFocus: false,
    enabled: !!subCategoryId,
    onSuccess: (res) => {
      const filter = res.data?.data?.result?.name.replace(" ", "_");
      setSubCategoryName(filter);
    },
  });

  const hanldeNavigatePage = (product) => {
    setCategoryId(product.product_category);
    setSubCategoryId(product.product_subcategory);
    if (categoryId && subCategoryId) {
      navigate(`/product/${categoryName}/${subCategoryName}/${product._id}`);
    }
  };

  // Add to Cart
  const cartSessionValue = JSON.parse(sessionStorage.getItem("cartProducts"));
  const handleAddCartClick = (e) => {
    if (cartSessionValue) {
      const filterCart = cartSessionValue.filter(
        (value) => value._id === e._id
      );
      if (filterCart && filterCart.length > 0) {
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="blue">
        //       <InfoCircle color="blue" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Already Exists in Cart",
        // });
      } else {
        const cartProdutwithQuantity = { ...e, quantity: 1 };
        const newCart = [...cartSessionValue, cartProdutwithQuantity];
        sessionStorage.setItem("cartProducts", JSON.stringify(newCart));
        dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
        // showNotification({
        //   icon: (
        //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
        //       <CircleCheck color="green" />
        //     </ThemeIcon>
        //   ),
        //   message: "Product Added to Cart",
        // });
      }
    } else {
      const cartProdutwithQuantity = { ...e, quantity: 1 };
      sessionStorage.setItem(
        "cartProducts",
        JSON.stringify([cartProdutwithQuantity])
      );
      dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
      // showNotification({
      //   icon: (
      //     <ThemeIcon variant="light" radius="xl" size="xl" color="green">
      //       <CircleCheck color="green" />
      //     </ThemeIcon>
      //   ),
      //   message: "Product Added to Cart",
      // });
    }
  };

  useEffect(() => {
    dispatch(setCart(JSON.parse(sessionStorage.getItem("cartProducts"))));
  }, []);

  // Add to Wish list
  const wishListSessionValue = JSON.parse(sessionStorage.getItem("wishList"));
  const removeFromWishList = (e) => {
    const removeFromSessionStorage = wishListSessionValue.filter(
      (value) => value._id !== e
    );
    sessionStorage.setItem(
      "wishList",
      JSON.stringify(removeFromSessionStorage)
    );
    dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="green">
          <CircleCheck color="green" />
        </ThemeIcon>
      ),
      message: "Product Removed from Wishlist",
    });
  };

  useEffect(() => {
    dispatch(setWishList(JSON.parse(sessionStorage.getItem("wishList"))));
  }, []);

  return (
    <div>
      {productList && productList.length > 0 ? (
        <div className="homeproductlist-div">
          <Container size={"82rem"} className="homeproductlist-div-container">
            <Center>
              <div className="homeproductlist-div-container-heading">
                <h1>WishList &nbsp;</h1>
              </div>
            </Center>
            <div className="homeproductlist-div-container-content">
              {Array.isArray(productList) ? (
                productList.map((homeProductList, index) => {
                  return (
                    <div
                      key={index}
                      className="homeproductlist-div-container-content-product"
                    >
                      <div
                        onMouseOver={() => {
                          setCategoryId(homeProductList.product_category);
                          setSubCategoryId(homeProductList.product_subcategory);
                        }}
                        className="homeproductlist-div-container-content-product-image"
                      >
                        <div className="homeproductlist-div-container-content-product-image-img">
                          <img
                            onClick={() => hanldeNavigatePage(homeProductList)}
                            src={`${config.baseUrlApi}/assets/productImages/${homeProductList.product_image}`}
                            alt=""
                          />
                          <img
                            onClick={() => hanldeNavigatePage(homeProductList)}
                            src={`${config.baseUrlApi}/assets/productImages/${homeProductList.product_gallery_image[1]}`}
                            alt=""
                            id="second-image"
                          />
                          <div className="homeproductlist-div-container-content-product-wishlist-cart">
                            {/* <Tooltip label="Remove from Wish List"> */}
                            <div className="wishlis-remove-wishList homeproductlist-div-container-content-product-wishlist-cart-wishlist">
                              <div
                                onClick={() =>
                                  removeFromWishList(homeProductList._id)
                                }
                                className="wishlist-circle-icon homeproductlist-div-container-content-product-wishlist-cart-wishlist-image"
                              >
                                <Heart strokeWidth={1} />
                              </div>
                            </div>
                            {/* </Tooltip> */}
                            <div className=" wishlis-remove-wishList-2 homeproductlist-div-container-content-product-wishlist-cart-cart">
                              <div
                                onClick={() =>
                                  handleAddCartClick(homeProductList)
                                }
                                className=" wishlist-circle-icon homeproductlist-div-container-content-product-wishlist-cart-cart-image"
                              >
                                <Tooltip label="Add to Cart">
                                  <ShoppingCart strokeWidth={1} />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="homeproductlist-div-container-content-product-content">
                        <div className="homeproductlist-div-container-content-product-content-header">
                          <p>{homeProductList.name}</p>
                        </div>
                        <div className="homeproductlist-div-container-content-product-content-shop-name">
                          <p>Mogo</p>
                        </div>
                        <div className="homeproductlist-div-container-content-product-content-shop-name">
                          <p>{homeProductList?.CategoryDetails[0]?.name}</p>
                        </div>
                        <div className="homeproductlist-div-container-content-product-content-rating-like">
                          <div className="homeproductlist-div-container-content-product-content-rating-like-rating">
                            <Rating value={5} readOnly />
                          </div>
                          <div className="homeproductlist-div-container-content-product-content-rating-like-like">
                            <div className="homeproductlist-div-container-content-product-content-rating-like-like-image">
                              <img src={wishlist} alt="" width={10} />
                            </div>
                            {homeProductList.like}
                          </div>
                        </div>
                        <div className="homeproductlist-div-container-content-product-content-price">
                          <h1>
                            ₹
                            {`${
                              homeProductList.sale_price
                                ? homeProductList.sale_price
                                : 199
                            }`}
                          </h1>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Card p={"xl"}>
                    <Center>
                      <Title order={1}>Wish List is Empty</Title>
                    </Center>
                  </Card>
                </div>
              )}
            </div>
          </Container>
        </div>
      ) : (
        <Container className="cart-div-container cart-empty" size={"82rem"}>
          <Flex justify={"center"} align={"center"} w={"100%"} h={"100%"}>
            <Title>WishList is Empty</Title>
          </Flex>
        </Container>
      )}
    </div>
  );
};

export default WishList;
